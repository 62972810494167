import {HTTP} from "../../helpers/axios";
// src/thunks.ts
import {AxiosResponse} from "axios";
import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import {serverRoutes} from "../../api/serverRoutes";


export const selectedDealer = (dealer:object):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {

    dispatch({
        type: 'SELECTED_DEALER',
        payload: dealer
    });

};
