import {HTTP} from "../../helpers/axios";
// src/thunks.ts
import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'


export const selectedDeviceAction = (device:object):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {

        dispatch({
            type: 'SELECTED_DEVICE',
            payload: device
        });

};
