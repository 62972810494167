import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import {getAllDebtsAction} from "../../actions/debt/getAdebtaAction";
import {DebtItemsDatainterface} from "../../dataInterface/debtItemsDatainterface";
import moment from "moment";
import ModalComponent from "../Modal/ModalComponent";
import {getDebtItemsByDebtId} from "../../actions/debt/getDebtItemsByDebtIdAction"
import {lockdebtAction} from "../../actions/debt/lockdebtAction"

import {DebtDataInterface} from "../../dataInterface/debtDataInterface";


interface internalState {
    loading:boolean,
    Success:boolean,
    error:boolean,
    errorString:string,
    successString:string,
    take:number,
    skip:number,
    mechanicFname:string,
    dealerFname:string,
    startDate:string,
    endDate:string,
    paymentDate:string,
    receipt:string,
    paid:boolean|string,
    showModal:boolean,
    debtItems:Array<DebtItemsDatainterface>

}

interface parentProps {
}

interface externalStateProps {
    allDebts:[Array<DebtDataInterface>,number]
    debtItems:Array<DebtItemsDatainterface>
}


interface DispatchProps {
    getAllDebtsAction:(data:object,callback:(error:boolean)=>void)=>void,
    getDebtItemsByDebtId:(data:object,callback:(error:boolean)=>void)=>void
    lockdebtAction:(data:object,callback:(error:boolean)=>void)=>void
}

type Props = externalStateProps & DispatchProps & parentProps;

class DeptComponent extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading:false,
            Success:false,
            error:false,
            errorString:"",
            successString:"",
            take:15,
            skip:0,
            mechanicFname:"",
            dealerFname:"",
            startDate:"",
            endDate:"",
            paymentDate:"",
            receipt:"",
            paid:'2',
            showModal:false,
            debtItems:[]
        };

        this.paginate=this.paginate.bind(this);
        this.nextPage=this.nextPage.bind(this);
        this.previousPage=this.previousPage.bind(this);
        this.getAllDebts=this.getAllDebts.bind(this);
        this.clear=this.clear.bind(this);
        this.handleShow=this.handleShow.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.lockDebt=this.lockDebt.bind(this);

    }

    componentDidMount(): void {
        this.getAllDebts();
    }


    lockDebt(e:React.MouseEvent<HTMLTableCellElement>,debtId:number){
        e.preventDefault();
        this.setState({
            loading:true
        });
        this.props.lockdebtAction({
            debtId
        },(error)=>{
            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:`Failed to Lock Debt with id ${debtId}`,
                    successString:"",
                    Success:false,
                });
            }else {
                this.setState({
                    loading:false,
                    error:false,
                    errorString:"",
                    successString:`Debt Locked`,
                    Success:true,
                });


            }
        });
    }



    getDebtItems(debtId:number){
        this.setState({
            loading:true
        });
        this.props.getDebtItemsByDebtId({
            debtId
        },(error)=>{
            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Server Error Detected",
                    successString:"",
                    Success:false,
                });
            }else {

                this.setState({
                    loading:false,
                });
            }
        });
    }



    getAllDebts(){
        this.setState({
            loading:true
        });
        this.props.getAllDebtsAction({
            skip:this.state.skip,
            take:this.state.take,
            paid:this.state.paid,
            mechanicFname:this.state.mechanicFname,
            dealerFname:this.state.dealerFname,
            startDate:this.state.startDate,
            endDate:this.state.endDate,
            receipt:this.state.receipt,
            paymentDate:this.state.paymentDate
        },(error)=>{
            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Server Error Detected",
                    successString:"",
                    Success:false,
                });
            }else {
                this.setState({
                    loading:false,
                });
            }
        });
    }


    paginate(data:{selected:number}){
        console.log(data);
        this.setState({
            skip: this.state.take * data.selected
        },()=>{
            this.getAllDebts();
        })
    }
    nextPage(){
        this.setState({
            skip:this.state.skip+10,
        },()=>{
            this.getAllDebts();

        });
    }

    previousPage(){
        this.setState({
            skip:this.state.skip-10,
        },()=>{
            this.getAllDebts();
        });
    }


    clear(e:React.MouseEvent|null=null){

        this.setState({
            skip:0,
            take:15,
            mechanicFname:"",
            dealerFname:"",
            startDate:"",
            endDate:"",
            receipt:"",
            paymentDate:"",
            paid:"2"
        },()=>{
            this.getAllDebts();
        })
    }

    handleShow(e:React.MouseEvent<HTMLTableDataCellElement, MouseEvent>,debtId:number){
        e.preventDefault();

        this.setState({
            showModal:true,
        },()=>{

            this.getDebtItems(debtId);
        });

    }

    handleClose(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal:false,
        })
    }



    render() {

        const iconGreen = {
            color:'green'
        };

        const iconRed = {
            color:'red'
        };


        const iconGrey = {
            color:'grey'
        };

        const devices = {
            cursor:'pointer',
            color:iconRed.color
        };

        const locked = {
            cursor:'pointer',
            color:iconRed.color
        };

        const unlocked = {
            cursor:'pointer',
            color:iconGrey.color
        };

        const unlockedPaid = {
            color:iconGrey.color
        };

        let debtItems=()=>{

            if(this.props.debtItems!==null){
                return this.props.debtItems.map((value,index)=>{
                    return (
                        <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{value.device?.customer?.fname + " " + value.device?.customer?.lname}</td>
                            <td>{value.device?.plateNumber}</td>
                            <td>{value.device?.device.unique_id}</td>
                            <td>{value.amount}</td>
                        </tr>
                    )
                });

            }
        };


        let allDebts=()=>{

            if(this.props.allDebts!==null){
                return this.props.allDebts[0].map((value,index)=>{

                    return (
                        <tr>
                            <th scope="row">{index + 1}</th>
                            <td className={value.whoPay===0? 'badge badge-primary':''}>{value.mechanic?.fname + " " + value.mechanic?.lname}</td>
                            <td className={value.whoPay===1? 'badge badge-primary':''}>{value.dealer?.fname + " " + value.dealer?.lname}</td>
                            <td>{value.modeOfPayment}</td>
                            <td>
                                {
                                    value.paid? <i className="fa fa-check-circle" style={iconGreen}></i>:<i className="fa fa-times" style={iconRed}></i>
                                }
                            </td>
                            <td>{value.paymentNumber}</td>
                            <td>{value.amount}</td>
                            <td>{value.tariff}</td>
                            <td>{value.paidAmount}</td>
                            <td>{value.receipt}</td>
                            <td>{moment(value.createdAt).format('YYYY-MM-DD h:mm:ss a')}</td>
                            <td>{value.paymentDate!==null?moment(value.paymentDate).format('YYYY-MM-DD h:mm:ss a'):'No Date Set'}</td>
                            <td onClick={(e)=>this.handleShow(e,value.id)}><i className="fa fa-tablet" style={devices}/></td>
                            {
                                value.locked ?<td><i className="fa fa-lock" style={iconGreen}/></td>:
                                    value.paid?<td/>:
                                        <td onClick={(e)=>this.lockDebt(e,value.id)}><i className="fa fa-lock-open" style={unlocked}/></td>


                            }

                        </tr>
                    )
                })

            }
        };


        return (
            <React.Fragment>
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="col-auto">
                                <div className="input-group mb-4">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Receipt</div>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Receipt No"  value={this.state.receipt}  onChange={(e)=>this.setState({receipt:e.target.value})}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 mb-3">
                            <form>
                                <div className="row">
                                    <div className="col">
                                        <label>Start Date</label>
                                        <input type="datetime-local" className="form-control" placeholder="Start Date" value={this.state.startDate}  onChange={(e)=>this.setState({startDate:e.target.value})}/>
                                    </div>
                                    <div className="col">
                                        <label>End Date</label>
                                        <input type="datetime-local" className="form-control" placeholder="End Date" value={this.state.endDate}  onChange={(e)=>this.setState({endDate:e.target.value})}/>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-4">
                            <label>Payment Date</label>
                            <input type="date" className="form-control" placeholder="Payment Date" value={this.state.paymentDate}  onChange={(e)=>this.setState({paymentDate:e.target.value})}/>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="col-auto">
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Mechanic Name</div>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Mechanic Name"  value={this.state.mechanicFname}  onChange={(e)=>this.setState({mechanicFname:e.target.value})}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="col-auto">
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Dealer Name</div>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Dealer Name"  value={this.state.dealerFname}  onChange={(e)=>this.setState({dealerFname:e.target.value})}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <select className="custom-select custom-select-sm" onChange={(e)=>this.setState({paid:e.target.value})}>
                                <option selected={this.state.paid==='2'} value={2}>Status</option>
                                <option selected={this.state.paid==='1'} value={1} data-paid={1}>Paid</option>
                                <option selected={this.state.paid==='0'} value={0} >Not Paid</option>
                            </select>
                        </div>
                        <div className="col-md-12 mb-5">
                            <div className="row">
                                <div className="col-md-10">
                                    <button className="btn btn-primary btn-block" type="button" onClick={this.getAllDebts}>Filter</button>
                                </div>
                                <div className="col-md-2">
                                    <button className="btn btn-success btn-block" type="button" onClick={this.clear}>Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Mechanic</th>
                            <th scope="col">Dealer</th>
                            <th scope="col">Network</th>
                            <th scope="col">Paid</th>
                            <th scope="col">Payment Number</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Tariff</th>
                            <th scope="col">Paid Amount</th>
                            <th scope="col">Receipt</th>
                            <th scope="col">Created Date</th>
                            <th scope="col">Payment Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {allDebts()}
                        </tbody>
                    </table>
                    <ModalComponent
                        title="Devices"
                        show={this.state.showModal}
                        handleClose={this.handleClose}
                        success={this.state.Success}
                        loader={this.state.loading}
                        error={this.state.error}
                        errorString={this.state.errorString}
                        successString={this.state.successString}
                    >
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Customer</th>
                                <th scope="col">Plate Number</th>
                                <th scope="col">Imei</th>
                                <th scope="col">Price</th>
                            </tr>
                            </thead>
                            <tbody>
                            {debtItems()}
                            </tbody>
                        </table>

                    </ModalComponent>
                </div>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        allDebts:state.allDebts,
        debtItems:state.debtItemsNonPageable
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {getAllDebtsAction,getDebtItemsByDebtId,lockdebtAction}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(DeptComponent);
