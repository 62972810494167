import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import { ResponsiveLine ,Serie} from '@nivo/line/'
import DevicesSalesByMonthYearComponent from "../statistics/DevicesSalesByMonthYearComponent";

interface internalState {
    data:Array<Serie>
}

interface parentProps {
}

interface externalStateProps {
}


interface DispatchProps {
}

type Props = externalStateProps & DispatchProps & parentProps;

class DashboardComponent extends React.Component<Props, internalState> {
    constructor(props:Props) {
        super(props);
        this.test=this.test.bind(this);
    }

    componentDidMount(): void {
        console.log('rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr');
    }

    test(){

    }


    render() {

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <DevicesSalesByMonthYearComponent/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {}
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {
        }, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
