import {HTTP} from "../../helpers/axios";
// src/thunks.ts
import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import { serverRoutes} from "../../api/serverRoutes";

export const shiftDeviceToAnotherDealerAction = (data:object,callback:(error:boolean)=>void):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {

    try {
        const response =  await   HTTP(getState,dispatch).post(serverRoutes.dealerDeviceShift,data);

        let error=false;
        callback(error);

    }catch (e) {
        let error=true;
        callback(error)
    }

};

