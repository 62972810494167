import React from "react";
import {Action, bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import LoaderComponent from "../Loader/LoaderComponent";
import Notifiction from "../Notification/Notifiction";
import {getMessageAction} from "../../actions/messages/getAllMessagesAction";
import {createMessageAction} from "../../actions/messages/createMessageAction";
import {approveMessageAction} from "../../actions/messages/approveMessageAction";
import {testMessageAction} from "../../actions/messages/testMessageAction";

import {MessageDataInterface} from "../../dataInterface/messageDataInterface";
import ReactPaginate from "react-paginate";
import ModalComponent from "../Modal/ModalComponent";
import moment from "moment";
import {faRetweet} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface internalState {
    loading:boolean,
    error:boolean,
    errorString:string,
    Success:boolean,
    successString:string,
    take:number,
    skip:number,
    message:string,
    date:string,
    time:string
}

interface parentProps {
}

interface externalStateProps {
    messages:[Array<MessageDataInterface>,number]
}


interface DispatchProps {

    getMessageAction:(data:object,callback:(error:boolean)=>void)=>void,
    createMessageAction:(data:object,callback:(error:boolean)=>void)=>void
    approveMessageAction:(data:object,callback:(error:boolean)=>void)=>void,
    testMessageAction:(data:object,callback:(error:boolean)=>void)=>void,


}

type Props = externalStateProps & DispatchProps & parentProps;

class MessagesComponent extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading:false,
            errorString:"",
            error:false,
            Success:false,
            successString:"",
            take:7,
            skip:0,
            message:"",
            time:"",
            date:""
        };

        this.approve=this.approve.bind(this);
        this.testMessage=this.testMessage.bind(this);
        this.createMessage=this.createMessage.bind(this);
        this.getAllMessages=this.getAllMessages.bind(this);


    }

    componentDidMount(): void {
        this.getAllMessages();
    }


    approve(messageId:number){
        this.setState({
            loading:true
        });

        this.props.approveMessageAction({messageId},(error)=>{
            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Error Approving Message",
                    successString:"",
                    Success:false,
                });
            }else {
                this.setState({
                    loading:false,
                    error:false,
                    errorString:"",
                    successString:"Message Approved",
                    Success:true,
                });

                this.getAllMessages();
            }

        });

    }

    testMessage(){

        if(this.state.message!=="" && this.state.date!=="" && this.state.time!==""){

            this.setState({
                loading:true,
                error:false,
                errorString:"",
                successString:"",
                Success:false,
            });

            this.props.testMessageAction({message:this.state.message},(error)=>{
                if(error){
                    this.setState({
                        loading:false,
                        error:true,
                        errorString:"Error Sending Test Message",
                        successString:"",
                        Success:false,
                    });
                }else {
                    this.setState({
                        loading:false,
                        error:false,
                        errorString:"",
                        successString:"Message Sent",
                        Success:true,
                    });

                }

            });

        }else {
            this.setState({
                error:true,
                errorString:"Please Input The message,Date and Time",
                successString:"",
                Success:false,
            });
        }
    }


    createMessage(){

        if(this.state.message!=="" && this.state.date!=="" && this.state.time!==""){

            this.setState({
                loading:true,
                error:false,
                errorString:"",
                successString:"",
                Success:false,
            });

            this.props.createMessageAction({message:this.state.message,date:this.state.date,time:this.state.time},(error)=>{
                if(error){
                    this.setState({
                        loading:false,
                        error:true,
                        errorString:"Error getting Messages",
                        successString:"",
                        Success:false,
                    });
                }else {
                    this.setState({
                        loading:false,
                        error:false,
                        errorString:"",
                        successString:"Message Created",
                        Success:true,
                        take:7,
                        skip:0,
                        message:"",
                        date:"",
                        time:""
                    });

                    this.getAllMessages();
                }

            });

        }else {
            this.setState({
                error:true,
                errorString:"Please Input The message,Date and Time",
                successString:"",
                Success:false,
            });
        }
    }

    getAllMessages(){
        this.props.getMessageAction({take:this.state.take,skip:this.state.skip},(error)=>{

            this.setState({
                loading:true
            });

            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Error getting Messages",
                    successString:"",
                    Success:false,
                });
            }else {
                this.setState({
                    loading:false,
                });
            }

        });
    }

    logPaginate(data:{selected:number}){
        this.setState({
            skip: this.state.skip * data.selected
        },()=>{
            this.getAllMessages();
        })
    }

    render() {



        let allMessages=()=>{

            if(this.props.messages!==null){

                let refreshButton=(message:MessageDataInterface)=>{

                    if(!message.approved){
                        return <td className="pointer" onClick={()=>this.approve(message.id)}>{<FontAwesomeIcon icon={faRetweet} color="red"/>}</td>

                    }else {
                        return <td className="pointer">{<FontAwesomeIcon icon={faRetweet} color="grey"/>}</td>
                    }
                };

                return this.props.messages[0].map((message,index)=>{
                    return (
                        <React.Fragment>
                            <tr>
                                <td>{index+1}</td>
                                <td>{moment(message.createdAt).format("DD-MM-YYYY")}</td>
                                <td>{moment(message.publishDate).format("DD-MM-YYYY")}</td>
                                <td>{message.messageSent? "Sent":"Not Sent"}</td>
                                <td>{message.approved? "Approved":"Not Approved"}</td>
                                <td>{message.message}</td>
                                {refreshButton(message)}
                            </tr>
                        </React.Fragment>
                    );
                })
            }
        }


        return (
            <React.Fragment>
                <div className="legend">
                    <LoaderComponent loading={this.state.loading}/>
                    <Notifiction successString={this.state.successString} success={this.state.Success} errorString={this.state.errorString} error={this.state.error}/>
                    <h6 className="legend-title">
                        Messages
                    </h6>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            {/*<LoaderComponent loading={this.state.loading}/>*/}
                        </div>
                    </div>
                    <div className="row div-table-row">
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="date">Select Date for Publishment</label>
                                <input type="date" className="form-control" value={this.state.date} onChange={(e)=>this.setState({date:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="time">Select Time for Publishment</label>
                                <input type="time" className="form-control" value={this.state.time} onChange={(e)=>this.setState({time:e.target.value})}/>
                            </div>
                        </div>
                        <div className="col-md-12 div-table">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Messages To Customers</label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows={7} value={this.state.message} onChange={(e)=>this.setState({message:e.target.value})}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 mb-5">
                            <button className="btn btn-default btn-block" onClick={(e)=>this.createMessage()}>Create Message</button>
                        </div>
                        <div className="col-md-4 mb-5">
                            <button className="btn btn-default btn-block" onClick={(e)=>this.testMessage()}>Test Message</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Date Created</th>
                                    <th scope="col">Date Publishment</th>
                                    <th scope="col">Message Sent</th>
                                    <th scope="col">Approved</th>
                                    <th scope="col">Message</th>
                                    <th scope="col">Approve</th>
                                </tr>
                                </thead>
                                <tbody>
                                {allMessages()}
                                </tbody>
                            </table>
                            <ReactPaginate
                                containerClassName="pagination"
                                breakClassName="page-item"
                                breakLabel={<a className="page-link">...</a>}
                                pageClassName="page-item"
                                previousClassName="page-item"
                                nextClassName="page-item"
                                pageLinkClassName="page-link"
                                previousLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                activeClassName="active"
                                pageCount={this.props.messages?this.props.messages[1]/this.state.take:0}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={2}
                                onPageChange={this.logPaginate}
                            />

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        messages:state.messages
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {
            getMessageAction,
            createMessageAction,
            approveMessageAction,
            testMessageAction
        }, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(MessagesComponent);
