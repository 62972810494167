import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router-dom";
import {roleDataTypeInterface} from "../../dataInterface/roleDataTypeInterface";
import {PermissionDataInterface} from "../../dataInterface/permissionDataInterface";
import {getAllPermissionsAction} from "../../actions/permissions/getPermissionsAction";
import {assignPermissionToRole} from "../../actions/role/assignPermissionsToRoleAction";
import {getPermissionsByRoleAction} from "../../actions/permissions/getPermissionsByRoleAction";
import "./style/permission.scss";
import LoaderComponent from "../Loader/LoaderComponent";
import Notifiction from "../Notification/Notifiction";

interface internalState {
    loading:boolean,
    error:boolean,
    errorString:string,
    Success:boolean,
    successString:string,
    permissionsForSelectedRole:Array<string>,
    allPermissios:Array<{name:string,perm:Array<string>}>
}

interface parentProps {
}

interface externalStateProps {
    selectedRole:roleDataTypeInterface,
    permissions:Array<PermissionDataInterface>
}


interface DispatchProps {
    getAllPermissionsAction:(callback:(error:boolean)=>void)=>void,
    assignPermissionToRole:(data:object,callback:(error:boolean)=>void)=>void,
    getPermissionsByRoleAction:(data:object,callback:(permissions:Array<PermissionDataInterface>,error:boolean)=>void)=>void
}

type Props = externalStateProps & DispatchProps & parentProps & RouteComponentProps;

class PermissionsComponent extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
            errorString: "",
            Success: false,
            successString: "",
            permissionsForSelectedRole: [],
            allPermissios:[],
        };

        this.assignPermissions=this.assignPermissions.bind(this);
        this.permCheckBox=this.permCheckBox.bind(this);

    }

    isPermissionNameAlreadySet(name:string){

        return this.state.allPermissios.findIndex((perm)=>{
            return perm.name===name;
        })
    }

    async componentDidMount(): Promise<void> {
        this.getRoles();

        if (this.props.permissions) {
            await Promise.all(this.props.permissions.map((permission, index) => {

                let allPermissions = this.state.allPermissios;
                let permIndex=this.isPermissionNameAlreadySet(permission.name);
                if(permIndex===-1){
                    allPermissions.push({name:permission.name,perm:[permission.codeName]});
                    this.setState({
                        allPermissios: allPermissions
                    });
                }else {
                    allPermissions[permIndex].perm.push(permission.codeName)
                }


            }));
        }

        this.setState({loading:true});
        this.props.getPermissionsByRoleAction({roleId:this.props.selectedRole.id},async (permissions,error)=>{

            if(error){
                this.setState({
                    error:true,
                    successString:"",
                    Success:false,
                    errorString:"Server Error Detected in Getting Permissions for Selected Role",
                    loading:false
                });
            }else {
                let rolePermissions:Array<string>=[];
                await Promise.all(permissions.map((perm,index)=>{
                    rolePermissions.push(perm.codeName)
                }));
                this.setState({
                    loading:false,
                    permissionsForSelectedRole:rolePermissions
                })
            }

        })
    }


    isSelectedRoleInThisPermission(role:Array<roleDataTypeInterface>){

        return role.findIndex((data,index)=>{
            return this.props.selectedRole.id===data.id
        })

    }

    assignPermissions(){
        this.props.assignPermissionToRole({roleId:this.props.selectedRole.id,permissions:this.state.permissionsForSelectedRole},(error)=>{

            this.setState({loading:true});
            if(error){
                this.setState({
                    error:true,
                    successString:"",
                    Success:false,
                    errorString:"Server Error Detected",
                    loading:false
                });
            }else {
                this.setState({
                    error:false,
                    successString:"Permissions Added to Role",
                    Success:true,
                    errorString:"",
                    loading:false
                })
            }
        });
    }

    getRoles(){

        this.props.getAllPermissionsAction((error)=>{

            if(error){
                this.setState({
                    error:true,
                    successString:"",
                    Success:false,
                    errorString:"Server Error Detected"
                })
            }
        })
    }

    permCheckBox(e:React.ChangeEvent<HTMLInputElement>){
        let perms=this.state.permissionsForSelectedRole;

        if(!this.state.permissionsForSelectedRole.includes(e.target.value)){
            perms.push(e.target.value);
            this.setState({
                permissionsForSelectedRole:perms
            },()=>{

            });
        }else {
            let index=perms.indexOf(e.target.value);
            perms.splice(index,1);
            this.setState({
                permissionsForSelectedRole:perms
            })
        }
    }



    render() {

        let permissionsData=()=>{
            let permissions=(perms:Array<string>)=>{
                return perms.map((perm,index)=>{
                    return(
                       <li>
                           <div className="form-check mb-3">
                               <input type="checkbox" className="mr-1" checked={this.state.permissionsForSelectedRole.includes(perm)} value={perm} onChange={this.permCheckBox}/>
                               {perm}
                           </div>
                       </li>
                    )
                })
            };

            return this.state.allPermissios.map((permission,index)=>{
                return(
                    <React.Fragment>
                        <div className="legend col-md-4">
                            <h6 className="legend-title">
                                {permission.name}
                            </h6>
                            <div className="row">
                                <ul>
                                    {permissions(permission.perm)}
                                </ul>
                            </div>
                        </div>
                    </React.Fragment>
                )
            })

        };

        return (
            <React.Fragment>
                <LoaderComponent loading={this.state.loading}/>
                <Notifiction error={this.state.error} errorString={this.state.errorString} success={this.state.Success} successString={this.state.successString}/>
                <div className="row">
                    {permissionsData()}
                    <div className="col-md-12">
                        <Notifiction error={this.state.error} errorString={this.state.errorString} success={this.state.Success} successString={this.state.successString}/>
                        <LoaderComponent loading={this.state.loading}/>
                        <button className="btn btn-default float-right mb-3" onClick={this.assignPermissions}>Submit</button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        selectedRole:state.selectedRole,
        permissions:state.getPermissions
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {getAllPermissionsAction,assignPermissionToRole,getPermissionsByRoleAction}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsComponent);
