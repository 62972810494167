import {SimCardInterface} from "../../dataInterface/simCardInterface";


interface simCard {
    type:string
    payload:SimCardInterface
}

export default (state = null, action:simCard) => {
    switch (action.type) {
        case "ALL_SIMS":
            return action.payload;
        default:
            return state;
    }
};
