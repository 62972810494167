import axios,{AxiosInstance} from "axios";
import {server} from "../api/host";
import {CombinedState} from "redux";
import {RootState} from "../reducers";
import {persistor} from "../store";




export function HTTP(getState:  () => CombinedState<RootState>,dispatch:any):AxiosInstance {
    const instance=axios.create({

        baseURL: server.host,

        headers: {
            "Content-Type": "application/json",
            "Authorization":`Bearer ${getState().auth?.token}`

        }
    });


    // Add a response interceptor
    instance.interceptors.response.use(response => {

        // Check if the 'Authorization' header is present in the response
        if (response.headers && response.headers['refresh-token']) {
            const newAuthorizationHeader = response.headers['refresh-token'];
            console.log(newAuthorizationHeader);

            dispatch({
                type: 'AUTHENTICATION',
                payload: {
                    isAuthenticated:true,
                    token:response.headers['refresh-token']
                }
            });

            // Optionally, update the state or perform other actions
            // For example, you might want to store the new token
        }

        return response;
    }, error => {

        if (error.response) {
            const statusCode = error.response.status;

            // Here you can handle specific status codes
            if (statusCode === 401) {
                // Handle Unauthorized (401)
                // Maybe dispatch an action to log out the user or refresh the token
                dispatch({
                    type: 'RESET',
                });

                let execution=new Promise<void>(async (resolutionFunc,rejectionFunc)=>{
                    persistor.pause();
                    await persistor.purge();
                    resolutionFunc();
                });


                window.location.href="/login";

            } else if (statusCode === 500) {
                // Handle Internal Server Error (500)
            }
            // Add more cases as needed
        }

        return Promise.reject(error);
    });

    return instance;
}



