import axios,{AxiosResponse} from "axios";

import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import {serverRoutes} from "../../api/serverRoutes";
import {loginDatatype} from "../../dataInterface/loginTypes";
import {userResponseData} from "../../dataInterface/UsersDataInterface";
import {server} from "../../api/host";


const loginAction = (authData:{username:string,password:string},callback:(error:boolean)=>void):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {


    interface authData extends  AxiosResponse,loginDatatype{}

    try {
        const response:AxiosResponse = await axios.post(server.host + serverRoutes.deleteMyAccount,authData);

        let userData=response.data.userData as Array<userResponseData>;

        callback(false);

    }catch (e) {
        callback(true);
    }


};
export default loginAction;


