import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {UsersDataInterface} from "../../dataInterface/UsersDataInterface";
import {loginDatatype} from "../../dataInterface/loginTypes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMobile} from "@fortawesome/free-solid-svg-icons";


interface internalState {

}

interface parentProps {
}

interface externalStateProps {
    loggedInUser:loginDatatype,
    authUserPermissions:Array<string>

}


interface DispatchProps {
}

type Props = externalStateProps & DispatchProps & parentProps;

class Sidemenu extends React.Component<Props, internalState> {

    constructor(props:Props) {
        super(props);
    }

    componentDidMount(): void {
    }



    render() {
        const iconStyle = {
            color:'green'
        };


        let fullName=()=>{

            if(this.props.loggedInUser!==null){
                return(
                    <a href="#" className="d-block">{this.props.loggedInUser.fname +" "+ this.props.loggedInUser.lname}</a>
                );
            }


        };

        return (
            <React.Fragment>
                <aside className="main-sidebar sidebar-dark-primary elevation-4" >
                    {/*Brand Logo */}
                    <a href="index3.html" className="brand-link">
                        <img src="/dist/img/serdcom.svg" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{opacity: ".8"}}/>
                        <span className="brand-text font-weight-light">SERDCOM</span>
                    </a>

                    {/*Sidebar */}
                    <div className="sidebar">
                        {/*Sidebar user panel (optional) */}
                        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                            <div className="image">
                                <span style={{fontSize: '1.5em', color: 'Tomato'}}>
                                    <i className="far fa-user"/>
                                </span>
                            </div>
                            <div className="info">
                                {fullName()}
                            </div>
                        </div>
                        {/*Sidebar Menu */}
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                                data-accordion="false">
                                {/*Add icons to the links using the .nav-icon class
                                     with font-awesome or any other icon font library */}
                                <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        <i className="nav-icon fas fa-tachometer-alt"/>
                                        <p>
                                            Dashboard
                                            <i className="right fas fa-angle-left"/>
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <Link to="/admin" className="nav-link">
                                                <i className="far fa-circle nav-icon"/>
                                                <p>Dashboard</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                {
                                    (()=>{
                                        if(this.props.authUserPermissions?.includes("-admin-customer-")){
                                            return (
                                                <li className="nav-item">
                                                    <a href="#" className="nav-link">
                                                        <i className="nav-icon fas fa-users" style={iconStyle}/>
                                                        <p>
                                                            Customers
                                                            <i className="right fas fa-angle-left"/>
                                                        </p>
                                                    </a>
                                                    <ul className="nav nav-treeview">
                                                        <li className="nav-item">
                                                            <Link to="/admin/customer/" className="nav-link">
                                                                <i className="far fa-circle nav-icon"/>
                                                                <p>Customers</p>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>

                                            );
                                        }
                                    })()
                                }
                                {
                                    (()=>{
                                        if(this.props.authUserPermissions?.includes("-admin-devices-")){
                                            return(
                                                <li className="nav-item">
                                                    <a href="#" className="nav-link">
                                                        <i className="nav-icon fas fa-mobile" style={iconStyle}/>
                                                        <p>
                                                            Devices
                                                            <i className="right fas fa-angle-left"/>
                                                        </p>
                                                    </a>
                                                    { (()=>{
                                                        if(this.props.authUserPermissions?.includes("POST_register_Device")){
                                                            return(
                                                                <ul className="nav nav-treeview">
                                                                    <li className="nav-item">
                                                                        <Link to="/admin/devices/new" className="nav-link">
                                                                            <i className="far fa-circle nav-icon"/>
                                                                            <p>New Devices</p>
                                                                        </Link>
                                                                    </li>
                                                                </ul>

                                                            )
                                                        }
                                                    })()}
                                                    <ul className="nav nav-treeview">
                                                        <li className="nav-item">
                                                            <Link to="/admin/devices" className="nav-link">
                                                                <i className="far fa-circle nav-icon"/>
                                                                <p>All Devices</p>
                                                            </Link>
                                                        </li>                                    <ul className="nav nav-treeview">
                                                        <li className="nav-item">
                                                            <Link to="/admin/devices" className="nav-link">
                                                                <i className="far fa-circle nav-icon"/>
                                                                <p>All Devices</p>
                                                            </Link>
                                                        </li>
                                                    </ul>

                                                    </ul>
                                                </li>

                                            )
                                        }

                                    })()
                                }
                                {
                                    (()=>{
                                        if(this.props.authUserPermissions?.includes("-admin-subscription-type-")){
                                            return(
                                                <li className="nav-item">
                                                    <a href="#" className="nav-link">
                                                        <i className="nav-icon fas fa-typo3" style={iconStyle}/>
                                                        <p>
                                                            Subscription Type
                                                            <i className="right fas fa-angle-left"/>
                                                        </p>
                                                    </a>
                                                    <ul className="nav nav-treeview">
                                                        <li className="nav-item">
                                                            <Link to="/admin/subscription/type" className="nav-link">
                                                                <i className="far fa-circle nav-icon"/>
                                                                <p>View</p>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                            )
                                        }

                                    })()
                                }
                                {
                                    (()=>{
                                        if(this.props.authUserPermissions?.includes("-admin-simcard-")){
                                            return(
                                                <li className="nav-item">
                                                    <a href="#" className="nav-link">
                                                        <i className="nav-icon fas fa-sim-card" style={iconStyle}/>
                                                        <p>
                                                            Sim Cards
                                                            <i className="right fas fa-angle-left"/>
                                                        </p>
                                                    </a>
                                                    <ul className="nav nav-treeview">
                                                        <li className="nav-item">
                                                            <Link to="/admin/simcard" className="nav-link">
                                                                <i className="far fa-circle nav-icon"/>
                                                                <p>Sim Cards</p>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>

                                            )
                                        }

                                    })()
                                }
                                {
                                    (()=>{
                                        if(this.props.authUserPermissions?.includes("-admin-dealer")){
                                            return(
                                                <li className="nav-item">
                                                    <a href="#" className="nav-link">
                                                        <i className="nav-icon fas fa-lightbulb" style={iconStyle}/>
                                                        <p>
                                                            Dealer
                                                            <i className="right fas fa-angle-left"/>
                                                        </p>
                                                    </a>
                                                    <ul className="nav nav-treeview">
                                                        <li className="nav-item">
                                                            <Link to="/admin/dealer" className="nav-link">
                                                                <i className="far fa-circle nav-icon"/>
                                                                <p>Dealers</p>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>

                                            )
                                        }

                                    })()
                                }
                                {
                                    (()=>{
                                        if(this.props.authUserPermissions?.includes("-admin-users-dashboard-")){
                                            return(
                                                <li className="nav-item">
                                                    <a href="#" className="nav-link">
                                                        <i className="nav-icon fas fa-user-tie" style={iconStyle}/>
                                                        <p>
                                                            Users
                                                            <i className="right fas fa-angle-left"/>
                                                        </p>
                                                    </a>
                                                    <ul className="nav nav-treeview">
                                                        <li className="nav-item">
                                                            <Link to="/admin/users/dashboard" className="nav-link">
                                                                <i className="far fa-circle nav-icon"/>
                                                                <p>Users</p>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>

                                            )
                                        }

                                    })()
                                }
                                {
                                    (()=>{
                                        if(this.props.authUserPermissions?.includes("-admin-roles-dashboard-")){
                                            return(
                                                <li className="nav-item">
                                                    <a href="#" className="nav-link">
                                                        <i className="nav-icon fas fa-project-diagram" style={iconStyle}/>
                                                        <p>
                                                            Roles
                                                            <i className="right fas fa-angle-left"/>
                                                        </p>
                                                    </a>
                                                    <ul className="nav nav-treeview">
                                                        <li className="nav-item">
                                                            <Link to="/admin/roles/dashboard" className="nav-link">
                                                                <i className="far fa-circle nav-icon"/>
                                                                <p>Roles Dashboard</p>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>

                                            )
                                        }

                                    })()
                                }

                                {
                                    (()=>{
                                        if(this.props.authUserPermissions?.includes("-admin-bundle-")){
                                            return(
                                                <li className="nav-item">
                                                    <a href="#" className="nav-link">
                                                        <i className="nav-icon fas fa-hourglass-end" style={iconStyle}/>
                                                        <p>
                                                            Bundles
                                                            <i className="right fas fa-angle-left"/>
                                                        </p>
                                                    </a>
                                                    <ul className="nav nav-treeview">
                                                        <li className="nav-item">
                                                            <Link to="/admin/bundle/dashboard" className="nav-link">
                                                                <i className="far fa-circle nav-icon"/>
                                                                <p>Bundles</p>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>

                                            )
                                        }

                                    })()
                                }
                                {
                                    (()=>{
                                        if(this.props.authUserPermissions?.includes("-admin-mechanic-")){
                                            return(
                                                <li className="nav-item">
                                                    <a href="#" className="nav-link">
                                                        <i className="nav-icon fas fa-cogs" style={iconStyle}/>
                                                        <p>
                                                            Mechanics
                                                            <i className="right fas fa-angle-left"/>
                                                        </p>
                                                    </a>
                                                    <ul className="nav nav-treeview">
                                                        <li className="nav-item">
                                                            <Link to="/admin/mechanic/dashboard" className="nav-link">
                                                                <i className="far fa-circle nav-icon"/>
                                                                <p>Mechanics</p>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>

                                            )
                                        }

                                    })()
                                }
                                {
                                    (()=>{
                                        if(this.props.authUserPermissions?.includes("-admin-messages-")){
                                            return(
                                                <li className="nav-item">
                                                    <a href="#" className="nav-link">
                                                        <i className="nav-icon fas fa-envelope-open-text" style={iconStyle}/>
                                                        <p>
                                                            Messages
                                                            <i className="right fas fa-angle-left"/>
                                                        </p>
                                                    </a>
                                                    <ul className="nav nav-treeview">
                                                        <li className="nav-item">
                                                            <Link to="/admin/messages" className="nav-link">
                                                                <i className="far fa-circle nav-icon"/>
                                                                <p>Mesages</p>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>

                                            )
                                        }

                                    })()
                                }
                                {
                                    (()=>{
                                        if(this.props.authUserPermissions?.includes("-admin-settings-")){
                                            return(
                                                <li className="nav-item">
                                                    <a href="#" className="nav-link">
                                                        <i className="nav-icon fas fa-hammer" style={iconStyle}/>
                                                        <p>
                                                            Settings
                                                            <i className="right fas fa-angle-left"/>
                                                        </p>
                                                    </a>
                                                    <ul className="nav nav-treeview">
                                                        <li className="nav-item">
                                                            <Link to="/admin/settings" className="nav-link">
                                                                <i className="far fa-circle nav-icon"/>
                                                                <p>Settings</p>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>

                                            )
                                        }

                                    })()
                                }
                                {
                                    (()=>{
                                        if(this.props.authUserPermissions?.includes("-admin-report-")){
                                            return(
                                                <li className="nav-item">
                                                    <a href="#" className="nav-link">
                                                        <i className="nav-icon fas fa-newspaper" style={iconStyle}/>
                                                        <p>
                                                            Reports
                                                            <i className="right fas fa-angle-left"/>
                                                        </p>
                                                    </a>
                                                    <ul className="nav nav-treeview">
                                                        <li className="nav-item">
                                                            <Link to="/admin/report" className="nav-link">
                                                                <i className="far fa-circle nav-icon"/>
                                                                <p>Reports</p>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>

                                            )
                                        }

                                    })()
                                }
                                {
                                    (()=>{
                                        if(this.props.authUserPermissions?.includes("-admin-money-")){
                                            return(
                                                <li className="nav-item">
                                                    <a href="#" className="nav-link">
                                                        <i className="nav-icon fas fa-money-bill-wave" style={iconStyle}/>
                                                        <p>
                                                            Money
                                                            <i className="right fas fa-angle-left"/>
                                                        </p>
                                                    </a>
                                                    <ul className="nav nav-treeview">
                                                        <li className="nav-item">
                                                            <Link to="/admin/money" className="nav-link">
                                                                <i className="far fa-circle nav-icon"/>
                                                                <p>Money Dashboard</p>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>

                                            )
                                        }

                                    })()
                                }

                                <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        <i className="nav-icon fas fa-th"/>
                                        <p>
                                            SERDCOM
                                            <span className="right badge badge-danger">
                                                <i className="nav-icon fas fa-copyright"/>
                                            </span>
                                        </p>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        {/*/.sidebar-menu */}
                    </div>
                    {/*/.sidebar */}
                </aside>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        loggedInUser:state.user,
        authUserPermissions:state.authUserPermissions

    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(Sidemenu);
