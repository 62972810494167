import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import {Link} from "react-router-dom";


interface internalState {

}

interface parentProps {
}

interface externalStateProps {
}


interface DispatchProps {

}

type Props = externalStateProps & DispatchProps & parentProps;

class ReportDashboard extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {}

    }

    componentDidMount(): void {
    }

    render() {
        return (
            <React.Fragment>
                <div className="legend">
                    <h6 className="legend-title">
                        Report Types
                    </h6>
                    <div className="row div-table-row">
                        <div className="col-md-2 div-table div-table-title">
                            Mechanic Report
                        </div>
                        <div className="col-md-3 div-table div-table-value">
                            <Link to="/admin/report/mechanic">
                                <button className="btn btn-default">View</button>
                            </Link>
                        </div>
                        <div className="col-md-2 div-table div-table-title">
                            Device Sales
                        </div>
                        <div className="col-md-5 div-table div-table-value">
                            <Link to="/admin/report/device/sales">
                                <button className="btn btn-default">View</button>
                            </Link>
                        </div>
                        <div className="col-md-2 div-table div-table-title mt-4">
                            Subscription Payments
                        </div>
                        <div className="col-md-5 div-table div-table-value mt-4">
                            <Link to="/admin/report/subscription">
                                <button className="btn btn-default">View</button>
                            </Link>
                        </div>
                    </div>

                </div>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {}
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDashboard);
