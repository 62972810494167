import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import Notifiction from "../Notification/Notifiction";
import {createMechanicAction} from "../../actions/mechanic/createMechanicAction";
import {getAllMechanicsPageable} from "../../actions/mechanic/getAllmeachanicsPageableAction";
import ModalComponent from "../Modal/ModalComponent";
import ReactPaginate from "react-paginate";
import {mechanicsDataInterface} from "../../dataInterface/mechanicsDataInterface";
import {GivenToDataInterface} from "../../dataInterface/givenToDataInterface";


interface internalState {
    loading:boolean,
    modalLoder:boolean,
    Success:boolean,
    error:boolean,
    errorString:string,
    successString:string,
    showModal:boolean,
    fname:string,
    lname:string,
    phone:string,
    take:number,
    skip:number,

}

interface parentProps {
}

interface externalStateProps {
    mechanics:[Array<mechanicsDataInterface>,number]
}


interface DispatchProps {
    createMechanicAction:(data:object,callback:(error:boolean)=>void)=>void,
    getAllMechanicsPageable:(data:object,callback:(error:boolean)=>void)=>void,

}

type Props = externalStateProps & DispatchProps & parentProps;

class MechanicDashboard extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading:false,
            modalLoder:false,
            Success:false,
            error:false,
            errorString:"",
            successString:"",
            showModal:false,
            fname:"",
            lname:"",
            phone:"",
            take:6,
            skip:0,
        };

        this.handleShow=this.handleShow.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.getMechanics=this.getMechanics.bind(this);
        this.createMechanics=this.createMechanics.bind(this);
        this.paginate=this.paginate.bind(this);
        this.nextPage=this.nextPage.bind(this);
        this.previousPage=this.previousPage.bind(this);

    }

    handleShow(e:React.MouseEvent<HTMLButtonElement>){
        e.preventDefault();
        this.setState({
            showModal:true
        })
    }

    handleClose(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal:false
        })
    }

    createMechanics(){

        if(this.state.fname!==""  && this.state.lname!==""  && this.state.phone!==""){
            this.setState({
                loading:true,
                error:false,
                errorString:"",
                successString:"",
                Success:false,
            });

            this.props.createMechanicAction({
                fname:this.state.fname,
                lname:this.state.lname,
                phone:this.state.phone
            },(error)=>{
                if(error){
                    this.setState({
                        loading:false,
                        error:true,
                        errorString:"Server Error Detected",
                        successString:"",
                        Success:false,
                    });
                }else {
                    this.setState({
                        loading:false,
                        error:false,
                        errorString:"",
                        successString:"Mechanic Created",
                        Success:true,
                        fname:"",
                        lname:"",
                        phone:""
                    });
                    this.getMechanics();
                }
            })

        }else {
            this.setState({
                loading:false,
                error:true,
                errorString:"Fill all Required Fields",
                successString:"",
                Success:false,
            });
        }
    }


    getMechanics(){
        this.setState({
            loading:true
        });
        this.props.getAllMechanicsPageable({
            skip:this.state.skip,
            take:this.state.take
        },(error)=>{
            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Server Error Detected",
                    successString:"",
                    Success:false,
                });
            }else {
                this.setState({
                    loading:false,
                });
            }
        });
    }


    paginate(data:{selected:number}){
        this.setState({
            skip: this.state.take * data.selected
        },()=>{
            this.getMechanics();
        })
    }
    nextPage(){
        this.setState({
            skip:this.state.skip+10,
        },()=>{
            this.getMechanics();

        });
    }

    previousPage(){
        this.setState({
            skip:this.state.skip-10,
        },()=>{
            this.getMechanics();
        });
    }

    componentDidMount(): void {
        this.getMechanics();
    }

    render() {

        let checkDeviceIfPaid=(data:Array<GivenToDataInterface>)=>{

            let remainingDevices=0;

            for (let i=0; i < data.length; i++){
                if(!data[i].device.paid && !data[i].device.faulty){
                    remainingDevices++;
                }
            }
            return remainingDevices;
        };


        let mechanics=()=>{

            if(this.props.mechanics){
                return this.props.mechanics[0].map((mechanic,index)=>{

                    return(
                        <tr>
                            <th scope="row">{index+1}</th>
                            <td>{mechanic.fname.toUpperCase()}</td>
                            <td>{mechanic.lname}</td>
                            <td>{mechanic.phone}</td>
                            <td>{checkDeviceIfPaid(mechanic.givenTo)}</td>
                        </tr>
                    )
                })

            }
        };




        return (
            <React.Fragment>
                <Notifiction error={this.state.error} success={this.state.Success} errorString={this.state.errorString} successString={this.state.successString}/>
                <div className="legend">
                    <h6 className="legend-title">
                        Roles
                    </h6>
                    <div className="row div-table-row">
                        <div className="col-md-4 div-table div-table-title" >
                            Create Roles
                        </div>
                        <div className="col-md-3 div-table div-table-value" >
                            <button className="btn btn-default" onClick={(e)=>this.handleShow(e)}>Register Mechanic</button>
                        </div>
                        <div className="col-md-2 div-table div-table-title">
                            Upload
                        </div>
                        <div className="col-md-2 div-table div-table-value">
                        </div>
                    </div>
                </div>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Mechanic First Name</th>
                        <th scope="col">Mechanic Last Name</th>
                        <th scope="col">Mechanic Phone Number</th>
                        <th scope="col">Number of Devices</th>
                    </tr>
                    </thead>
                    <tbody>
                    {mechanics()}
                    </tbody>
                </table>

                <ModalComponent
                    title="Register Mechanic"
                    handleShow={this.handleShow}
                    show={this.state.showModal}
                    handleClose={this.handleClose}
                    loader={this.state.modalLoder}
                    save={this.createMechanics}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    <div className="row">
                        <div className="col-12">
                            <form>
                                <div className="form-group">
                                    <div className="form-row">
                                        <div className="input-group mb-2 col-md-4">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">First Name</div>
                                            </div>
                                            <input type="text" className="form-control" placeholder="First Name"  value={this.state.fname}  onChange={(e)=>this.setState({fname:e.target.value})}/>
                                        </div>
                                        <div className="input-group mb-2 col-md-8">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Last Name</div>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Last Name"  value={this.state.lname}  onChange={(e)=>this.setState({lname:e.target.value})}/>
                                        </div>
                                        <div className="input-group mb-2 col-md-8">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Phone Number</div>
                                            </div>
                                            <input type="number" className="form-control" placeholder="Phone Number"  value={this.state.phone}  onChange={(e)=>this.setState({phone:e.target.value})}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ModalComponent>
                <ReactPaginate
                    containerClassName="pagination"
                    breakClassName="page-item"
                    breakLabel={<a className="page-link">...</a>}
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    pageCount={this.props.mechanics?this.props.mechanics[1]/this.state.take:0}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    onPageChange={this.paginate}
                />
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        mechanics:state.mechanicsPageable
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {
            createMechanicAction,
            getAllMechanicsPageable
        }, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(MechanicDashboard);
