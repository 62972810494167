
interface loggenInUserReducer {
    type:string
    payload:{fname: string, lname: string, username: string, role: {id: number, name: string}}
}

export default (state = null, action:{type:string,payload:loggenInUserReducer}) => {
    switch (action.type) {
        case "USER_DATA":
            return action.payload;
        default:
            return state;
    }
};