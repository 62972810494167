import {bundleDataInterface} from "../../dataInterface/bundleDataInterface";


interface BundleReducer {
    type:string
    payload:bundleDataInterface
}

export default (state = null, action:BundleReducer) => {
    switch (action.type) {
        case "ALL_BUNDLES":
            return action.payload;
        default:
            return state;
    }
};
