import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import {dealerDataInterface} from "../../dataInterface/dealerDataInterface";
import {getDealersAction} from "../../actions/dealer/getDealersAction";
import {searchDealerAction} from "../../actions/dealer/searchDealerAction";
import {selectedDealer} from "../../actions/dealer/selectedDealerAction"
import Notifiction from "../Notification/Notifiction";
import ReactPaginate from "react-paginate";
import moment from "moment";
import {Link} from "react-router-dom";
import {GivenToDataInterface} from "../../dataInterface/givenToDataInterface";



interface internalState {
    loading:boolean,
    take:number,
    skip:number,
    error:boolean,
    success:boolean,
    errorString:string,
    successString:string,
    dealer:string
}

interface parentProps {
}

interface externalStateProps {
    dealers:[Array<dealerDataInterface>,number]
}


interface DispatchProps {
    getDealersAction:(data:object,callback:(error:boolean)=>void)=>void,
    searchDealerAction:(data:object,callback:(error:boolean)=>void)=>void,
    selectedDealer:(dealer:dealerDataInterface)=>void,

}

type Props = externalStateProps & DispatchProps & parentProps;

class DealerViewComponent extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading:false,
            take:6,
            skip:0,
            error:false,
            success:false,
            errorString:"",
            successString:"",
            dealer:""

        };
        this.onchangeSearch=this.onchangeSearch.bind(this);
        this.search=this.search.bind(this);
        this.selectedDealer=this.selectedDealer.bind(this);
        this.paginate=this.paginate.bind(this);

    }

    componentDidMount(): void {
        this.loadDealers();
    }

    search(e:React.MouseEvent){
        e?.preventDefault();

        if(this.state.dealer!==""){

            this.setState({
                loading:true,
                success:false,
                error:false,
                errorString:"",
                successString:""
            });
            this.props.searchDealerAction({take:this.state.take,skip:this.state.skip,dealer:this.state.dealer},(error)=>{

                if(error){
                    this.setState({
                        error:true,
                        errorString:"Server Error Detected",
                        success:false,
                        successString:"",
                        loading:false
                    })
                }else {
                    this.setState({
                        loading:false
                    })
                }
            })
        }else {
            this.setState({
                error:true,
                errorString:"Please Enter Dealer Name To search",
                success:false,
                successString:""
            })
        }

    }


    loadDealers(){
        this.setState({loading:true});
        this.props.getDealersAction({take:this.state.take,skip:this.state.skip},(error)=>{

            if(error){
                this.setState({
                    error:true,
                    errorString:"Server Error Detected",
                    success:false,
                    successString:"",
                    loading:false
                })
            }else {
                this.setState({
                   loading:false
                })
            }
        })
    }

    onchangeSearch(e:React.ChangeEvent<HTMLInputElement>){
        this.setState({dealer:e.target.value},()=>{
            if(this.state.dealer==""){
                this.loadDealers();
            }
        });

    }

    paginate(data:{selected:number}){

        this.setState({
            skip: this.state.take * data.selected
        },()=>{
            this.loadDealers();
        })
    }
    nextPage(){
        this.setState({
            skip:this.state.skip+10,
        },()=>{
            this.loadDealers();

        });
    }

    previousPage(){
        this.setState({
            skip:this.state.skip-10,
        },()=>{
            this.loadDealers();
        });
    }

    selectedDealer(dealer:dealerDataInterface){

        this.props.selectedDealer(dealer);
    }

    render() {

        let checkDeviceIfPaid=(data:Array<GivenToDataInterface>)=>{

            let remainingDevices=0;

            for (let i=0; i < data.length; i++){
                if(!data[i].device.paid && !data[i].device.faulty){
                    remainingDevices++;
                }
            }
            return remainingDevices;
        };

        let dealers=()=>{
            return   this.props.dealers?this.props.dealers[0].map((dealer,index)=>{

                return(
                    <React.Fragment>
                        <tr key={dealer.id}>
                            <th scope="row">{index+1}</th>
                            <td>{dealer.dealerName}</td>
                            <td>{dealer.phoneNumber}</td>
                            <td>{moment(dealer.createdAt).format("YYYY:MM:DD HH:ss")}</td>
                            <td>{checkDeviceIfPaid(dealer.givenTo)}</td>
                            <td>
                                <Link to="/admin/dealer/detail">
                                    <button className="btn btn-default" onClick={()=>this.selectedDealer(dealer)}>Details</button>
                                </Link>
                            </td>
                        </tr>
                    </React.Fragment>
                )
            }):<React.Fragment/>
        };




        return (
            <React.Fragment>
                <div className="row mb-4">
                    <div className="col-12">
                        <Notifiction success={this.state.success} error={this.state.error} successString={this.state.successString} errorString={this.state.errorString}/>
                        <div className="col-auto">
                            <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Search Dealer</div>
                                </div>
                                <input type="text" className="form-control" placeholder="Name"  value={this.state.dealer}  onChange={this.onchangeSearch}/>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" onClick={this.search}>Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Dealer Name</th>
                        <th scope="col">Phone Number</th>
                        <th scope="col">Date Registered</th>
                        <th scope="col">Devices</th>
                    </tr>
                    </thead>
                    <tbody>
                    {dealers()}
                    </tbody>
                </table>


                <ReactPaginate
                    containerClassName="pagination"
                    breakClassName="page-item"
                    breakLabel={<a className="page-link">...</a>}
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    pageCount={this.props.dealers?this.props.dealers[1]/this.state.take:0}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    onPageChange={this.paginate}
                />
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        dealers:state.dealers
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {getDealersAction,searchDealerAction,selectedDealer}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(DealerViewComponent);
