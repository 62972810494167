import React, {ButtonHTMLAttributes} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import {fetchRolesPageableAction} from "../../actions/role/fetchRolesPageableAction";
import {roleDataTypeInterface} from "../../dataInterface/roleDataTypeInterface";
import {getAllPermissionsAction} from "../../actions/permissions/getPermissionsAction";
import ModalComponent from "../Modal/ModalComponent";
import {PermissionDataInterface} from "../../dataInterface/permissionDataInterface";
import {assignPermissionToRole} from "../../actions/role/assignPermissionsToRoleAction";
import {createRole} from "../../actions/role/createRoleAction";
import {selectedRoleAction} from "../../actions/role/selectedRoleAction";
import {Link, withRouter,RouteComponentProps} from "react-router-dom";

import ReactPaginate from "react-paginate";

interface internalState {
    loading:boolean,
    modalLoder:boolean,
    Success:boolean,
    error:boolean,
    errorString:string,
    successString:string,
    showModal:boolean,
    selectedRole:string,
    permissionsToEdit:Array<number>,
    selectedRoleId:number,
    roleName:string,
    roleCode:string,
    showModalRole:boolean,
    take:number,
    skip:number
}

interface parentProps {
}

interface externalStateProps {
    roles:[Array<roleDataTypeInterface>,number],
    permissions:Array<PermissionDataInterface>,
}


interface DispatchProps {
    fetchRolesPageableAction:(data:object,callback:(error:boolean)=>void)=>void,
    assignPermissionToRole:(roles:object,callback:()=>void)=>void,
    createRole:(roles:object,callback:(error:boolean)=>void)=>void,
    selectedRoleAction:(role:roleDataTypeInterface)=>void
}

type Props = externalStateProps & internalState & DispatchProps & parentProps & RouteComponentProps;

class RolesComponent extends React.Component<Props, internalState> {


    constructor(props: Props) {
        super(props);

        this.state = {
            loading:false,
            modalLoder:false,
            Success:false,
            error:false,
            errorString:"",
            successString:"",
            showModal:false,
            showModalRole:false,
            selectedRole:"",
            permissionsToEdit:[],
            selectedRoleId:0,
            roleName:"",
            roleCode:"",
            take:6,
            skip:0
        };

        this.handleShow=this.handleShow.bind(this);
        this.showCreateRole=this.showCreateRole.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.editPermissionsForRole=this.editPermissionsForRole.bind(this);
        this.editPermissionsSubmit=this.editPermissionsSubmit.bind(this);
        this.createRole=this.createRole.bind(this);
        this.handleCloseRole=this.handleCloseRole.bind(this);
        this.paginate=this.paginate.bind(this);
        this.nextPage=this.nextPage.bind(this);
        this.previousPage=this.previousPage.bind(this);
        this.selectedRole=this.selectedRole.bind(this);
    }

    componentDidMount(): void {
        this.getAllRoles();
    }


    getAllRoles(){
        this.setState({loading:true});
        this.props.fetchRolesPageableAction({take:this.state.take,skip:this.state.skip,pageable:true},(error)=>{
            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Server Error Detected",
                    successString:"",
                    Success:false,
                });
            }

        });
    }

    selectedRole(e:React.MouseEvent<HTMLButtonElement, MouseEvent>,role:roleDataTypeInterface){
        e.preventDefault();
        this.props.selectedRoleAction(role);
        this.props.history.push('/admin/permissions');
    }

    createRole(){

        this.setState({loading:true});
        console.log({name:this.state.roleName.trim(),codeName:this.state.roleCode.toLowerCase().trim(),take:this.state.take,skip:this.state.skip})
        if(this.state.roleName!=="" && this.state.roleCode!==""){
            this.props.createRole({name:this.state.roleName.trim(),codeName:this.state.roleCode.toLowerCase().trim(),take:this.state.take,skip:this.state.skip},(error)=>{
                if(error){
                    this.setState({
                        loading:false,
                        error:true,
                        errorString:"Server Error Detected",
                        successString:"",
                        Success:false,
                    });
                }else {
                    this.setState({
                        loading:false,
                        error:false,
                        errorString:"",
                        successString:"Role Created",
                        Success:true,
                        roleName:"",
                        roleCode:""
                    },()=>{
                        this.getAllRoles();
                    })
                }
            })

        }else {
            this.setState({
                loading:false,
                error:true,
                errorString:"Pleas Fill Required Fields",
                successString:"",
                Success:false,
            });
        }
    }


    showCreateRole(e:React.MouseEvent<HTMLButtonElement>){
        e.preventDefault();
        this.setState({
            showModalRole:true
        })
    }

    handleCloseRole(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModalRole:false
        })
    }

    handleShow(e:React.MouseEvent<HTMLButtonElement>){
        e.preventDefault();
        this.setState({
            showModal:true
        })
    }

    handleClose(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal:false
        })
    }

    editPermissionsForRole(e: React.ChangeEvent, roles: Array<roleDataTypeInterface>,permissionId:number){
        let permissionsToEdit:Array<number>=this.state.permissionsToEdit;

        permissionsToEdit.push(permissionId);

        this.setState({
            permissionsToEdit,
        })
    }

    editPermissionsSubmit(e:React.MouseEvent){
        e.preventDefault();

        this.setState({
            loading:true
        });

        this.props.assignPermissionToRole({
            edit:this.state.permissionsToEdit,
            roleId:this.state.selectedRoleId
        },()=>{
            this.setState({
                loading:false,
                permissionsToEdit:[],
            });
        })
    }

    isRoleInPermission(roles:Array<roleDataTypeInterface>):boolean{

        if(roles.length!==0){
            let ifFound=roles.findIndex((role)=>{

                return role.name===this.state.selectedRole
            });

            return ifFound != -1;
        }else return false;

    }
    paginate(data:{selected:number}){
        this.setState({
            skip: this.state.take * data.selected
        },()=>{
            this.getAllRoles();
        })
    }
    nextPage(){
        this.setState({
            skip:this.state.skip+10,
        },()=>{
            this.getAllRoles();

        });
    }

    previousPage(){
        this.setState({
            skip:this.state.skip-10,
        },()=>{
            this.getAllRoles();
        });
    }

    render() {

        let roles=()=>{

            if(this.props.roles){
                return this.props.roles[0].map((role,index)=>{

                    return(
                        <tr>
                            <th scope="row">{index+1}</th>
                            <td>{role.name.toUpperCase()}</td>
                            <td>{role.codeName.toUpperCase()}</td>
                            <button className="btn btn-dark" onClick={(e)=>this.selectedRole(e,role)}>Permissions</button>
                        </tr>
                    )
                })

            }
        };

        let permission=()=>this.props.permissions?.map((permission,index)=>{
            return(
                <tr>
                    <th scope="row">{index + 1}</th>
                    <td>{permission.name}</td>
                    <td>{permission.codeName}</td>
                    <td>
                        <div className="form-check">
                            {/*<input className="form-check-input" type="checkbox"*/}
                            {/*       value={permission.id}*/}
                            {/*       onChange={(e)=>this.editPermissionsForRole(e,permission.roles,permission.id)}*/}
                            {/*       defaultChecked={this.isRoleInPermission(permission.roles)}*/}
                            {/*/>*/}
                        </div>
                    </td>
                </tr>
            )
        })
        return (
            <React.Fragment>
                <div className="legend">
                    <h6 className="legend-title">
                        Roles
                    </h6>
                    <div className="row div-table-row">
                        <div className="col-md-4 div-table div-table-title" >
                            Create Roles
                        </div>
                        <div className="col-md-3 div-table div-table-value" >
                            <button className="btn btn-default" onClick={(e)=>this.handleShow(e)}>Create Role</button>
                        </div>
                        <div className="col-md-2 div-table div-table-title">
                            Upload
                        </div>
                        <div className="col-md-2 div-table div-table-value">
                        </div>
                    </div>
                </div>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Role Name</th>
                        <th scope="col">Code Name</th>
                    </tr>
                    </thead>
                    <tbody>
                    {roles()}
                    </tbody>
                </table>
                <ModalComponent
                    title="Create Role"
                    handleShow={this.handleShow}
                    show={this.state.showModal}
                    handleClose={this.handleClose}
                    loader={this.state.modalLoder}
                    save={this.editPermissionsSubmit}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    <div className="row">
                        <div className="col-12">
                            <form>
                                <div className="form-group">
                                    <div className="form-row">
                                        <div className="input-group mb-2 col-md-6">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Role Name</div>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Name"  value={this.state.roleName}  onChange={(e)=>this.setState({roleName:e.target.value})}/>
                                        </div>

                                        <div className="input-group mb-2 col-md-6">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Role Code</div>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Code"  value={this.state.roleCode}  onChange={(e)=>this.setState({roleCode:e.target.value})}/>
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="button" onClick={this.createRole}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    </ModalComponent>
                <ModalComponent
                    title="Create New Role"
                    handleShow={this.showCreateRole}
                    show={this.state.showModalRole}
                    handleClose={this.handleCloseRole}
                    loader={this.state.modalLoder}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    <div className="row">
                        <div className="col-12">
                            <form>
                                <div className="form-group">
                                    <div className="form-row">
                                        <div className="input-group mb-2 col-md-6">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Role Name</div>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Name"  value={this.state.roleName}  onChange={(e)=>this.setState({roleName:e.target.value})}/>
                                        </div>

                                        <div className="input-group mb-2 col-md-6">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Role CodeName</div>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Name"  value={this.state.roleCode}  onChange={(e)=>this.setState({roleCode:e.target.value})}/>
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="button" >Submit2</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ModalComponent>
                <ReactPaginate
                    containerClassName="pagination"
                    breakClassName="page-item"
                    breakLabel={<a className="page-link">...</a>}
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    pageCount={this.props.roles?this.props.roles[1]/this.state.take:0}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    onPageChange={this.paginate}
                />

            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        roles:state.allRolesPageable,
        permissions:state.getPermissions
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {
            fetchRolesPageableAction,
            assignPermissionToRole,
            createRole,
            selectedRoleAction
        }, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(RolesComponent);
