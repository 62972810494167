import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import "./loginstyle.scss";
import loginAction from "../../actions/login/loginAction";

import Joi from "@hapi/joi";
import {withRouter,RouteComponentProps} from "react-router-dom";
import {loginDatatype} from "../../dataInterface/loginTypes";
import LoaderComponent from "../Loader/LoaderComponent";
import {authUserPermissionsAction} from "../../actions/permissions/authUserPermissionsAction";


interface internalState {
    username:string,
    password:string,
    loading:boolean,
    error:boolean,
}

interface parentProps {
}

interface mapStateToprops {
    auth: { isAuthenticated:boolean, token:string|undefined }|null,
    user:loginDatatype,
    authUserPermissions:Array<string>
}


interface DispatchProps {
    loginAction:(authData:{username:string,password:string},callback:()=>void)=>void,
    authUserPermissionsAction:(data:Array<string>)=>void

}

type Props = mapStateToprops & DispatchProps & parentProps & RouteComponentProps;


class Login2Component extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state={
            username:"",
            password:"",
            loading:false,
            error:false,

        };
        this.submit=this.submit.bind(this);
    }

    componentDidMount(): void {
    }

    async getAllPermissionsFromRoles(){

        let permissions:Array<string>=[];

        if(this.props.user!==null){
            await Promise.all(this.props.user?.role.map( async (role,index)=>{

                if(role.permission!==null && role.permission!==undefined){
                    await Promise.all(role.permission.map((perm,index)=>{
                        if(!permissions.includes(perm.codeName)){
                            permissions.push(perm.codeName);
                        }
                    }));

                }
            }));
            this.props.authUserPermissionsAction(permissions);

        }


    }


    static getDerivedStateFromProps(props:Props,state:internalState){
        if(props.auth!==null && props.user!==null && props.authUserPermissions?.includes("-admin-")){
            if(props.auth?.isAuthenticated && props.user?.role!==null){
                props.history.push("/admin")
            }
        }

        return null;
    }
    componentWillUnmount(){
        this.setState = (state,callback)=>{
            return;
        };
    }

    submit(e:React.MouseEvent<HTMLButtonElement>){

        e.preventDefault();
        const schema=Joi.object({
            username:Joi.string().alphanum().required(),
            password:Joi.string()
        });

        let validate=schema.validate({username:this.state.username,password:this.state.password});

        if(!validate.error){
            this.setState({loading:true,error:false})
            this.props.loginAction({username:this.state.username,password:this.state.password},()=>{
                this.setState({loading:false});
                this.getAllPermissionsFromRoles();
            })
        }else {
            this.setState({loading:false,error:true})
        }

    }


    render() {
        let loginError=()=>{
            if(this.props.auth !==null || this.state.error){
                if(this.props.auth?.token === undefined || this.state.error){
                    return(
                        <span className="text-danger">Wrong Username or Password</span>
                    );
                }
            }
        };

        return (
            <React.Fragment>
                <div className="hold-transition login-page" style={{backgroundImage:'url("../../dist/img/company.png")',backgroundRepeat:'no-repeat'}}>
                    <div className="login-box" >
                        <div className="card card-outline card-primary" >
                            <div className="card-header text-center">
                                <a href="#">SERDCOM</a>
                            </div>
                            <div className="card-body">
                                <p className="login-box-msg">Sign in to start your session</p>
                                <div className="text-center">
                                    <LoaderComponent loading={this.state.loading}/>
                                    {loginError()}
                                </div>
                                <div>
                                    <div className="input-group mb-3">
                                        <input  onChange={(e: React.ChangeEvent<HTMLInputElement>)=>this.setState({username:e.target.value})}
                                                type="text"
                                                value={this.state.username}
                                                className="form-control"
                                                placeholder="User Name"
                                                name="username"
                                        />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-envelope"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input onChange={(e: React.ChangeEvent<HTMLInputElement>)=>this.setState({password:e.target.value})}
                                                 type="password"
                                                 value={this.state.password}
                                                 className="form-control"
                                                 placeholder="Password"
                                        />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-lock"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <button type="submit" className="btn btn-primary btn-block" onClick={this.submit}>Sign In</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): mapStateToprops {
    return {
        auth:state.auth,
        user:state.user,
        authUserPermissions:state.authUserPermissions
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {
            loginAction,
            authUserPermissionsAction
        }, dispatch)

}

export default withRouter<RouteComponentProps,React.ComponentType<any>>(connect(mapStateToProps, mapDispatchToProps)(Login2Component as any));
