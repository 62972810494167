import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import "./index.scss";
import {Link, withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";



interface internalState {
}

interface parentProps {
}

interface mapStateToprops {
}


interface DispatchProps {}

type Props = mapStateToprops & DispatchProps & parentProps & RouteComponentProps;


class IndexComponent extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
    }

    componentDidMount(): void {
    }


    render() {
        return (
            <React.Fragment>
                <div className="main-content-index"  style={{backgroundImage:'url("../../dist/img/company.png")',backgroundRepeat:'no-repeat'}}>
                    <div className="row">
                        <div className="col-12 image-data">
                            <div className="row image-container">
                                <div className="col-6">
                                    <img src="../../dist/img/letstrakApp.png" width={300} alt=""/>
                                </div>
                            </div>
                            <div className="row image-container">
                                <div className="col-6">
                                    <small>@2023 SERDCOM</small>&nbsp;&nbsp;&nbsp;
                                    <Link to="/account/delete">Delete My Data</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): mapStateToprops {
    return {
        auth:state.auth,
        user:state.user,
        authUserPermissions:state.authUserPermissions
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators({}, dispatch)

}

export default withRouter<RouteComponentProps,React.ComponentType<any>>(connect(mapStateToProps, mapDispatchToProps)(IndexComponent as any));
