import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {subScriptionSalesReportMonthlyInterface} from "../../dataInterface/deviceSalesReportMonthlyInterface";
import LoaderComponent from "../Loader/LoaderComponent";
import Notifiction from "../Notification/Notifiction";
import {getSubscriptionReportSalesMonthly} from "../../actions/report/getSubscriptionReportSalesAction";

interface internalState {
    stastData:Array<{name:string,data:Array<number>}>
    loading:boolean,
    Success:boolean,
    error:boolean,
    errorString:string,
    successString:string,
    year:string,
}

interface parentProps {
}

interface externalStateProps {
    subscriptionSales:subScriptionSalesReportMonthlyInterface
}


interface DispatchProps {
    getSubscriptionReportSalesMonthly:(data:object,callback:(error:boolean)=>void)=>void
}

type Props = externalStateProps & DispatchProps & parentProps;

class SubscriptionStatisticsComponent extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            stastData:[],
            loading:false,
            Success:false,
            error:false,
            errorString:"",
            successString:"",
            year:'',
        };

        this.getSubscriptions=this.getSubscriptions.bind(this);
    }

    static getDerivedStateFromProps(props:Props,state:internalState){

        return {stastData:props.subscriptionSales}

    }


    getSubscriptions(e:React.ChangeEvent<HTMLSelectElement>){

        this.setState({
            year:e.target.value
        },()=>{

            this.props.getSubscriptionReportSalesMonthly({year:this.state.year},(error)=>{

                if(error){
                    this.setState({
                        loading:false,
                        error:true,
                        errorString:"Server Error Detected",
                        successString:"",
                        Success:false,
                    });
                }else {

                }
            });
        })

    }

    componentDidMount(): void {
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <LoaderComponent loading={this.state.loading}/>
                        <Notifiction error={this.state.error} success={this.state.Success} errorString={this.state.errorString} successString={this.state.successString}/>
                    </div>
                    <div className="col-md-12">
                        <select className="custom-select custom-select-lg mb-3" onChange={this.getSubscriptions}>
                            <option selected>Select Year</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                        </select>
                    </div>
                    <div className="col-md-12">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                chart: {
                                    type: 'line'
                                },
                                title: {
                                    text: 'Subscription Sales Statistics'
                                },
                                subtitle: {
                                    text: 'Sales'
                                },
                                xAxis: {
                                    categories: [
                                        'Jan',
                                        'Feb',
                                        'Mar',
                                        'Apr',
                                        'May',
                                        'Jun',
                                        'Jul',
                                        'Aug',
                                        'Sep',
                                        'Oct',
                                        'Nov',
                                        'Dec'
                                    ],
                                },
                                yAxis: {
                                    min: 0,
                                    title: {
                                        text: 'Devices'
                                    }
                                },
                                plotOptions: {
                                    line: {
                                        dataLabels: {
                                            enabled: true
                                        },
                                        enableMouseTracking: true
                                    }
                                },
                                series: this.state.stastData
                            }
                            }
                        />

                    </div>
                </div>

            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        subscriptionSales:state.subscriptionSales

    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {
            getSubscriptionReportSalesMonthly
        }, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionStatisticsComponent);
