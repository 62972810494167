import axios from "axios";
import {HTTP} from "../../helpers/axios";
// src/thunks.ts

import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import {serverRoutes} from "../../api/serverRoutes";

export const fetchUsers = (data:object,callback:(error:boolean)=>void):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {


    try {
        let response=await HTTP(getState,dispatch).post(serverRoutes.getAllUsers,data);
        dispatch({
            type: 'FETCH_USERS',
            payload: response.data
        });

        let error=false;
        callback(error);
    }catch (e) {

        let error=true;
        callback(error);
    }

};

export default fetchUsers;
