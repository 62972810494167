import {SimCardInterface} from "../../dataInterface/simCardInterface";
import {subScriptionTypeDataInterface} from "../../dataInterface/subScriptionTypeDataInterface";


interface subType {
    type:string
    payload:subScriptionTypeDataInterface
}

export default (state = null, action:subType) => {
    switch (action.type) {
        case "SUBSCRIPTION_TYPES_PAGEABLE":
            return action.payload;
        default:
            return state;
    }
};
