import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import LoaderComponent from "../Loader/LoaderComponent";
import {reconciliation} from "../../actions/settings/reconsiliationAction";
import Notifiction from "../Notification/Notifiction";

interface internalState {
    loading:boolean,
    error:boolean,
    errorString:string,
    Success:boolean,
    successString:string,
}

interface parentProps {
}

interface externalStateProps {
}


interface DispatchProps {
    reconciliation:(callback:(error:boolean)=>void)=>void
}

type Props = externalStateProps & DispatchProps & parentProps;

class SettingsComponent extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading:false,
            errorString:"",
            error:false,
            Success:false,
            successString:""
        }

    }

    componentDidMount(): void {
    }

    reconciliation(e:React.MouseEvent<HTMLButtonElement>){
        e.preventDefault();

        this.setState({loading:true});

        this.props.reconciliation((error)=>{

            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Server Error Detected",
                    Success:false,
                    successString:""
                });
            }else {
                this.setState({
                   loading:false,
                    error:false,
                    errorString:"",
                    Success:true,
                    successString:"Reconciliation SuccessFull"
                });
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="legend">
                    <LoaderComponent loading={this.state.loading}/>
                    <Notifiction successString={this.state.successString} success={this.state.Success} errorString={this.state.errorString} error={this.state.error}/>
                    <h6 className="legend-title">
                        Settings
                    </h6>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            {/*<LoaderComponent loading={this.state.loading}/>*/}
                        </div>
                    </div>
                    <div className="row div-table-row">
                        <div className="col-md-2 div-table">
                            <button className="btn btn-default" onClick={(e)=>this.reconciliation(e)}>
                                <i className="right fas fa-signal"/>
                                &nbsp;
                                Device Traccar Id Sync
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {}
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {reconciliation}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsComponent);
