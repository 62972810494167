import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import {getSubscriptiontypes} from "../../actions/subscription/getsubscriptionTypeAction";
import {setDeviceType} from "../../actions/device/typeOfDeviceAction";
import {setDeviceVersion} from "../../actions/device/setDeviceVersionAction";
import {subScriptionTypeDataInterface} from "../../dataInterface/subScriptionTypeDataInterface";
import {deviceInterface} from "../../dataInterface/deviceDataInterface";
import History from "history";


interface internalState {
    subType:string,
    deviceTVersion:string
}

interface parentProps {
    history?:History.History,
}

interface externalStateProps {
    subScriptionTypes:Array<subScriptionTypeDataInterface>,
    selectedDevice:deviceInterface,

}


interface DispatchProps {
    getSubscriptiontypes:(callback:(error:boolean)=>void)=>void,
    setDeviceType:(data:object)=>void,
    setDeviceVersion:(data:object)=>void
}

type Props = externalStateProps & DispatchProps & parentProps;

class SelectDeviceTypeComponent extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            subType:'',
            deviceTVersion:'V2'
        }

        this.next=this.next.bind(this);

    }


    setTypeAndVersion(){
        if(this.state.subType!==""){
            this.props.setDeviceType(this.props.subScriptionTypes[parseInt(this.state.subType)]);
            this.props.setDeviceVersion({version:this.state.deviceTVersion});
        }
    }

    componentDidMount(): void {

        this.props.getSubscriptiontypes((error)=>{

        });
    }

    next(){
        if(this.state.subType!==""){
            this.setTypeAndVersion();
            this.props.history?.push({pathname:"/admin/details/view/" + this.props.selectedDevice.id});
        }

    }

    render() {
        let subScriptionTypes=this.props.subScriptionTypes?.map((types,index)=>{

            return <option value={index}>{types.name}</option>
        });

        return (
            <React.Fragment>
               <div className="row">
                   <div className="col-md-12">
                       <div className="col-md-12">
                           <div className="form-group">
                               <label htmlFor="mech">Select SubScription Type</label>
                               <select className="form-control" id="mec" onChange={(e)=>this.setState({subType:e.target.value})}>
                                   <option></option>
                                   {subScriptionTypes}
                               </select>
                           </div>
                           <div className="form-group">
                               <label htmlFor="mech">Select Device Type</label>
                               <select className="form-control" id="mec" onChange={(e)=>this.setState({deviceTVersion:e.target.value})}>
                                   <option value="V1" selected={this.state.deviceTVersion==="V1"}>VI</option>
                                   <option value="V2" selected={this.state.deviceTVersion==="V2"}>V2</option>
                               </select>
                           </div>
                       </div>
                       <div className="col-md-12">
                           <button className="btn btn-primary btn-block" onClick={this.next}>Next</button>
                       </div>
                   </div>
               </div>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        subScriptionTypes:state.subScriptionTypes,
        selectedDevice:state.selectedDevice,

    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        { getSubscriptiontypes,setDeviceType,setDeviceVersion}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(SelectDeviceTypeComponent);
