import {HTTP} from "../../helpers/axios";
import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import { serverRoutes} from "../../api/serverRoutes";


export const getSubscriptionReportSalesMonthly = (data:object,callback:(error:boolean)=>void):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {


    try{
        const response =  await   HTTP(getState,dispatch).post(serverRoutes.getSubscriptionSalesMonthly,data);

        dispatch({
            type: 'SUBSCRIPTION_SALES_MONTHLY',
            payload: response.data
        });

        let error=false;
        callback(error);
    }catch (e) {
        let error=true;
        callback(error);
    }

};

