import React, {ChangeEvent} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import Notifiction from "../Notification/Notifiction";
import LoaderComponent from "../Loader/LoaderComponent";
import {getCustomersBySearch} from "../../actions/customer/getCustomersBySearchAction";
import {getCustomersAction} from "../../actions/customer/getCustomerAction";
import {customerDevices} from "../../actions/device/customerDevicesAction";
import {selectedCustomerAction} from "../../actions/customer/selectedCustomerAction";
import {CustomerDataInterface} from "../../dataInterface/CustomerDataInterface";
import editCustomerAction from "../../actions/customer/editCustomerAction";
import {Link,withRouter,RouteComponentProps} from "react-router-dom";
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobile } from '@fortawesome/free-solid-svg-icons'
import CreateCustomerComponent from "./CreateCustomerComponent";
import '../../App.scss'

interface internalState {
    loading:boolean,
    name:string,
    error:boolean,
    errorString:string,
    Success:boolean,
    successString:string,
    take:number,
    skip:number

}

interface parentProps {
}

interface externalStateProps {
    customers:[Array<CustomerDataInterface>,number],
    authUserPermissions:Array<string>

}


interface DispatchProps {
    getCustomersAction:(dd:object,callback:(error:boolean)=>void)=>void,
    getCustomersBySearch:(searchObject:object,callback:()=>void)=>void,
    editCustomerAction:(customer:object,callBack:(error:boolean)=>void)=>void,
    customerDevices:(devices:object)=>void,
    selectedCustomerAction:(customer:CustomerDataInterface)=>void
}

type Props = externalStateProps & DispatchProps & parentProps & RouteComponentProps;;

class CustomerComponent extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading:false,
            name:"",
            error: false,
            Success: false,
            errorString: "",
            successString: "",
            take:10,
            skip:0
        };

        this.search=this.search.bind(this);
        this.searchChange=this.searchChange.bind(this);
        this.paginate=this.paginate.bind(this);

    }

    componentDidMount(): void {
        this.loadCustomers();
    }


    loadCustomers(){
        this.setState({
            loading:true
        });
        this.props.getCustomersAction({take:this.state.take,skip:this.state.skip},(error)=>{


            if(!error){
                this.setState({
                    loading:false,
                    error:false,
                    errorString:"",
                });
            }else {
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Failed To Load Customers"
                });
            }

        })
    }

    customerDevices(customer:CustomerDataInterface){
        if(customer.devices!==null){
            this.props.selectedCustomerAction(customer);
            this.props.customerDevices(customer.devices);
            this. props.history.push("/admin/customer/devices/list");
        }

    }


    search(e:React.MouseEvent){
        e.preventDefault();
        this.setState({loading:true});
        this.props.getCustomersBySearch({name:this.state.name},()=>{
            this.setState({loading:false});
        });
    }

    searchChange(e:ChangeEvent<HTMLInputElement>){
        if(e.target.value!==""){
            this.setState({name:e.target.value})
        }else {
            this.setState({name:""},()=>{
                this.loadCustomers();
            });
        }
    }

    paginate(data:{selected:number}){
        this.setState({
            skip: this.state.take * data.selected
        },()=>{
            this.loadCustomers();
        })
    }

    formatPhoneNumber(phone:string) {

        if (phone.length !== 10) {
            return 'Invalid input';
        }

        return phone.substring(0, 4) + '--' + phone.substring(4, 7) + '--' + phone.substring(7, 10);
    }




    render() {

        let isCustomerEmmpty=()=>{

            if(this.props.customers){
                if(this.props.customers[0].length===0){
                    return (
                        <div className="col-auto text-center mb-3">
                            <CreateCustomerComponent/>
                        </div>
                    )

                }

            }else {
                return (
                    <div className="col-auto text-center mb-3">
                        <CreateCustomerComponent/>
                    </div>
                )
            }

        };

        let allcustomers=()=>{

            if(this.props.customers){
                return this.props.customers[0]?.map((customer,index)=>{
                    if(this.props.authUserPermissions?.includes("POST_get_customers")){
                        return(
                            <tr key={customer.id}>
                                <th scope="row">{index + 1}</th>
                                <td>{customer.devices!=null?customer.devices?.length:"0"}</td>
                                <td>{customer.fname}</td>
                                <td>{customer.lname}</td>
                                <td>{customer.phones!=null? this.formatPhoneNumber(customer.phones[0].phone_number):"nill"}</td>
                                <td>{moment(customer.createdAt).format('DD-MM-YYYY HH:mm')}</td>
                                <td>{customer.username}</td>
                                <td>{customer.password}</td>
                                {
                                    (()=>{
                                        if(this.props.authUserPermissions?.includes("-admin-customer-devices-list-")){
                                            return <td onClick={(e)=>this.customerDevices(customer)} className="pointer">
                                                <span>
                                                    <FontAwesomeIcon icon={faMobile} color="red" />
                                                </span>
                                            </td>
                                        }
                                    })()
                                }
                            </tr>

                        )

                    }else {
                        return <React.Fragment/>
                    }
                })

            }else {
                return <React.Fragment/>
            }

        };


        return (
            <React.Fragment>
                <Notifiction error={this.state.error} errorString={this.state.errorString} successString={this.state.successString} success={this.state.Success}/>
                <LoaderComponent loading={this.state.loading}/>
                <div className="col-auto">
                    {
                        (()=>{
                            if(this.props.authUserPermissions?.includes("POST_search")){
                                return(
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">Search Customer</div>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Name"  value={this.state.name} onChange={this.searchChange}/>
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" type="button" onClick={this.search}>Search</button>
                                        </div>
                                    </div>
                                )
                            }
                        })()
                    }
                </div>
                {isCustomerEmmpty()}
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">No.Devices</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Phone Number</th>
                        <th scope="col">Date Registered</th>
                        <th scope="col">Username</th>
                        <th scope="col">Password</th>
                    </tr>
                    </thead>
                    <tbody>
                    {allcustomers()}
                    </tbody>
                </table>
                <ReactPaginate
                    containerClassName="pagination"
                    breakClassName="page-item"
                    breakLabel={<a className="page-link">...</a>}
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    pageCount={this.props.customers?this.props.customers[1]/this.state.take:0}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    onPageChange={this.paginate}
                />
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        customers:state.allCustomers,
        authUserPermissions:state.authUserPermissions
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {getCustomersAction,getCustomersBySearch,editCustomerAction,customerDevices,selectedCustomerAction}, dispatch)

}



export default connect(mapStateToProps, mapDispatchToProps)(CustomerComponent);
