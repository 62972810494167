import {HTTP} from "../../helpers/axios";
// src/thunks.ts
import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import { serverRoutes} from "../../api/serverRoutes";


export const getDeviceSubscriptionLogs= (data:object,callback:(error:boolean)=>void):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {


    try {

        const response =  await   HTTP(getState,dispatch).post(serverRoutes.getDeviceSubscriptionLogs,data);
        console.log(444444444444444444444)
        console.log(response.data);
        dispatch({
            type: 'DEVICE_SUBSCRIPTION_LOGS',
            payload: response.data
        });

        let error=false;
        callback(error);
    }catch (e) {
        console.log(e);
        let error=true;
        callback(error);
    }


};
