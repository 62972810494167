// src/thunks.ts
import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'


export const customerDevices = (devices:object):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {

    dispatch({
        type: 'CUSTOMER_DEVICES',
        payload: devices
    });

};
