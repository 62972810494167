import {deviceData} from "../../dataInterface/deviceDetailInterface";
import {DeviceTypeDataInterface} from "../../dataInterface/deviceTypeDataInterface";


interface deviceVersion {
    type:string
    payload:{version:string}
}

export default (state = null, action:deviceVersion) => {
    switch (action.type) {
        case "SELECTED_DEVICE_VERSION":
            return action.payload;
        default:
            return state;
    }
};
