import React, {ButtonHTMLAttributes} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import {Modal,Button} from "react-bootstrap";
import LoaderComponent from "../Loader/LoaderComponent";
import Notifiction from "../Notification/Notifiction";

interface internalState {
}

interface parentProps {
    title:string,
    children:React.ReactNode,
    handleShow?:(e:React.MouseEvent<HTMLButtonElement>)=>void,
    show:boolean,
    handleClose:(e:React.MouseEvent<HTMLButtonElement>)=>void,
    save?:(e:React.MouseEvent<HTMLButtonElement>)=>void,
    loader:boolean,
    success:boolean,
    error:boolean,
    errorString:string,
    successString:string

}

interface externalStateProps {

}


interface DispatchProps {
}

type Props = externalStateProps & DispatchProps & parentProps;

class ModalComponent extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);

        this.state = {}
    }

    render() {

        let save=()=>{
            if(this.props.save){
                return(
                    <Button variant="default" onClick={this.props.save}>
                        Save Changes
                    </Button>
                )
            }
        }

        return (
            <React.Fragment>
                <Modal show={this.props.show} onHide={this.props.handleClose} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <Notifiction error={this.props.error} success={this.props.success} errorString={this.props.errorString} successString={this.props.successString}/>
                            <LoaderComponent loading={this.props.loader}/>
                            {this.props.children}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.props.handleClose}>
                            Close
                        </Button>
                        {save()}
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {}
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(ModalComponent);
