import {mechanicsDataReportInterface} from "../../dataInterface/mechanicsDataReportInterfce";


interface mechanicsDataReport {
    type:string
    payload:mechanicsDataReportInterface
}

export default (state = null, action:mechanicsDataReport) => {
    switch (action.type) {
        case "MECHANICS_REPORT":
            return action.payload;
        default:
            return state;
    }
};
