import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import './style.scss';
import {getAllMechanicsReport} from "../../actions/report/getMechanicsReportAction";
import {getAllMechanicsReportByDateRange} from "../../actions/report/getMechanicReportByDateRangeAction";

import {mechanicsDataReportInterface} from "../../dataInterface/mechanicsDataReportInterfce";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {mechanicDataReportByDateRangeInterface} from "../../dataInterface/mechanicDataReportByDateRangeInterface";
import Notifiction from "../Notification/Notifiction";
import LoaderComponent from "../Loader/LoaderComponent";

interface internalState {
    stastData:Array<{name:string,data:Array<number>}>
    startDate:string,
    endDate:string,
    loading:boolean,
    Success:boolean,
    error:boolean,
    errorString:string,
    successString:string,
    year:string,
}

interface parentProps {
}

interface externalStateProps {
    mechanicsReport:Array<mechanicsDataReportInterface>,
    mechanicReportDateRange:Array<mechanicDataReportByDateRangeInterface>
}


interface DispatchProps {
    getAllMechanicsReport:(data:object,callback:(error:boolean)=>void)=>void,
    getAllMechanicsReportByDateRange:(data:object,callback:(error:boolean)=>void)=>void,
}

type Props = externalStateProps & DispatchProps & parentProps;

class Mechanic extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            stastData:[],
            startDate:"",
            endDate:"",
            loading:false,
            Success:false,
            error:false,
            errorString:"",
            successString:"",
            year:''
        };

        this.getReportByDateRange=this.getReportByDateRange.bind(this);
        this.getMechanicReport=this.getMechanicReport.bind(this);

    }

     static getDerivedStateFromProps(props:Props,state:internalState){

         return {stastData:props.mechanicsReport}

    }

    componentDidMount(): void {
    }


    getMechanicReport(e:React.ChangeEvent<HTMLSelectElement>){

       this.setState({
           year:e.target.value
       },()=>{
           this.props.getAllMechanicsReport({year:this.state.year},(error)=>{

               if(error){

               }else {

               }
           });
       })
    }

    getReportByDateRange(){
        this.props.getAllMechanicsReportByDateRange({
            startDate:this.state.startDate,
            endDate:this.state.endDate
        },(error)=>{

            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Server Error Detected",
                    successString:"",
                    Success:false,
                });
            }else {
                this.setState({
                    startDate:"",
                    endDate:""
                })
            }
        });
    }

    render() {

        let report=()=>{

            if(this.props.mechanicReportDateRange){
                return this.props.mechanicReportDateRange.map((data,index)=>{
                    return (
                        <tr key={index}>
                            <th scope="row">{index +1}</th>
                            <td>{data.fname + " " + data.lname}</td>
                            <td>{data.sum}</td>
                        </tr>
                    )
                });

            }
        };


        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12 mb-5">
                        <div className="col-md-12">
                            <select className="custom-select custom-select-lg mb-3" onChange={this.getMechanicReport}>
                                <option selected>Select Year</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                chart: {
                                    type: 'column'
                                },
                                title: {
                                    text: 'Mechanics Device Statistics'
                                },
                                subtitle: {
                                    text: 'Mechanics'
                                },
                                xAxis: {
                                    categories: [
                                        'Jan',
                                        'Feb',
                                        'Mar',
                                        'Apr',
                                        'May',
                                        'Jun',
                                        'Jul',
                                        'Aug',
                                        'Sep',
                                        'Oct',
                                        'Nov',
                                        'Dec'
                                    ],
                                    crosshair: true
                                },
                                yAxis: {
                                    min: 0,
                                    title: {
                                        text: 'Devices'
                                    }
                                },
                                tooltip: {
                                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                                    footerFormat: '</table>',
                                    shared: true,
                                    useHTML: true
                                },
                                plotOptions: {
                                    column: {
                                        pointPadding: 0.2,
                                        borderWidth: 0
                                    }
                                },
                                series: this.state.stastData
                            }
                            }
                        />

                    </div>
                    <div className="col-md-12">
                        <LoaderComponent loading={this.state.loading}/>
                        <Notifiction error={this.state.error} success={this.state.Success} errorString={this.state.errorString} successString={this.state.successString}/>
                    </div>
                    <div className="col-md-12 mt-3 mb-3">
                        <div className="row">
                            <div className="col">
                                <input type="date" className="form-control" placeholder="First name" value={this.state.startDate} onChange={(e)=>this.setState({startDate:e.target.value})}/>
                            </div>
                            <div className="col">
                                <input type="date" className="form-control" placeholder="Last name" value={this.state.endDate} onChange={(e)=>this.setState({endDate:e.target.value})}/>
                            </div>
                            <div className="col-md-12 mt-3">
                                <button className="btn btn-default btn-block" onClick={this.getReportByDateRange}>Submit</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Number of Devices</th>
                            </tr>
                            </thead>
                            <tbody>
                            {report()}
                            </tbody>
                        </table>
                    </div>

                </div>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        mechanicsReport:state.mechanicsDataReport,
        mechanicReportDateRange:state.mechanicReportDateRange
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {getAllMechanicsReport,getAllMechanicsReportByDateRange}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(Mechanic);
