import {HTTP} from "../../helpers/axios";
// src/thunks.ts
import {AxiosResponse} from "axios";
import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import { serverRoutes} from "../../api/serverRoutes";


export const getCustomersBySearch = (searchObject:object,callback:()=>void):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {

    const response =  await   HTTP(getState,dispatch).post(serverRoutes.searchCustomer,searchObject);

    dispatch({
        type: 'GET_CUSTOMERS',
        payload: response.data
    });



    callback();

};

