import {reportRequestsDataInterface} from "../../dataInterface/ReportRequestsDataInterface";

interface deviceReportRequests {
    type:string
    payload:reportRequestsDataInterface
}

export default (state = null, action:deviceReportRequests) => {
    switch (action.type) {
        case "DEVICE_REPORT_REQUESTS":
            return action.payload;
        default:
            return state;
    }
};
