import {Action, applyMiddleware, compose, createStore,} from "redux";
import thunk from "redux-thunk";
// @ts-ignore
import logger from 'redux-logger';
import { persistStore, persistReducer ,Persistor} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
//import { composeWithDevTools } from 'redux-devtools-extension';
import reduxReset from 'redux-reset';

import rootReducer ,{RootState} from "../reducers";



const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

 const store2 = createStore(rootReducer, {}, applyMiddleware(thunk, logger));


    let store = createStore(persistedReducer,{},compose( applyMiddleware(thunk, logger),reduxReset())) as any



    let persistor = persistStore(store);

    export  { store, persistor,store2 }

