import {UsersSettingsDataInterface} from "../../dataInterface/userSettingsDataInterface";


interface type {
    type:string
    payload:UsersSettingsDataInterface
}

export default (state = null, action:type) => {
    switch (action.type) {
        case "GET_TYPE_USER_SETTINGS":
            return action.payload;
        default:
            return state;
    }
};
