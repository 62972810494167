import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import logoutAction from "../../actions/logout/logoutAction";


interface internalState {

}

interface parentProps {
}

interface externalStateProps {
}


interface DispatchProps {
    logoutAction:(callBack:(execution:Promise<any>)=>void)=>void

}

type Props = externalStateProps & DispatchProps & parentProps;

class LteHeader extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {};
        this.logout=this.logout.bind(this);
    }

    componentDidMount(): void {
    }

    logout(e:React.MouseEvent<HTMLAnchorElement>){
        e.preventDefault();
        this.props.logoutAction((execution => {
            execution.then(()=>{
                window.location.href="/login";
            })
        }));

    }


    render() {
        return (
            <React.Fragment>
                <div>
                    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                        {/*Left navbar links*/}
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link" data-widget="pushmenu" href="#" role="button">
                                    <i className="fas fa-bars"/>
                                </a>
                            </li>
                            <li className="nav-item d-none d-sm-inline-block">
                                <a href="index3.html" className="nav-link">Home</a>
                            </li>
                        </ul>

                        {/*Right navbar links*/}
                        <ul className="navbar-nav ml-auto">
                             {/*Navbar Search*/}
                            <li className="nav-item">
                                <a className="nav-link" data-widget="navbar-search" href="#" role="button">
                                    <i className="fas fa-search"/>
                                </a>
                            </li>

                            {/*Messages Dropdown Menu */}
                            {/*Notifications Dropdown Menu */}
                            <li className="nav-item dropdown">
                                <a className="nav-link" data-toggle="dropdown" href="#">
                                    <i className="fas fa-cogs"/>
                                    {/*<span className="badge badge-warning navbar-badge">15</span>*/}
                                </a>
                                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                    <span className="dropdown-header">Settings</span>
                                    <div className="dropdown-divider"/>
                                    <a href="#" className="dropdown-item" onClick={this.logout}>
                                        <i className="fas fa-user mr-2"/> Logout
                                        <span className="float-right text-muted text-sm">Sign Out</span>
                                    </a>
                                </div>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                                    <i className="fas fa-expand-arrows-alt"/>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#"
                                   role="button">
                                    <i className="fas fa-th-large"/>
                                </a>
                            </li>
                        </ul>
                    </nav>

                </div>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {}
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {logoutAction}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(LteHeader);
