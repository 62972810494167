import React, {ButtonHTMLAttributes} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";


interface internalState {
}

interface parentProps {
    success:boolean,
    successString:string,
    error:boolean,
    errorString:string
}

interface externalStateProps {
}


interface DispatchProps {
}

type Props = externalStateProps & DispatchProps & parentProps;

class Notifiction extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);

        this.state = {}
    }

    render() {

        let notification=()=>{
            if(this.props.error){
                return(
                    <div className="alert alert-danger" role="alert">
                        {this.props.errorString}
                    </div>
                )
            }else if (this.props.success){
               return (
                   <div className="alert alert-success" role="alert">
                       {this.props.successString}
                   </div>
               )
            }
        }


        return (
            <React.Fragment>
                {notification()}
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {}
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(Notifiction);