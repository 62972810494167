import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import Notifiction from "../Notification/Notifiction";
import {searchSimsAction} from  "../../actions/simcard/searchSimsAction";
import {getSimsAction} from  "../../actions/simcard/getAllSimCardsAction";
import LoaderComponent from "../Loader/LoaderComponent";
import ReactPaginate from "react-paginate";
import {SimCardInterface} from "../../dataInterface/simCardInterface";
import moment from "moment";


interface internalState {
    loading:boolean,
    device:string,
    searchError:boolean,
    error:boolean,
    success:boolean,
    errorString:string,
    successString:string,
    Success:boolean,
    take:number,
    skip:number,
    search:string,
}

interface parentProps {
}

interface externalStateProps {
    simcards:[Array<SimCardInterface>,number]
}


interface DispatchProps {
    searchSimsAction:(data:object,callback:(error:boolean)=>void)=>void,
    getSimsAction:(data:object,callback:(error:boolean)=>void)=>void,
}

type Props = externalStateProps & DispatchProps & parentProps;

class ViewSimCards extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading:false,
            device:"",
            searchError:false,
            error:false,
            success:false,
            errorString:"",
            successString:"",
            Success:false,
            search:"",
            take:6,
            skip:0,
        };

        this.search=this.search.bind(this);
        this.loadSimcards=this.loadSimcards.bind(this);
        this.onchangeSearch=this.onchangeSearch.bind(this);
        this.paginate=this.paginate.bind(this);
        this.nextPage=this.nextPage.bind(this);
        this.previousPage=this.previousPage.bind(this);

    }

    componentDidMount(): void {
        this.loadSimcards();
        console.log(4444444444444444444444444444444444444444)
    }

    search(e:React.MouseEvent<HTMLButtonElement>){

        this.setState({
            loading:true
        });

        if(this.state.search!==""){
            this.props.searchSimsAction({
                take:this.state.take,
                skip:this.state.skip,
                search:this.state.search.trim()
            },(error)=>{
                if(!error){

                    this.setState({
                        loading:false
                    });

                }else {
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Error form Server",
                        Success:true,
                        loading:false
                    });
                }
            })

        }else {
            this.setState({
                error:true,
                errorString:"Please Input SimCard Number To search",
                successString:"",
                Success:false,
                loading:false
            });
        }

    }

    loadSimcards(){

        this.setState({
            loading:true
        });

        this.props.getSimsAction({
            take:this.state.take,
            skip:this.state.skip,
        },(error)=>{
            if(!error){
                this.setState({
                    error:false,
                    errorString:"",
                    successString:"Device Sim Card Updated",
                    Success:true,
                    loading:false
                });
            }else {
                this.setState({
                    error:true,
                    errorString:"Failed To Update Device Sim Card Maybe Already in Use",
                    successString:"",
                    Success:false,
                    loading:false
                });
            }
        })
    }

    onchangeSearch(e:React.ChangeEvent<HTMLInputElement>){

        this.setState({
            search:e.target.value
        },()=>{
            if(this.state.search ===""){
                this.loadSimcards();
            }
        });


    }

    paginate(data:{selected:number}){
        this.setState({
            skip: this.state.take * data.selected
        },()=>{
            this.loadSimcards();
        })
    }
    nextPage(){
        this.setState({
            skip:this.state.skip+10,
        },()=>{
            this.loadSimcards();

        });
    }

    previousPage(){
        this.setState({
            skip:this.state.skip-10,
        },()=>{
            this.loadSimcards();
        });
    }

    render() {

        let simCards=()=>{
            if(this.props.simcards){
                return   this.props.simcards[0].map((sim,index)=>{

                    return(
                        <React.Fragment>
                            <tr key={sim.id}>
                                <th scope="row">{index+1}</th>
                                <td>{sim.simNumber}</td>
                                <td>{sim.batch}</td>
                                <td>{sim.isDefective? 'Defective':'Not Defective'}</td>
                                <td>{sim.isCancelled? 'Cancelled':'Not Cancelled'}</td>
                                <td>{moment(sim.dateRegistered).format("YYYY:MM:DD HH:ss")}</td>
                                <td>{moment(sim.dateTopUp).format("YYYY:MM:DD HH:ss")}</td>
                                <td>{moment(sim.dateEnd).format("YYYY:MM:DD HH:ss")}</td>
                                <td>{moment(sim.dateConnected).format("YYYY:MM:DD HH:ss")}</td>
                                <td>{moment(sim.expiry).format("YYYY:MM:DD HH:ss")}</td>
                            </tr>
                        </React.Fragment>
                    )
                })

            }else {
                return <React.Fragment/>
            }
        };

        return (
            <React.Fragment>
                <div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <LoaderComponent loading={this.state.loading}/>
                            <Notifiction success={this.state.success} error={this.state.error} successString={this.state.successString} errorString={this.state.errorString}/>
                            <div className="col-auto">
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Search Sim card</div>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Name"  value={this.state.search}  onChange={this.onchangeSearch}/>
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" type="button" onClick={this.search}>Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Number</th>
                            <th scope="col">Batch</th>
                            <th scope="col">Defective</th>
                            <th scope="col">Cancelled</th>
                            <th scope="col">Date Registered</th>
                            <th scope="col">Date Top Up</th>
                            <th scope="col">Bundle End Date</th>
                            <th scope="col">Customer Connected Date</th>
                            <th scope="col">Expiry</th>
                        </tr>
                        </thead>
                        <tbody>
                        {simCards()}
                        </tbody>
                    </table>

                    <ReactPaginate
                        containerClassName="pagination"
                        breakClassName="page-item"
                        breakLabel={<a className="page-link">...</a>}
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                        pageCount={this.props.simcards?this.props.simcards[1]/this.state.take:0}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        onPageChange={this.paginate}
                    />

                </div>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        simcards:state.simcards
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {searchSimsAction,getSimsAction}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(ViewSimCards);
