import {deviceSalesReportMonthlyInterface} from "../../dataInterface/deviceSalesReportMonthlyInterface";


interface deviceSalesReport {
    type:string
    payload:deviceSalesReportMonthlyInterface
}

export default (state = null, action:deviceSalesReport) => {
    switch (action.type) {
        case "DEVICE_SALES_MONTHLY":
            return action.payload;
        default:
            return state;
    }
};
