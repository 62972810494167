import {CustomerDataInterface} from "../../dataInterface/CustomerDataInterface";


interface fetchUserReducer {
    type:string
    payload:CustomerDataInterface
}

export default (state = null, action:fetchUserReducer) => {
    switch (action.type) {
        case "SEARCH_CUSTOMER":
            return action.payload;
        default:
            return state;
    }
};