
import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import {persistor} from "../../store";


const logoutAction = (callBack:(execution:Promise<any>)=>void):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {

    dispatch({
        type: 'RESET',
    });

    let execution=new Promise<void>(async (resolutionFunc,rejectionFunc)=>{
        persistor.pause();
        await persistor.purge();
        resolutionFunc();
    });
    callBack(execution);
};
export default logoutAction;


