import {CustomerDataInterface} from "../../dataInterface/CustomerDataInterface";


interface customerReducer {
    type:string
    payload:Array<CustomerDataInterface>
}

export default (state = null, action:customerReducer) => {
    switch (action.type) {
        case "SELECTED_CUSTOMER":
            return action.payload;
        default:
            return state;
    }
};
