import {HTTP} from "../../helpers/axios";
// src/thunks.ts
import {AxiosResponse} from "axios";
import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import { serverRoutes} from "../../api/serverRoutes";
import {responseData} from "../../dataInterface/deviceDetailInterface";


export const postDeviceVersionAction = (data:object,callback:(error:boolean)=>void):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {

    try {
        const response:responseData =  await   HTTP(getState,dispatch).post(serverRoutes.postDeviceVersion,data);

        dispatch({
            type: 'SELECTED_DEVICE',
            payload: response.data
        });
        callback(false);
    }catch (e){
        callback(true);
    }



};

export default postDeviceVersionAction;
