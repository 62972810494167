import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import {searchDevicesFromAll} from "../../actions/device/searchFromAllAction";
import Notifiction from "../Notification/Notifiction";
import LoaderComponent from "../Loader/LoaderComponent";
import {getDeviceAction} from "../../actions/device/getDevicesAction";


interface internalState {
    loading:boolean,
    device:string,
    searchError:boolean,
    error:boolean,
    success:boolean,
    errorString:string,
    successString:string,
    take:number,
    skip:number,
}

interface parentProps {
}

interface externalStateProps {
}


interface DispatchProps {
    searchDevicesFromAll:(device:string,callback:()=>void)=>void,
    getDeviceAction:(data:object,callback:()=>void)=>void,

}

type Props = externalStateProps & DispatchProps & parentProps;

class SearchForAllComonent extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading:false,
            device:"",
            searchError:false,
            error:false,
            success:false,
            errorString:"",
            successString:"",
            take:6,
            skip:0,
        };

        this.search=this.search.bind(this);
        this.loadDevices=this.loadDevices.bind(this);
        this.onchangeSearch=this.onchangeSearch.bind(this);

    }

    componentDidMount(): void {
    }

    search(e:React.MouseEvent|null=null){
        e?.preventDefault();

        let device=this.state.device.trim();
        if(device!=="" && device.length >=4){

            this.setState({
                loading:true,
                searchError:false,
                success:false,
                error:false,
                errorString:"",
                successString:""
            });
            this.props.searchDevicesFromAll(this.state.device.trim(),()=>{
                this.setState({
                    loading:false,
                });

            })
        }else {
            this.setState({
                error:true,
                errorString:"Please Input Device Unique ID and Must be four characters long",
                success:false,
                successString:""
            })
        }

    }

    loadDevices(){
        this.props.getDeviceAction({take:this.state.take,skip:this.state.skip},()=>{
            this.setState({
                loading:false,
            });
        });
    }

    onchangeSearch(e:React.ChangeEvent<HTMLInputElement>){
        this.setState({device:e.target.value},()=>{
            if(this.state.device==""){
                this.loadDevices();
            }
        });

    }


    render() {
        let showSearchError=()=>{
            if(this.state.searchError){
                return(
                    <div className="text-danger text-center">
                        <span>Please Input Device Unique ID and Must be four characters long</span>
                    </div>
                )
            }

        };



        return (
            <React.Fragment>
                <div className="row mb-4">
                    <div className="col-12">
                        {showSearchError()}
                        <Notifiction success={this.state.success} error={this.state.error} successString={this.state.successString} errorString={this.state.errorString}/>
                        <div className="col-auto">
                            <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Search Devices</div>
                                </div>
                                <input type="text" className="form-control" placeholder="Name"  value={this.state.device}  onChange={this.onchangeSearch}/>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" onClick={this.search}>Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LoaderComponent loading={this.state.loading}/>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {}
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {searchDevicesFromAll,getDeviceAction}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(SearchForAllComonent);
