import {HTTP} from "../../helpers/axios";
// src/thunks.ts
import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import { serverRoutes} from "../../api/serverRoutes";
import {responseData} from "../../dataInterface/deviceDetailInterface";


export const searchDevicesFromAll = (device:string,callback:()=>void):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {


    const response:responseData =  await   HTTP(getState,dispatch).post(serverRoutes.searchDevice,{
        unique_id:device
    });



    dispatch({
        type: 'ALL_DEVICES',
        payload: response.data
    });

    callback();

};
