import {HTTP} from "../../helpers/axios";
// src/thunks.ts
import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import {serverRoutes,} from "../../api/serverRoutes";


export const assignDeviceToCustomer = (data:object,callback:(error:boolean)=>void):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {

    try {
        const response =  await   HTTP(getState,dispatch).post(serverRoutes.assignDeviceToCustomer,data);
        let error=false;
        callback(error);

        dispatch({
            type: 'SEARCH_DEVICES',
            payload: null
        });

        dispatch({
            type: 'SEARCH_CUSTOMER',
            payload:null
        });

        dispatch({
            type: 'SELECTED_DEVICE',
            payload: response.data
        });

        dispatch({
            type: 'SELECTED_CUSTOMER',
            payload: response.data.details.customer
        });
    }catch (e) {
        let error=true;
        callback(error);

    }


};

export default assignDeviceToCustomer;
