import React, {ChangeEvent} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import "./scss/devicedetails.scss";
import LoaderComponent from "../Loader/LoaderComponent";
import Notifiction from "../Notification/Notifiction";
import {getDeviceAdminPhoneAction} from "../../actions/device/getDevicePhoneAction"
import {deviceData} from "../../dataInterface/deviceDetailInterface";
import {adminPhoneDataInterface} from "../../dataInterface/adminPhoneDataInterface";
import {registerDeviceToTraccar} from "../../actions/device/registerDeviceToTraccarAction";
import {UnregisterDeviceToTraccar} from "../../actions/device/unRegisterDeviceInTraccarAction";
import {enableDevice} from "../../actions/device/enableDeviceAction";
import {disableDevice} from "../../actions/device/desableDeviceAction";


import {sendSmsCommandAction} from "../../actions/device/sendSmsCommandToDeviceAction";
import {setAccCommandAction} from "../../actions/commands/setAccAction";
import {setAdminCommandAction} from "../../actions/commands/setAdminAction";
import {setApnCommandAction} from "../../actions/commands/setApnAction";
import {setDomainCommandAction} from "../../actions/commands/setDomainAction";
import {setPasswordCommandAction} from "../../actions/commands/setPasswordCommandAction";
import {setResponseTimeCommandAction} from "../../actions/commands/setResponseTimeAction";
import {setTimezoneCommandAction} from "../../actions/commands/setTimeZoneCommandAction";
import {setSmsCustomerCommandAction} from "../../actions/commands/smsToCustomerAction";
import {checkCommandAction} from "../../actions/commands/checkCommandAction";
import {rebootCommandAction} from "../../actions/commands/rebootCommandAction";
import {removeAdminAction} from "../../actions/commands/removeAdminAction";
import {deviceSmsResetAction} from "../../actions/commands/resetCommandAction";
import {deviceSmsStopAction} from "../../actions/commands/stopCommandAction";
import {deviceSmsResumeAction} from "../../actions/commands/resumeCommandAction";
import {customAdminAction} from "../../actions/commands/customAdminCommandAction";
import {CustomerDataInterface} from "../../dataInterface/CustomerDataInterface";
import {deviceInterface} from "../../dataInterface/deviceDataInterface";
import ModalComponent from "../Modal/ModalComponent";
import {searchDevicesWithoutDetails} from "../../actions/device/searchDeviceWithoutDetailsAction";
import InserPhoneToDevice from "./InserPhoneToDevice";
import {swapDeviceAction} from "../../actions/device/swapDeviceAction";
import {updateDevicePhoneAction} from "../../actions/device/updateDevicePhoneAction";
import {updatePlateNumberAction} from "../../actions/device/updatePlateNumberAction";
import {updateSubscriptionAction} from "../../actions/subscription/updateSubScriptionAction";
import {getSubscriptionLogs} from "../../actions/subscription/getSubScriptionLogsAction";
import {sendInstructionSmsAction} from "../../actions/customer/sendSmsInstructionsAction";
import {searchUsers} from "../../actions/users/searchUserAction";
import {sendInstuctionSmsToUser} from "../../actions/commands/sendInstuctionSmsToUserAction";
import {activateBundle} from "../../actions/bundle/activateBundleToDeviceAction";
import {getActiveBundlesAction} from "../../actions/bundle/getActiveBundlesAction";
import {getDeviceRemainingReportRequests} from "../../actions/device/getDeviceReportRequestsAction"
import moment from "moment";
import SearchCustomerCreateAssignDevice from "../Customer/SearchCustomerCreateAssignDevice";
import {SubscriptionDataInterface} from "../../dataInterface/subscriptionDataInterface";
import ReactPaginate from "react-paginate";
import {UsersDataInterface} from "../../dataInterface/UsersDataInterface";
import {bundleDataInterface} from "../../dataInterface/bundleDataInterface";
import {reportRequestsDataInterface} from "../../dataInterface/ReportRequestsDataInterface";
import {getAllMechanics} from "../../actions/mechanic/getAllMechanicAction";
import {setSmsMechanicCommandAction} from "../../actions/commands/smsToMechanicAction";
import {setSmsDealerCommandAction} from "../../actions/commands/smsToDealerAction";
import {getDeviceSubscriptionLogs} from "../../actions/subscription/getDeviceSubscriptionLogsAction";
import {setOutOfService} from "../../actions/device/setOutOfServiceAction";
import {createDebtAction} from "../../actions/debt/createDebtAction";
import {postDeviceVersionAction} from "../../actions/device/postDeviceVersionAction";
import {dealerDataInterface} from "../../dataInterface/dealerDataInterface";
import {getDebtItemsByDetailId} from "../../actions/debt/getDebtItemByDetailIdAction";
import {DebtItemsDatainterface} from "../../dataInterface/debtItemsDatainterface";
import {DeviceTypeDataInterface} from "../../dataInterface/deviceTypeDataInterface";
import {setCategoryAction} from "../../actions/device/setCategoryAction";

interface internalState {
    loading:boolean,
    newControlNumber:string,
    error:boolean,
    errorString:string,
    Success:boolean,
    successString:string,
    months:string,
    showModal:boolean,
    showModal2:boolean,
    showModal3:boolean,
    showModal4:boolean,
    showModal5:boolean,
    showModal6:boolean,
    showModal7:boolean,
    showModal8:boolean,
    showModal9:boolean,
    showModal10:boolean,
    showModal11:boolean,
    modalLoder:boolean,
    isDevicePhoneSelected:boolean,
    selectedAdminNumber:string,
    password:string,
    uniqueIdSelected:boolean,
    newUniqueId:string,
    phone:string,
    plateNumber:string,
    paidAmount:string,
    take:number,
    skip:number,
    userToSearch:string,
    bundleId:string,
    selectDisabled:boolean,
    price:string,
    logsTake:number,
    logsSkip:number,
    whoPay:string,
    selectedVersion:string
}

interface parentProps {
}

interface externalStateProps {
    selectedDevice:deviceInterface,
    devicePhones:{phones:Array<adminPhoneDataInterface>},
    selectedCustomer:CustomerDataInterface,
    searchDeviceWithoutDetails:Array<deviceInterface>,
    subLogs:[Array<SubscriptionDataInterface>,number]
    searchedUsers:Array<UsersDataInterface>,
    activeBundles:Array<bundleDataInterface>,
    deviceRemainingRequestsReport:reportRequestsDataInterface,
    subDeviceLogs:[Array<SubscriptionDataInterface>,number],
    authUserPermissions:Array<string>
    debtItemData:DebtItemsDatainterface,
    deviceType:DeviceTypeDataInterface
}



interface DispatchProps {
    getDeviceAdminPhoneAction:(deviceId:number,callback:(error:boolean)=>void)=>void,
    registerDeviceToTraccar:(data:object,callback:(error:boolean)=>void)=>void,
    UnregisterDeviceToTraccar:(data:object,callback:(error:boolean)=>void)=>void,
    sendSmsCommandAction:(data:object,callback:()=>void)=>void,
    setAccCommandAction:(data:object,callback:(error:boolean)=>void)=>void,
    setAdminCommandAction:(data:object,callback:(error:boolean)=>void)=>void,
    setDomainCommandAction:(data:object,callback:(error:boolean)=>void)=>void,
    setPasswordCommandAction:(data:object,callback:(error:boolean)=>void)=>void,
    setResponseTimeCommandAction:(data:object,callback:(error:boolean)=>void)=>void,
    setTimezoneCommandAction:(data:object,callback:(error:boolean)=>void)=>void,
    setSmsCustomerCommandAction:(data:object,callback:(error:boolean)=>void)=>void,
    setApnCommandAction:(data:object,callback:(error:boolean)=>void)=>void,
    checkCommandAction:(data:object,callback:(error:boolean)=>void)=>void,
    rebootCommandAction:(data:object,callback:(error:boolean)=>void)=>void,
    removeAdminAction:(data:object,callback:(error:boolean)=>void)=>void,
    deviceSmsResetAction:(data:object,callback:(error:boolean)=>void)=>void,
    deviceSmsStopAction:(data:object,callback:(error:boolean)=>void)=>void,
    deviceSmsResumeAction:(data:object,callback:(error:boolean)=>void)=>void,
    customAdminAction:(data:object,callback:(error:boolean)=>void)=>void,
    enableDevice:(data:object,callback:(error:boolean)=>void)=>void,
    disableDevice:(data:object,callback:(error:boolean)=>void)=>void,
    searchDevicesWithoutDetails:(device:string,callback:(error:boolean)=>void)=>void,
    swapDeviceAction:(data:object,callback:(error:boolean)=>void)=>void,
    setOutOfService:(data:object,callback:(error:boolean)=>void)=>void,
    updateDevicePhoneAction:(data:object,callback:(error:boolean)=>void)=>void,
    updatePlateNumberAction:(data:object,callback:(error:boolean)=>void)=>void,
    updateSubscriptionAction:(data:object,callback:(error:boolean)=>void)=>void,
    getSubscriptionLogs:(data:object,callback:(error:boolean)=>void)=>void,
    sendInstructionSmsAction:(data:object,callback:(error:boolean)=>void)=>void,
    searchUsers: (data: object, callback: (error: boolean) => void) => void,
    sendInstuctionSmsToUser:(data:object,callback:(error:boolean)=>void)=>void,
    activateBundle:(data:object,callback:(error:boolean)=>void)=>void,
    getActiveBundlesAction:(callback:(error:boolean)=>void)=>void,
    getDeviceRemainingReportRequests:(deviceId:number,callback:(error:boolean)=>void)=>void,
    getAllMechanics:(callback:(error:boolean)=>void)=>void,
    setSmsMechanicCommandAction:(data:object,callback:(error:boolean)=>void)=>void,
    setSmsDealerCommandAction:(data:object,callback:(error:boolean)=>void)=>void,
    getDeviceSubscriptionLogs:(data:object,callback:(error:boolean)=>void)=>void,
    createDebtAction:(data:object,callback:(error:boolean)=>void)=>void,
    getDebtItemsByDetailId:(data:object,callback:(error:boolean)=>void)=>void,
    postDeviceVersionAction:(data:object,callback:(error:boolean)=>void)=>void,
    setCategoryAction:(data:object,callback:(error:boolean)=>void)=>void,
}

type Props = externalStateProps & DispatchProps & parentProps;

class DeviceDetailsViewComponent extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading:true,
            newControlNumber:"",
            userToSearch:"",
            error:false,
            Success:false,
            errorString:"",
            successString:"",
            months:"",
            modalLoder:false,
            isDevicePhoneSelected:true,
            selectedAdminNumber:"",
            password:"123456",
            showModal:false,
            showModal2:false,
            showModal3:false,
            showModal4:false,
            showModal5:false,
            showModal6:false,
            showModal7:false,
            showModal8:false,
            showModal9:false,
            showModal10:false,
            showModal11:false,
            uniqueIdSelected:false,
            newUniqueId:"",
            phone:"",
            plateNumber:"",
            paidAmount:"",
            take:7,
            skip:0,
            bundleId:"",
            selectDisabled:false,
            price:"",
            logsTake:4,
            logsSkip:0,
            whoPay:"",
            selectedVersion:''
        };

        this.setPassword=this.setPassword.bind(this);
        this.setTimeZone=this.setTimeZone.bind(this);
        this.setResponseTime=this.setResponseTime.bind(this);
        this.setDomain=this.setDomain.bind(this);
        this.setApn=this.setApn.bind(this);
        this.setAcc=this.setAcc.bind(this);
        this.setSmsToCustomer=this.setSmsToCustomer.bind(this);
        this.setAdmin=this.setAdmin.bind(this);
        this.registerTracker=this.registerTracker.bind(this);
        this.UnregisterTracker=this.UnregisterTracker.bind(this);
        this.stop=this.stop.bind(this);
        this.resume=this.resume.bind(this);
        this.reset=this.reset.bind(this);
        this.addCustomNumber=this.addCustomNumber.bind(this);
        this.enableDevice=this.enableDevice.bind(this);
        this.disableDevice=this.disableDevice.bind(this);
        this.handleShow=this.handleShow.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.swapDevice=this.swapDevice.bind(this);
        this.enableDevice=this.enableDevice.bind(this);
        this.handleShow2=this.handleShow2.bind(this);
        this.handleShow3=this.handleShow3.bind(this);
        this.handleShow4=this.handleShow4.bind(this);
        this.handleShow5=this.handleShow5.bind(this);
        this.handleShow6=this.handleShow6.bind(this);
        this.handleShow7=this.handleShow7.bind(this);
        this.handleShow8=this.handleShow8.bind(this);
        this.handleShow9=this.handleShow9.bind(this);
        this.handleShow10=this.handleShow10.bind(this);
        this.handleShow11=this.handleShow11.bind(this);
        this.handleClose2=this.handleClose2.bind(this);
        this.handleClose3=this.handleClose3.bind(this);
        this.handleClose4=this.handleClose4.bind(this);
        this.handleClose5=this.handleClose5.bind(this);
        this.handleClose6=this.handleClose6.bind(this);
        this.handleClose7=this.handleClose7.bind(this);
        this.handleClose8=this.handleClose8.bind(this);
        this.handleClose9=this.handleClose9.bind(this);
        this.handleClose10=this.handleClose10.bind(this);
        this.handleClose11=this.handleClose11.bind(this);
        this.updateDevicePhone=this.updateDevicePhone.bind(this);
        this.updatePlateNumber=this.updatePlateNumber.bind(this);
        this.updateSubScription=this.updateSubScription.bind(this);
        this.searchUsers=this.searchUsers.bind(this);
        this.sendInstuctionsMessage=this.sendInstuctionsMessage.bind(this);
        this.setReportBundle=this.setReportBundle.bind(this);
        this.getDeviceRemainingRequestsReports=this.getDeviceRemainingRequestsReports.bind(this);
        this.sendInstuctionsMessageMechanic=this.sendInstuctionsMessageMechanic.bind(this);
        this.sendInstuctionsMessageDealer=this.sendInstuctionsMessageDealer.bind(this);
        this.getDeviceSubscriptionLogs=this.getDeviceSubscriptionLogs.bind(this);
        this.logPaginate=this.logPaginate.bind(this);
        this.setOutOfService=this.setOutOfService.bind(this);
        this.setDeviceVersion=this.setDeviceVersion.bind(this);
        this.selectedVersion=this.selectedVersion.bind(this);
        this.setCategory=this.setCategory.bind(this);

    }

    componentDidMount(): void {
        this.getAdminPhones();

        this.getDebtItemByDetailId();

    }


    getDebtItemByDetailId(){

        this.props.getDebtItemsByDetailId({detailId:this.props.selectedDevice.details.id},(error)=>{})
    }


    setCategory(e: ChangeEvent<HTMLSelectElement>){
        e.preventDefault();
        if(e.target.value !=""){
            this.props.setCategoryAction({detailId:this.props.selectedDevice.details.id,category:e.target.value},(error)=>{
                if(!error){
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Icon Set Successfully",
                        Success:true,
                        loading:false,
                        phone:""
                    });
                }else {
                    this.setState({
                        error:true,
                        errorString:"Failed To Set Icon",
                        successString:"",
                        Success:false,
                        loading:false
                    });
                }
            });
        }
    }

    selectedVersion(version:string){
        this.setState({
            selectedVersion:version
        },()=>this.setDeviceVersion());
    }

    setDeviceVersion(){

        if(this.state.selectedVersion!=""){
            this.setState({
                loading:true
            });

            this.props.postDeviceVersionAction({version:this.state.selectedVersion},(error)=>{
                if(!error){
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Version Changed",
                        Success:true,
                        loading:false,
                        phone:""
                    });
                }else {
                    this.setState({
                        error:true,
                        errorString:"Failed To Change Version",
                        successString:"",
                        Success:false,
                        loading:false
                    });
                }
            });
        }else {
            this.setState({
                error:true,
                errorString:"Select Version",
                successString:"",
                Success:false,
                loading:false
            });
        }

    }


    createDebt(e:React.MouseEvent<HTMLButtonElement>){

        e.preventDefault();

        if(this.state.whoPay!==""){
            this.props.createDebtAction({
                deviceId:this.props.selectedDevice?.details?.id,
                whoPay:this.state.whoPay
            },(error)=>{

                if(!error){
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Debt Assigned",
                        Success:true,
                        loading:false,
                        phone:""
                    },()=>{
                        this.getDebtItemByDetailId();
                    });
                }else {
                    this.setState({
                        error:true,
                        errorString:"Failed To Assign Debt",
                        successString:"",
                        Success:false,
                        loading:false
                    });
                }
            })

        }else{
            this.setState({
                error:true,
                errorString:"Please Select Who is paying",
                successString:"",
                Success:false,
                loading:false
            });
        }

    }

    setOutOfService(){
        this.setState({
            loading:true
        });

        this.props.setOutOfService({
            deviceId:this.props.selectedDevice?.details?.device.id
        },(error)=>{

            if(!error){
                this.setState({
                    error:false,
                    errorString:"",
                    successString:"Device Set Out of Service",
                    Success:true,
                    loading:false,
                    phone:""
                });
            }else {
                this.setState({
                    error:true,
                    errorString:"Failed To set out of Service",
                    successString:"",
                    Success:false,
                    loading:false
                });
            }
        })
    }

    getDeviceSubscriptionLogs(){

        this.setState({
            modalLoder:true
        });
        this.props.getDeviceSubscriptionLogs({deviceId:this.props.selectedDevice.id,take:this.state.logsTake,skip:this.state.logsSkip},(error)=>{
            if(error){
                this.setState({
                    modalLoder:false,
                    error:true,
                    errorString:"Error getting Subscription Logs",
                    successString:"",
                    Success:false,
                });
            }else {
                this.setState({
                    modalLoder:false,
                });
            }
        });

    }


    getMechanics(){
        this.setState({
            loading:true
        });
        this.props.getAllMechanics((error)=>{
            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Error getting Mechanics",
                    successString:"",
                    Success:false,
                });
            }else {
                this.setState({
                    loading:false,
                });
            }
        });
    }

    getActiveBundles(){
        this.props.getActiveBundlesAction((error)=>{
            if(error){
                this.setState({
                    modalLoder:false,
                    error:true,
                    errorString:"Server Error Detected",
                    Success:false,
                    successString:""
                });
            }else {
                this.setState({
                    modalLoder:false,
                    selectDisabled:false
                });
            }

        });
    }

    searchUsers(){

        this.setState({
            modalLoder:false,
            error:false,
            errorString:"",
            Success:false,
            successString:""
        });


        if(this.state.userToSearch){
            this.props.searchUsers({name:this.state.userToSearch},(error)=>{

                if(error){
                    this.setState({
                        modalLoder:false,
                        error:true,
                        errorString:"Server Error Detected",
                        Success:false,
                        successString:""
                    });
                }else {
                    this.setState({
                        modalLoder:false,
                        error:false,
                        errorString:"",
                        Success:false,
                        successString:"",
                        userToSearch:""
                    });
                }
            });
        }else {
            this.setState({
                modalLoder:false,
                error:true,
                errorString:"Please Type Users Name To search",
                Success:false,
                successString:""
            })
        }

    }

    sendInstuctionsMessage(e:React.MouseEvent<HTMLButtonElement>,user:UsersDataInterface){
        e.preventDefault();

        this.props.sendInstuctionSmsToUser({deviceId:this.props.selectedDevice.id,userId:user.id},(error)=>{
            if(error){
                this.setState({
                    modalLoder:false,
                    error:true,
                    errorString:"Server Error Detected",
                    Success:false,
                    successString:""
                });
            }else {
                this.setState({
                    modalLoder:false,
                    error:false,
                    errorString:"",
                    Success:true,
                    successString:"Text Message Sent",
                    userToSearch:""
                });
            }
        })

    }

    sendInstuctionsMessageDealer(e:React.MouseEvent<HTMLButtonElement>){
        e.preventDefault();

        this.props.setSmsDealerCommandAction({deviceId:this.props.selectedDevice.id},(error)=>{
            if(error){
                this.setState({
                    modalLoder:false,
                    error:true,
                    errorString:"Server Error Detected",
                    Success:false,
                    successString:""
                });
            }else {
                this.setState({
                    modalLoder:false,
                    error:false,
                    errorString:"",
                    Success:true,
                    successString:"Text Message Sent to Dealer",
                    userToSearch:""
                });
            }
        })

    }

    sendInstuctionsMessageMechanic(e:React.MouseEvent<HTMLButtonElement>){
        e.preventDefault();
        this.props.setSmsMechanicCommandAction({deviceId:this.props.selectedDevice.id},(error)=>{
            if(error){
                this.setState({
                    modalLoder:false,
                    error:true,
                    errorString:"Server Error Detected",
                    Success:false,
                    successString:""
                });
            }else {
                this.setState({
                    modalLoder:false,
                    error:false,
                    errorString:"",
                    Success:true,
                    successString:"Text Message Sent To Mechanic",
                    userToSearch:""
                });
            }
        })

    }


    handleShow(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>,DevicePhoneSelected:boolean,selectedAdminNumber?:string){
        e.preventDefault();
        if(DevicePhoneSelected){
            this.setState({
                isDevicePhoneSelected:true,
                showModal:true
            })
        }else {
            if(selectedAdminNumber)
                this.setState({
                    isDevicePhoneSelected:false,
                    showModal:true,
                    selectedAdminNumber
                })
        }
    }
    handleShow2(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>,DevicePhoneSelected:boolean,selectedAdminNumber?:string){
        e.preventDefault();
        this.setState({
            isDevicePhoneSelected:true,
            showModal2:true
        })
    }

    handleShow3(e:React.MouseEvent<HTMLAnchorElement, MouseEvent>){
        e.preventDefault();
        this.setState({
            showModal3:true
        })
    }

    handleShow4(e:React.MouseEvent<HTMLAnchorElement, MouseEvent>){
        e.preventDefault();
        this.setState({
            showModal4:true
        })
    }

    handleShow5(e:React.MouseEvent<HTMLAnchorElement, MouseEvent>){
        e.preventDefault();
        this.setState({
            showModal5:true
        })
    }

    handleShow6(e:React.MouseEvent<HTMLAnchorElement, MouseEvent>){
        e.preventDefault();
        this.setState({
            showModal6:true
        })
    }

    handleShow7(e:React.MouseEvent<HTMLButtonElement, MouseEvent>){
        e.preventDefault();
        this.setState({
            modalLoder:true,
            showModal7:true,
        },()=>{
            this.loadSubLogs();
        });

    }

    handleShow8(e:React.MouseEvent<HTMLButtonElement, MouseEvent>){
        e.preventDefault();
        this.setState({
            showModal8:true,
        });

    }

    handleShow9(e:React.MouseEvent<HTMLAnchorElement, MouseEvent>){
        e.preventDefault();

        this.setState({
            showModal9:true,
            modalLoder:true,
            selectDisabled:true
        });
        this.getActiveBundles();
        this.getDeviceRemainingRequestsReports();

    }

    handleShow10(e:React.MouseEvent<HTMLAnchorElement, MouseEvent>){
        e.preventDefault();

        this.getDeviceSubscriptionLogs();

        this.setState({
            showModal10:true,
            modalLoder:true,
            selectDisabled:true
        });

    }

    handleShow11(e:React.MouseEvent<HTMLButtonElement, MouseEvent>){
        e.preventDefault();

        this.getDeviceSubscriptionLogs();

        this.setState({
            showModal11:true,
            modalLoder:true,
            selectDisabled:true
        });

    }

    getDeviceRemainingRequestsReports(){
        this.setState({
            modalLoder:true,
        });
        this.props.getDeviceRemainingReportRequests(this.props.selectedDevice.id,(error)=>{
            if(error){
                this.setState({
                    modalLoder:false,
                    error:true,
                    errorString:"Server Error Detected",
                    Success:false,
                    successString:""
                });
            }else {
                this.setState({
                    modalLoder:false,
                    error:false,
                    errorString:"",
                    Success:false,
                    successString:"",
                });
            }

        })

    }

    handleClose(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal:false,
            error:false,
            errorString:"",
            Success:false,
            successString:"",
            uniqueIdSelected:false
        })
    }

    handleClose2(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal2:false,
            error:false,
            errorString:"",
            Success:false,
            successString:"",
            uniqueIdSelected:false
        })
    }

    handleClose3(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal3:false,
            error:false,
            errorString:"",
            Success:false,
            successString:"",
            uniqueIdSelected:false
        })
    }


    handleClose4(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal4:false,
            error:false,
            errorString:"",
            Success:false,
            successString:"",
            uniqueIdSelected:false
        })
    }

    handleClose5(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal5:false,
            error:false,
            errorString:"",
            Success:false,
            successString:"",
            uniqueIdSelected:false
        })
    }

    handleClose6(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal6:false,
            error:false,
            errorString:"",
            Success:false,
            successString:"",
            uniqueIdSelected:false
        })
    }

    handleClose7(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal7:false,
            error:false,
            errorString:"",
            Success:false,
            successString:"",
            uniqueIdSelected:false
        })
    }

    handleClose8(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal8:false,
            error:false,
            errorString:"",
            Success:false,
            successString:"",
            uniqueIdSelected:false
        })
    }

    handleClose9(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal9:false,
            error:false,
            errorString:"",
            Success:false,
            successString:"",
            uniqueIdSelected:false
        })
    }


    handleClose10(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal10:false,
            error:false,
            errorString:"",
            Success:false,
            successString:"",
            uniqueIdSelected:false
        })
    }

    handleClose11(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal11:false,
            error:false,
            errorString:"",
            Success:false,
            successString:"",
            uniqueIdSelected:false
        })
    }


    loadSubLogs(){
        this.props.getSubscriptionLogs({
            deviceId:this.props.selectedDevice.id,
            take:this.state.take,
            skip:this.state.skip
        },(error)=>{
            if(error){
                this.setState({
                    modalLoder:false,
                    error:true,
                    errorString:"Failed To Fetch Subscription Logs",
                    Success:false,
                    successString:""
                })

            }else{
                this.setState({
                    modalLoder:false,
                    error:false,
                    errorString:"",
                    Success:false,
                    successString:""
                })
            }
        });

    }


    enableDevice(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>){

        e.preventDefault();

        if(this.props.selectedDevice){
            let deviceId=this.props.selectedDevice.id;
            this.setState({
                loading:true
            });

            this.props.enableDevice({deviceId},(error)=>{

                if(error){
                    this.setState({
                        loading:false,
                        error:true,
                        errorString:"Failed to enable Device",
                        successString:"",
                        Success:false
                    });

                }else {
                    this.setState({
                        loading:false,
                        error:false,
                        errorString:"",
                        successString:"Device enabled",
                        Success:true
                    })
                }
            })
        }
    }

    disableDevice(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>){

        e.preventDefault();

        this.props.disableDevice({deviceId:this.props.selectedDevice.id},(error)=>{

            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Failed to disable Device",
                    successString:"",
                    Success:false
                });

            }else {
                this.setState({
                    loading:false,
                    error:false,
                    errorString:"",
                    successString:"Device disabled",
                    Success:true
                })

            }
        })
    }

    getAdminPhones(){
        this.props.getDeviceAdminPhoneAction(this.props.selectedDevice.id,(error)=>{
            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Failed to Get Device Control Numbers",
                    successString:"",
                    Success:false
                });

            }else {
                this.setState({
                    loading:false,
                    error:false,
                    errorString:"",
                    successString:"Device enabled",
                    Success:false
                })
            }
        })
    }

    registerTracker(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>){

        let devicePhone=this.props.selectedDevice.id;
        this.props.registerDeviceToTraccar({
            deviceId:this.props.selectedDevice.id,
            customerId:this.props.selectedCustomer.id,
            plateNumber:this.props.selectedDevice.details?.plateNumber,
            uniqueId:this.props.selectedDevice.unique_id,
            model:"gt06"

        },(error)=>{

            if(error){
                this.setState({
                    error: true,
                    errorString: "Failed To Register,Mybe Device already In Traccar",
                    successString: "",
                    Success: false,

                })
            }else {
                this.setState({
                    error: false,
                    errorString: "",
                    successString: "Successfully Registered Device in Traccar",
                    Success: true,

                })
            }

        })
    }

    UnregisterTracker(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) {

        this.props.UnregisterDeviceToTraccar({deviceId:this.props.selectedDevice.id}, (error) => {

            if(error){
                this.setState({
                    error: true,
                    errorString: "Failed To UnRegister,Mybe Device not In Traccar",
                    successString: "",
                    Success: false,

                })
            }else {
                this.setState({
                    error: false,
                    errorString: "",
                    successString: "Successfully Removed Device From Traccar",
                    Success: true,

                })
            }

        });
    }


    setPassword(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>){
        //set password in the device *** note we only use one password for all customer devices

        if(this.props.selectedCustomer){
            this.props.setPasswordCommandAction({deviceId:this.props.selectedDevice.id,customerId:this.props.selectedCustomer.id},(error)=>{
                if(error){
                    this.setState({
                        error:true,
                        errorString:"Command To Change Password Failed",
                        successString:"",
                        Success:false
                    })

                }else {
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Command To Change Password Sent SuccessFully",
                        Success:true,
                    },()=>{

                    });

                }
            })
        }else {
            this.setState({
                error:true,
                errorString:"Customer is Not Present On This Device",
                successString:"",
                Success:false
            })
        }

    }

    setAdmin(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>){

        e.preventDefault();

        if(this.props.selectedCustomer){
            this.props.setAdminCommandAction({deviceId:this.props.selectedDevice.id,customerId:this.props.selectedCustomer.id},(error)=>{

                if(error){
                    this.setState({
                        error:true,
                        errorString:"Command To Set Admin Failed",
                        successString:"",
                        Success:false
                    })

                }else {
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Command To Set Admin Sent SuccessFully",
                        Success:true
                    });
                }

            })
        }else {
            this.setState({
                error:true,
                errorString:"Customer Not Present On This Device",
                successString:"",
                Success:false
            })
        }

    }

    check(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>){


        if(this.props.selectedCustomer){
            this.props.checkCommandAction({deviceId:this.props.selectedDevice.id,customerId:this.props.selectedCustomer.id},(error)=>{

                if(error){
                    this.setState({
                        error:true,
                        errorString:"Command CHECK Failed",
                        successString:"",
                        Success:false
                    });
                }else {
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Command CHECK Sent SuccessFully",
                        Success:true
                    });
                }
            })
        }else {
            this.setState({
                error:true,
                errorString:"Customer Not Present On This Device",
                successString:"",
                Success:false
            })
        }

    }



    setTimeZone(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>){


        if(this.props.selectedCustomer){
            this.props.setTimezoneCommandAction({deviceId:this.props.selectedDevice.id,customerId:this.props.selectedCustomer.id},(error)=>{

                if(error){
                    this.setState({
                        error:true,
                        errorString:"Command To Set TimeZone Failed",
                        successString:"",
                        Success:false
                    });

                }else {
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Command To Set TimeZone Sent SuccessFully",
                        Success:true
                    });

                }
            })
        }else {
            this.setState({
                error:true,
                errorString:"Customer Not Present On This Device",
                successString:"",
                Success:false
            })
        }

    }

    setResponseTime(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>){
        if(this.props.selectedCustomer){

            this.props.setResponseTimeCommandAction({deviceId:this.props.selectedDevice.id,customerId:this.props.selectedCustomer.id},(error)=>{

                if(error){
                    this.setState({
                        error:true,
                        errorString:"Command To Set Response Failed",
                        successString:"",
                        Success:false
                    });
                }else {
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Command To Set Response Time Failed",
                        Success:true
                    });
                }

            })
        }else {
            this.setState({
                error:true,
                errorString:"Customer Not Present On This Device",
                successString:"",
                Success:false
            })
        }

    }

    setDomain(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>){

        if(this.props.selectedCustomer){

            this.props.setDomainCommandAction({deviceId:this.props.selectedDevice.id,customerId:this.props.selectedCustomer.id},(error)=>{

                if(error){
                    this.setState({
                        error:true,
                        errorString:"Command To Set Domain Failed",
                        successString:"",
                        Success:false
                    });
                }else {
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Command To Set Domain Sent SuccessFully",
                        Success:true
                    });
                }

            })
        }else {
            this.setState({
                error:true,
                errorString:"Customer Not Present On This Device",
                successString:"",
                Success:false
            })
        }

    }


    setApn(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>){

        if(this.props.selectedCustomer){

            this.props.setApnCommandAction({deviceId:this.props.selectedDevice.id,customerId:this.props.selectedCustomer.id},(error)=>{

                if(error){
                    this.setState({
                        error:true,
                        errorString:"Command To Set APN Failed",
                        successString:"",
                        Success:false
                    });

                }else {
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Command To Set APN Sent SuccessFully",
                        Success:true
                    });

                }
            })
        }else {
            this.setState({
                error:true,
                errorString:"Customer No Present On This Device",
                successString:"",
                Success:false
            })
        }

    }


    setAcc(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>){


        if(this.props.selectedCustomer){

            this.props.setAccCommandAction({deviceId:this.props.selectedDevice.id,customerId:this.props.selectedCustomer.id},(error)=>{

                if(error){
                    this.setState({
                        error:true,
                        errorString:"Command To Set ACC Sent SuccessFully",
                        successString:"",
                        Success:false
                    });
                }else {
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Command To Set ACC Sent SuccessFully",
                        Success:true
                    });
                }

            })
        }else {
            this.setState({
                error:true,
                errorString:"Customer No Present On This Device",
                successString:"",
                Success:false
            })
        }

    }

    stop(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>){


        if(this.props.selectedCustomer){

            this.props.deviceSmsStopAction({deviceId:this.props.selectedDevice.id,customerId:this.props.selectedCustomer.id},(error)=>{
                if(error){
                    this.setState({
                        error:true,
                        errorString:"Stop Command sent Successfully",
                        successString:"",
                        Success:false
                    });

                }else {
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Stop Command sent Successfully",
                        Success:true
                    });

                }
            })
        }else {
            this.setState({
                error:true,
                errorString:"Customer Not Present On This Device",
                successString:"",
                Success:false
            })
        }

    }

    resume(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>){


        if(this.props.selectedCustomer){

            this.props.deviceSmsResumeAction({deviceId:this.props.selectedDevice.id,customerId:this.props.selectedCustomer.id},(error)=>{

                if(error){
                    this.setState({
                        error:true,
                        errorString:"Resume Command Failed",
                        successString:"",
                        Success:false
                    });

                }else {
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Resume Command sent Successfully",
                        Success:true
                    });

                }
            })
        }else {
            this.setState({
                error:true,
                errorString:"Customer Not Present On This Device",
                successString:"",
                Success:false
            })
        }

    }



    reboot(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>){


        if(this.props.selectedCustomer){

            this.props.rebootCommandAction({deviceId:this.props.selectedDevice.id,customerId:this.props.selectedCustomer.id},(error)=>{

                if(error){
                    this.setState({
                        error:true,
                        errorString:"Reboot Command Failed",
                        successString:"",
                        Success:false
                    });

                }else {
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Reboot Command sent Successfully",
                        Success:true
                    });

                }
            })
        }else {
            this.setState({
                error:true,
                errorString:"Customer Not Present On This Device",
                successString:"",
                Success:false
            })
        }

    }

    instructionSms(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>){


        if(this.props.selectedCustomer){

            this.props.sendInstructionSmsAction({deviceId:this.props.selectedDevice.id},(error)=>{

                if(error){
                    this.setState({
                        error:true,
                        errorString:"Failed to send Sms To customer",
                        successString:"",
                        Success:false
                    });

                }else {
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Sms to Customer was Sent Successfully",
                        Success:true
                    });

                }
            })
        }else {
            this.setState({
                error:true,
                errorString:"Customer Not Present On This Device",
                successString:"",
                Success:false
            })
        }

    }


    reset(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>){


        if(this.props.selectedCustomer){

            this.props.deviceSmsResetAction({deviceId:this.props.selectedDevice.id,customerId:this.props.selectedCustomer.id},(error)=>{

                if(error){
                    this.setState({
                        error:true,
                        errorString:"Reset Command Failed",
                        successString:"",
                        Success:false
                    });

                }else {
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Reset Command sent Successfully",
                        Success:true
                    });

                }
            })
        }else {
            this.setState({
                error:true,
                errorString:"Customer Not Present On This Device",
                successString:"",
                Success:false
            })
        }

    }



    setSmsToCustomer(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>){



        if(this.props.selectedCustomer){

            this.props.setSmsCustomerCommandAction({deviceId:this.props.selectedDevice.id,customerId:this.props.selectedCustomer.id},(error)=>{

                if(error){
                    this.setState({
                        error:true,
                        errorString:"SMS to customer sent Successfully",
                        successString:"",
                        Success:false
                    });
                }else {
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"SMS to customer sent Successfully",
                        Success:true
                    });
                }

            })
        }else {
            this.setState({
                error:true,
                errorString:"Customer Not Present On This Device",
                successString:"",
                Success:false
            })
        }

    }

    addCustomNumber(){
        if(this.props.selectedCustomer){

            if(this.state.newControlNumber !==""){
                this.setState({
                    loading:true
                });
                this.props.customAdminAction({deviceId:this.props.selectedDevice.id,customerId:this.props.selectedCustomer.id,customNumber:this.state.newControlNumber},(error)=>{

                    if(error){
                        this.setState({
                            error:true,
                            errorString:"Admin Phone Number Addition Failed",
                            successString:"",
                            Success:false,
                            loading:false
                        });
                    }else {
                        this.setState({
                            error:false,
                            errorString:"",
                            successString:"Admin Phone Number Added Successfully",
                            Success:true,
                            newControlNumber:"",
                            loading:false
                        },()=>{
                            this.getAdminPhones();
                        });
                    }

                })

            }else {
                this.setState({
                    error:true,
                    errorString:"Please Input Control Number",
                    successString:"",
                    Success:false
                })
            }
        }else {
            this.setState({
                error:true,
                errorString:"Customer Not Present On This Device",
                successString:"",
                Success:false
            })
        }

    }

    removeCustomNumber(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>,phone:adminPhoneDataInterface|null=null){
        //let {deviceId,customNumber,AdminPhoneId,customerId}
        e.preventDefault();
        let deviceId=this.props.selectedDevice.id;
        let AdminPhoneId=phone?.id;
        let customerId=this.props.selectedCustomer.id;

        let data:object;

        if(phone!==null){
            let customNumber=this.state.newControlNumber;
            data={deviceId,customNumber,AdminPhoneId,customerId}

        }else {
            data={deviceId,AdminPhoneId,customerId};
        }


        if(this.state.newControlNumber!=="" || AdminPhoneId!=null){
            this.setState({
                loading:true
            });
            this.props.removeAdminAction(data,(error)=>{

                if(error){
                    this.setState({
                        error:true,
                        errorString:"SMS to Remove Control Number failed",
                        successString:"",
                        Success:false,
                        loading:false
                    });
                }else {

                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"SMS to Remove Control Number sent Successfully",
                        Success:true,
                        loading:false
                    },()=>{
                        this.getAdminPhones();
                    });

                }
            });

        }else {
            this.setState({
                error:true,
                errorString:"Please Input Control Number",
                successString:"",
                Success:false
            })
        }

    }

    showUniqueIdModal(e:React.MouseEvent<HTMLAnchorElement, MouseEvent>){
        e.preventDefault();
        this.setState({
            showModal:true,
            uniqueIdSelected:true
        })
    }

    searchDevice(e:React.MouseEvent){
        e.preventDefault();
        if(this.state.newUniqueId!==""){

            if(this.state.newUniqueId.length < 4){
                this.setState({
                    error:true,
                    errorString:"Please input at least Four characters to Search",
                    successString:"",
                    Success:false,
                    loading:false
                });

            }else {
                this.setState({
                    loading:true
                });

                this.props.searchDevicesWithoutDetails(this.state.newUniqueId,(error)=>{
                    if(error){
                        this.setState({
                            error:true,
                            errorString:"Failed To Search UNIQUEID",
                            successString:"",
                            Success:false,
                            loading:false
                        });
                    }else {
                        this.setState({
                            loading:false
                        })
                    }
                })

            }

        }else {
            this.setState({
                error:true,
                errorString:"Input UNIQUEID PLEASE",
                successString:"",
                Success:false,
                loading:false
            });
        }
    }

    swapDevice(e:React.MouseEvent<HTMLButtonElement, MouseEvent>,deviceId:number){
        e.preventDefault();
        this.setState({
            loading:true
        });

        this.props.swapDeviceAction({
            newDeviceId:deviceId,
            detailId:this.props.selectedDevice?.details?.id
        },(error)=>{

            if(!error){
                this.setState({
                    error:false,
                    errorString:"",
                    successString:"Device Swapped",
                    Success:true,
                    loading:false,
                    phone:""
                });
            }else {
                this.setState({
                    error:true,
                    errorString:"Failed To Swap Device",
                    successString:"",
                    Success:false,
                    loading:false
                });
            }
        })
    }

    updateDevicePhone(e:React.MouseEvent<HTMLButtonElement, MouseEvent>){
        e.preventDefault();

        if(this.state.phone.length >= 10 && this.state.phone.trim()!=""){
            this.props.updateDevicePhoneAction({
                phone:this.state.phone,
                deviceId:this.props.selectedDevice.id
            },(error)=>{
                if(!error){
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Device Sim Card Updated",
                        Success:true,
                        loading:false
                    });
                }else {
                    this.setState({
                        error:true,
                        errorString:"Failed To Update Device Sim Card Maybe Already in Use",
                        successString:"",
                        Success:false,
                        loading:false
                    });
                }
            })

        }else {
            this.setState({
                error:true,
                errorString:"Phone Number Should Not be Empty and should have ten numbers",
                successString:"",
                Success:false,
                loading:false
            });

        }
    }


    logPaginate(data:{selected:number}){
        this.setState({
            logsSkip: this.state.logsTake * data.selected
        },()=>{
            this.getDeviceSubscriptionLogs();
        })
    }
    paginate(data:{selected:number}){
        this.setState({
            skip: this.state.take * data.selected
        },()=>{
            this.loadSubLogs();
        })
    }
    nextPage(){
        this.setState({
            skip:this.state.skip+10,
        },()=>{
            this.loadSubLogs();

        });
    }

    previousPage(){
        this.setState({
            skip:this.state.skip-10,
        },()=>{
            this.loadSubLogs();
        });
    }


    updatePlateNumber(e:React.MouseEvent<HTMLButtonElement, MouseEvent>){
        e.preventDefault();

        if(this.state.plateNumber.trim()!==""){
            this.props.updatePlateNumberAction({
                plateNumber:this.state.plateNumber,
                deviceId:this.props.selectedDevice.id
            },(error)=>{
                if(!error){
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Plate Number Updated",
                        Success:true,
                        loading:false
                    });
                }else {
                    this.setState({
                        error:true,
                        errorString:"Plate Number Failed To be Updated",
                        successString:"",
                        Success:false,
                        loading:false
                    });
                }
            })

        }else {
            this.setState({
                error:true,
                errorString:"Please Input Plate Number",
                successString:"",
                Success:false,
                loading:false
            });

        }
    }

    updateSubScription(e:React.MouseEvent<HTMLButtonElement>){
        e.preventDefault();

        if(this.state.months!=="" && this.state.paidAmount!==""){
            this.props.updateSubscriptionAction({deviceId:this.props.selectedDevice.id,months:this.state.months,paidAmount:this.state.paidAmount},(error)=>{

                if(!error){
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Subscription Updated",
                        Success:true,
                        loading:false,
                        paidAmount:"",
                        months:""
                    });
                }else {
                    this.setState({
                        error:true,
                        errorString:"Server Error Detected",
                        successString:"",
                        Success:false,
                        loading:false,
                    });
                }
            })
        }else {
            this.setState({
                error:true,
                errorString:"Number of Months and Paid amount are Required",
                successString:"",
                Success:false,
                loading:false
            });
        }

    }

    setReportBundle(e:React.MouseEvent<HTMLButtonElement>|null=null,isBasic:boolean){

        if(!isBasic){

            if(this.state.price!=="" && this.state.bundleId!==""){
                this.props.activateBundle({isBasic:false,bundleId:this.state.bundleId,deviceId:this.props.selectedDevice.id,price:this.state.price},(error)=>{
                    if(!error){
                        this.setState({
                            error:false,
                            errorString:"",
                            successString:"Bundle Subscription Updated",
                            Success:true,
                            loading:false,
                            paidAmount:"",
                            months:"",
                            bundleId:"",
                            price:""
                        },()=>{
                            this.getDeviceRemainingRequestsReports();
                        });

                    }else {
                        this.setState({
                            error:true,
                            errorString:`Error Detected,is ${this.state.price} the correct price for this bundle`,
                            successString:"",
                            Success:false,
                            loading:false,
                        });
                    }

                });

            }else{
                this.setState({
                    error:true,
                    errorString:"Please Input Price and Bundle",
                    successString:"",
                    Success:false,
                    loading:false,
                });
            }

        }else {
            this.props.activateBundle({isBasic:true,deviceId:this.props.selectedDevice.id},(error)=>{
                if(!error){
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Bundle Subscription Updated",
                        Success:true,
                        loading:false,
                        paidAmount:"",
                        months:""
                    });
                }else {
                    this.setState({
                        error:true,
                        errorString:"Server Error Detected",
                        successString:"",
                        Success:false,
                        loading:false,
                    });
                }

            });
        }
    }


    render() {

        let logs=()=>{
            if(this.props.subDeviceLogs!==null){
                return this.props.subDeviceLogs[0].map((logs,index)=>{
                    return(
                        <React.Fragment>
                            <tr>
                                <td>{index + 1}</td>
                                <td>{logs.name}</td>
                                <td>{logs.receiptNo}</td>
                                <td>{logs.amountPaid}</td>
                                <td>{logs.monthsPaid}</td>
                                <td>{logs.modeOfPayment}</td>
                                <td>{logs.paymentPhoneNo}</td>
                                <td>{logs.rateAtPaymentDate}</td>
                                <td>{moment(logs.createdAt).format('DD-MM-YYYY HH:ss')}</td>
                            </tr>
                        </React.Fragment>
                    )
                })
            }
        };

        let users=()=>{
            if(this.props.searchedUsers){
                return this.props.searchedUsers.map((user,index)=>{

                    return (
                        <React.Fragment>
                            <tr>
                                <td>{index+1}</td>
                                <td>{user.fname}</td>
                                <td>{user.lname}</td>
                                <td>{user.phoneNumber}</td>
                                <td>
                                    <button className="btn btn-default" onClick={(e)=>this.sendInstuctionsMessage(e,user)}>Send Sms</button>
                                </td>
                            </tr>
                        </React.Fragment>
                    )
                })
            }else {
                return <React.Fragment/>
            }
        };

        let activeBunldes=()=>{

            if(this.props.activeBundles!==null){

                return this.props.activeBundles.map((bundle,index)=>{
                    return(
                        <option value={bundle.id}>{bundle.bundleName}</option>
                    )
                })
            }else {
                return(
                    <React.Fragment/>
                )
            }
        };

        let subscriptionLogs=()=>{

            if(this.props.subLogs!==null){

                return this.props.subLogs[0].map((logs,index)=>{
                    return(
                        <tr>
                            <td>{index + 1}</td>
                            <td>{logs.name}</td>
                            <td>{logs.type?.name}</td>
                            <td>{moment(logs.createdAt).format('DD-MM-YYYY HH:ss')}</td>
                            <td>{logs.rateAtPaymentDate}</td>
                            <td>{logs.amountPaid}</td>
                            <td>{logs.monthsPaid}</td>
                        </tr>
                    )
                });
            }else {
                return <React.Fragment/>
            }
        };

        let searchedDevicesWithoutDetails=()=>{
            return this.props.searchDeviceWithoutDetails?.map((device,index)=>{
                return (
                    <tr>
                        <th scope="row">{index+1}</th>
                        <td>{device.unique_id}</td>
                        <td>{moment(device.createdAt).format("DD-MM-YYYY HH:mm")}</td>
                        <td><button className="btn btn-default" onClick={(e)=>this.swapDevice(e,device.id)}>Select UNIQUEID</button></td>
                    </tr>
                )
            })
        };

        let modalSwitch=()=>{
            if(this.state.uniqueIdSelected){
               return (
                   <React.Fragment>
                       <div className="row">
                           <div className="col-md-12">
                               <LoaderComponent loading={this.state.loading}/>
                           </div>
                           <div className="col-md-12">
                               <div className="input-group mb-3">
                               <input className="form-control" type="text" placeholder="Search Unique Id" value={this.state.newUniqueId} onChange={(e)=>this.setState({newUniqueId:e.target.value})}/>
                               <button className="btn btn-default" onClick={(e)=>this.searchDevice(e)}>Search</button>
                               </div>
                           </div>
                       </div>
                       <table className="table">
                           <thead>
                           <tr>
                               <th scope="col">#</th>
                               <th scope="col">UNIQUE ID</th>
                               <th scope="col">DATE REGISTERED</th>
                           </tr>
                           </thead>
                           <tbody>
                           {searchedDevicesWithoutDetails()}
                           </tbody>
                       </table>
                   </React.Fragment>
               )

            }else {
                return (
                    <React.Fragment>
                        <div className="row mt-2 mb-2">
                            {
                                (()=>{
                                    if(this.props.authUserPermissions?.includes("POST_device_removeAdmin")){
                                        return (
                                            <React.Fragment>
                                                <div className="col-md-6">
                                                    <input type="number" className="form-control" placeholder="New Control Number" value={this.state.newControlNumber} onChange={(e)=>this.setState({newControlNumber:e.target.value})}/>
                                                </div>
                                                <div className="col-md-6">
                                                    <button className="btn btn-sm btn-success" onClick={(e)=>this.addCustomNumber()}>Add</button>
                                                    <button className="btn btn-sm btn-danger ml-3" onClick={(e)=>this.removeCustomNumber(e)}>Remove</button>
                                                </div>
                                            </React.Fragment>
                                        )
                                    }else {
                                    }
                                })()
                            }

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {adminPhones()}
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        };

        let adminPhones=()=>{
            let rows=()=>this.props.devicePhones?.phones?.map((phone,index)=>{
                return(
                    <React.Fragment>
                        <tr>
                            <th>Control Number {index +1}</th>
                            <th>{phone.phonenumber}</th>
                            <th>
                                {
                                    (()=>{
                                        if(this.props.authUserPermissions?.includes("POST_device_removeAdmin")){
                                            return <button className="btn btn-sm btn-danger ml-3" onClick={(e)=>this.removeCustomNumber(e,phone)}>Remove</button>
                                        }else {
                                            return <button className="btn btn-sm btn-danger ml-3" disabled>Remove</button>
                                        }
                                    })()
                                }
                            </th>
                        </tr>
                    </React.Fragment>
                )
            });

            return(
                <React.Fragment>
                    <table className="table table-bordered">
                        <tbody>
                        {rows()}
                        </tbody>
                    </table>
                </React.Fragment>

            )

        };

        let showNotification=()=>{
            if(!this.state.showModal){
                return (
                    <Notifiction error={this.state.error} successString={this.state.successString} success={this.state.Success} errorString={this.state.errorString}/>

                )
            }
        };

        let isAreadyRegistered=()=>{
            if(this.props.selectedDevice.details!==null){
                if(this.props.authUserPermissions?.includes("POST_change_device_UniqueId")){
                    return (
                        <a href="#" onClick={(e)=>this.showUniqueIdModal(e)}>{this.props.selectedDevice.unique_id}</a>
                    );
                }else {
                    return (
                        <a href="#">{this.props.selectedDevice.unique_id}</a>
                    );
                }

            }else {
                return (
                    <span>{this.props.selectedDevice.unique_id}</span>
                )
            }
        };

        let deviceCommands=()=>{
          if(this.props.selectedDevice.details !== null){

              if(this.props.selectedDevice.details?.devicePhone !==null && this.props.selectedCustomer!==null && !this.props.selectedDevice.details.notInService && this.props.selectedDevice.details.isWhoPaySet){
                  return (
                      <div className="row div-table-row">
                          <div className="col-md-2 div-table">
                              <button className="btn btn-default" onClick={(e)=>this.check(e)}>
                                  <i className="right fas fa-signal"/>
                                  &nbsp;
                                  Check Status
                              </button>
                          </div>
                          <div className="col-md-2 div-table">
                              <button className="btn btn-default" onClick={(e)=>this.setPassword(e)}>
                                  <i className="right fas fa-lock"/>
                                  &nbsp;
                                  Set Password
                              </button>
                          </div>
                          <div className="col-md-2 div-table">
                              <button className="btn btn-default" onClick={(e)=>this.setAdmin(e)}>
                                  <i className="right fas fa-lock"/>
                                  &nbsp;
                                  Set Admin
                              </button>
                          </div>
                          <div className="col-md-2 div-table">
                              <button className="btn btn-default"  onClick={(e)=>this.setTimeZone(e)}>
                                  <i className="right fas fa-globe-africa"/>
                                  &nbsp;
                                  Set Time Zone
                              </button>
                          </div>
                          <div className="col-md-2 div-table">
                              <button className="btn btn-default" onClick={(e)=>this.setApn(e)}>
                                  <i className="right fas fa-network-wired"/>
                                  &nbsp;
                                  Set Apn
                              </button>
                          </div>
                          <div className="col-md-2 div-table">
                              <button className="btn btn-default" onClick={(e)=>this.setDomain(e)}>
                                  <i className="right fas fa-satellite"/>
                                  &nbsp;
                                  Set Domain
                              </button>
                          </div>
                          <div className="col-md-2 div-table">
                              <button className="btn btn-default" onClick={(e)=>this.setAcc(e)}>
                                  <i className="right fas fa-toggle-on"/>
                                  &nbsp;
                                  Set ACC
                              </button>
                          </div>
                          <div className="col-md-2 div-table">
                              <button className="btn btn-default" onClick={(e)=>this.reboot(e)}>
                                  <i className="right fas fa-power-off"/>
                                  &nbsp;
                                  Reboot
                              </button>
                          </div>
                          <div className="col-md-2 div-table">
                              <button className="btn btn-default"  onClick={(e)=>this.stop(e)}>
                                  <i className="right fas fa-stop"/>
                                  &nbsp;
                                  Stop
                              </button>
                          </div>
                          <div className="col-md-2 div-table">
                              <button className="btn btn-default" onClick={(e)=>this.resume(e)}>
                                  <i className="right fas fa-play-circle"/>
                                  &nbsp;
                                  Resume
                              </button>
                          </div>
                          <div className="col-md-2 div-table">
                              <button className="btn btn-default" onClick={this.registerTracker}>
                                  <i className="right fas fa-compass"/>
                                  &nbsp;
                                  Register In Tracccar
                              </button>
                          </div>
                          <div className="col-md-3 div-table">
                              <button className="btn btn-default" onClick={this.UnregisterTracker}>
                                  <i className="right fas fa-compass"/>
                                  &nbsp;
                                  Unregister in Tracccar
                              </button>
                          </div>
                          <div className="col-md-2 div-table">
                              <button className="btn btn-default" onClick={this.enableDevice}>
                                  <i className="right fas fa-compass"/>
                                  &nbsp;
                                  Enable In Tracccar
                              </button>
                          </div>
                          <div className="col-md-2 div-table">
                              <button className="btn btn-default" onClick={this.disableDevice}>
                                  <i className="right fas fa-compass"/>
                                  &nbsp;
                                  Disable In Tracccar
                              </button>
                          </div>
                          <div className="col-md-2 div-table">
                              <button className="btn btn-default" onClick={(e)=>this.reset(e)}>
                                  <i className="right fas fa-plug"/>
                                  &nbsp;
                                  Reset
                              </button>
                          </div>
                          <div className="col-md-2 div-table">
                              <button className="btn btn-default" onClick={(e)=>this.instructionSms(e)}>
                                  <i className="right fas fa-plug"/>
                                  &nbsp;
                                  Instruction Sms
                              </button>
                          </div>
                          <div className="col-md-2 div-table">
                              <button className="btn btn-default" onClick={(e)=>this.handleShow8(e)}>
                                  <i className="right fas fa-plug"/>
                                  &nbsp;
                                  Instruction sms To User
                              </button>
                          </div>
                          <div className="col-md-2 div-table">
                              <button className="btn btn-default" onClick={(e)=>this.sendInstuctionsMessageMechanic(e)}>
                                  <i className="right fas fa-plug"/>
                                  &nbsp;
                                  Instruction sms To Mechanic
                              </button>
                          </div>
                          <div className="col-md-2 div-table">
                              <button className="btn btn-default" onClick={(e)=>this.sendInstuctionsMessageDealer(e)}>
                                  <i className="right fas fa-plug"/>
                                  &nbsp;
                                  Instruction sms To Dealer
                              </button>
                          </div>
                          <div className="col-md-2 div-table">
                              <button className="btn btn-default" onClick={(e)=>this.setReportBundle(e,true)}>
                                  <i className="right fas fa-plug"/>
                                  &nbsp;
                                  Setup Basic Report Bundle
                              </button>
                          </div>

                      </div>
                  )
              }else {
                  return (
                      <React.Fragment>
                      </React.Fragment>
                  )
              }
          }else {

              return (
                  <React.Fragment>
                  </React.Fragment>
              )
          }

        };

        let setupCustomerButton=()=>{
            if(this.props.selectedDevice.details !== null){
                //only show button when customer not set for this device
                return (
                    <button className="btn btn-default" onClick={()=>this.setState({showModal2:true})}>SETUP CUSTOMER</button>
                )
            }else {
                return (
                    <React.Fragment>
                        <button className="btn btn-default" disabled>SETUP CUSTOMER</button>
                    </React.Fragment>
                )
            }
        };

        let setup=()=>{

            if(this.props.selectedDevice.details?.devicePhone !==null){
                return (
                    <button className="btn btn-default" disabled>SETUP</button>
                )
            }else {
                return(
                    <InserPhoneToDevice deviceId={this.props.selectedDevice.id}/>
                )
            }
        };

        let checkWhoPay=()=>{

            if(this.props.debtItemData!==null){
                if(this.props.debtItemData.debt.whoPay===0){
                    return "Mechanic"
                }else if(this.props.debtItemData.debt.whoPay===1){
                    return "Marketing";
                }
            }else {
                return "Not Set";
            }
        };


        let showDeviceType=()=>{

            if(this.props.selectedCustomer===null){
                return(
                    <h3>{this.props.deviceType?.name}</h3>
                )
            }

        };


        return (
            <React.Fragment>
                <div className="legend">
                    <h6 className="legend-title">
                        Device Details
                    </h6>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h3>{showDeviceType()}</h3>
                        </div>
                    </div>
                    <div className="row div-table-row">
                        <div className="col-md-2 div-table div-table-title">
                            UNIQUE ID
                        </div>
                        <div className="col-md-3 div-table div-table-value">
                            {isAreadyRegistered()}
                        </div>
                        <div className="col-md-2 div-table div-table-title">
                            PLATE NUMBER
                        </div>
                        <div className="col-md-2 div-table div-table-value">
                            {
                                (()=>{
                                    if(this.props.authUserPermissions?.includes("POST_update_device_plate_number")){
                                        return  <a href="#" onClick={(e)=>this.handleShow4(e)}>{this.props.selectedDevice.details?.plateNumber}</a>
                                    }else {
                                        return  <a href="#">{this.props.selectedDevice.details?.plateNumber}</a>
                                    }

                                })()
                            }
                        </div>
                        <div className="col-md-1 div-table div-table-title">
                            DEVICE SIM CARD
                        </div>
                        <div className="col-md-2 div-table div-table-value">
                            {
                                (()=>{
                                    if(this.props.authUserPermissions?.includes("POST_update_simcard_details")){
                                        return <a href="#" onClick={(e)=>this.handleShow3(e)}>{this.props.selectedDevice.details?.devicePhone}</a>
                                    }else {
                                        return <a href="#">{this.props.selectedDevice.details?.devicePhone}</a>
                                    }

                                })()
                            }
                        </div>
                    </div>
                    <br/>
                    <div className="row div-table-row">
                        <div className="col-md-2 div-table div-table-title">
                            CUSTOMER NAME
                        </div>
                        <div className="col-md-2 div-table div-table-value">
                            {`${this.props.selectedCustomer?.fname? this.props.selectedCustomer?.fname:'Nill'}  ${this.props.selectedCustomer?.lname ? this.props.selectedCustomer?.lname:''}`}
                        </div>
                        <div className="col-md-2 div-table div-table-title">
                            DEVICE ADMIN NUMBERS
                        </div>
                        <div className="col-md-2 div-table div-table-value">
                            <button className="btn btn-default" onClick={()=>this.setState({showModal:true})}>ADMIN CONTROL NUMBERS</button>
                        </div>

                        <div className="col-md-2 div-table div-table-title">
                            EXPIRY DATE
                        </div>
                        <div className="col-md-2 div-table div-table-value">
                            {
                                (()=>{
                                    if(this.props.authUserPermissions?.includes("POST_update_subscription")){
                                        return (
                                            <a href="#" onClick={this.handleShow5}>
                                                {moment(this.props.selectedDevice.details?.expiry).format("DD-MM-YYYY HH:mm")}
                                            </a>
                                        )
                                    }else {
                                        return (
                                            <a href="#">
                                                {moment(this.props.selectedDevice.details?.expiry).format("DD-MM-YYYY HH:mm")}
                                            </a>
                                        )
                                    }
                                })()
                            }

                        </div>

                    </div>
                    <br/>
                    <div className="row div-table-row">
                        <div className="col-md-2 div-table div-table-title">
                            REGISTERED DATE
                        </div>
                        <div className="col-md-2 div-table div-table-value">
                            {moment(this.props.selectedDevice.details.configureDate).format('DD-MM-YYYY HH:mm')}
                        </div>

                        <div className="col-md-1 div-table div-table-title">
                            DEALER
                        </div>
                        <div className="col-md-3 div-table div-table-value">
                            {this.props.selectedDevice.details?.dealer?.dealerName}
                        </div>
                        <div className="col-md-1 div-table div-table-title">
                            USER<br/>PASS
                        </div>
                        <div className="col-md-3 div-table div-table-value">
                            {this.props.selectedCustomer?this.props.selectedCustomer.username:''}<br/>
                            {this.props.selectedCustomer?this.props.selectedCustomer.password:''}<br/>
                        </div>
                    </div>
                    <div className="row div-table-row mt-3">
                        <div className="col-md-1 div-table div-table-title">
                            Subscriptions
                        </div>
                        <div className="col-md-2 div-table div-table-value">
                            <button className="btn btn-default" onClick={this.handleShow7}>View</button>
                        </div>
                        <div className="col-md-1 div-table div-table-title">
                            Report Bundle
                        </div>
                        <div className="col-md-2 div-table div-table-value">
                            {
                                (()=>{
                                    if(this.props.authUserPermissions?.includes("POST_set_bundle_active_inactive")){
                                       return <a href="#" onClick={this.handleShow9}>{this.props.selectedDevice?.details?.bundle?this.props.selectedDevice?.details?.bundle?.bundleName:"Not Set"}</a>
                                    }else {
                                        return <a href="#">{this.props.selectedDevice?.details?.bundle?this.props.selectedDevice?.details?.bundle?.bundleName:"Not Set"}</a>
                                    }
                                })()
                            }
                        </div>
                        <div className="col-md-2 div-table div-table-title">
                            MECHANIC
                        </div>
                        <div className="col-md-2 div-table div-table-value">
                            {this.props.selectedDevice?.details?.mechanic?.fname + " " + this.props.selectedDevice?.details?.mechanic?.lname}
                        </div>
                        <div className="col-md-3 div-table div-table-title mt-3">
                            SUBSCRIPTION LOGS
                        </div>
                        <div className="col-md-2 div-table div-table-value mt-3">
                            <a href="#" onClick={this.handleShow10}>View Subscription Logs</a>

                        </div>
                        <div className="col-md-3 div-table div-table-title mt-3">
                            SET VERSION ({this.props.selectedDevice.deviceVersion})
                        </div>
                        <div className="col-md-2 div-table div-table-value mt-3">
                            <button className="btn btn-default" onClick={(e)=>this.selectedVersion("V1")}>SET V1</button>&nbsp;&nbsp;
                            <button className="btn btn-default" onClick={(e)=>this.selectedVersion("V2")}>SET V2</button>
                        </div>
                    </div>

                </div>
                {
                    (()=>{
                        if(this.props.authUserPermissions?.includes("POST_set_device_password")){
                            return (
                                <div className="legend">
                                    <h6 className="legend-title">
                                        Device Operations
                                    </h6>
                                    <div className="row div-table-row">
                                        <div className="col-md-2 div-table div-table-title">
                                            SIM CARD & CONTROL NUMBERS
                                        </div>
                                        <div className="col-md-1 div-table div-table-value">
                                            {setup()}
                                        </div>
                                        <div className="col-md-2 div-table div-table-title">
                                            SETUP CUSTOMER
                                        </div>
                                        <div className="col-md-2 div-table div-table-value">
                                            {setupCustomerButton()}
                                        </div>
                                        <div className="col-md-1 div-table div-table-title">
                                            SETUP DEPT

                                        </div>
                                        <div className="col-md-3 div-table div-table-value">
                                            <button className="btn btn-default mr-5"
                                                    onClick={(e) => this.handleShow11(e)}>Who Pays
                                            </button>
                                            {checkWhoPay()}
                                        </div>
                                        <div className="col-md-2 div-table div-table-title mt-3">
                                            SET OUT OF SERVICE
                                        </div>
                                        <div className="col-md-2 div-table div-table-value mt-3">
                                            {this.props.selectedDevice.details.notInService ?
                                                <button className="btn btn-danger" disabled>OUT OF SERVICE</button>
                                                :
                                                <button className="btn btn-danger" onClick={this.setOutOfService}>SET
                                                    OUT OF SERVICE</button>
                                            }
                                        </div>
                                        <div className="col-md-2 div-table div-table-title mt-3">
                                            Select Icon
                                        </div>
                                        <div className="col-md-2 div-table div-table-value mt-3">
                                            <select className="form-control" id="exampleSelect" onChange={this.setCategory}>
                                                <option value=""></option>
                                                <option value="motorcycle">motorcycle</option>
                                                <option value="bus">Bus</option>
                                                <option value="car">Car</option>
                                                <option value="pickup">Pickup</option>
                                                <option value="tractor">Tractor</option>
                                                <option value="default">Default</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            )
                        } else {
                            return (
                                <div className="legend">
                                    <h6 className="legend-title">
                                        Device Operations
                                    </h6>
                                    <div className="row div-table-row">
                                        <div className="col-md-2 div-table div-table-title">
                                            SIM CARD & CONTROL NUMBERS
                                        </div>
                                        <div className="col-md-2 div-table div-table-value">
                                            <button className="btn btn-default" disabled>SETUP</button>
                                        </div>
                                        <div className="col-md-2 div-table div-table-title">
                                            SETUP CUSTOMER
                                        </div>
                                        <div className="col-md-6 div-table div-table-value">
                                            <button className="btn btn-default" disabled>SETUP CUSTOMER</button>
                                        </div>
                                        <div className="col-md-2 div-table div-table-title mt-3">
                                            SET OUT OF SERVICE
                                        </div>
                                        <div className="col-md-2 div-table div-table-value mt-3">
                                            {this.props.selectedDevice.details.notInService?
                                                <button className="btn btn-danger" disabled>OUT OF SERVICE</button>
                                                :
                                                <button className="btn btn-danger" disabled>SET OUT OF SERVICE</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                            )
                        }
                    })()
                }

                {
                    (()=>{
                        if(this.props.authUserPermissions?.includes("POST_set_device_domain")){

                            return (
                                <div className="legend">
                                    <h6 className="legend-title">
                                        Device Commands
                                    </h6>
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <LoaderComponent loading={this.state.loading}/>
                                        </div>
                                    </div>
                                    {showNotification()}
                                    {deviceCommands()}
                                </div>

                            )
                        }
                    })()
                }

                <ModalComponent
                    title="Swap Control Numbers"
                    show={this.state.showModal}
                    handleClose={this.handleClose}
                    loader={this.state.modalLoder}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    {modalSwitch()}
                </ModalComponent>
                <ModalComponent
                    title="Search And Link Device To Customer"
                    show={this.state.showModal2}
                    handleClose={this.handleClose2}
                    loader={this.state.modalLoder}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    <SearchCustomerCreateAssignDevice/>
                </ModalComponent>
                <ModalComponent
                    title="Update Device Sim Card"
                    show={this.state.showModal3}
                    handleClose={this.handleClose3}
                    loader={this.state.modalLoder}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    <div className="col-md-12">
                        <LoaderComponent loading={this.state.loading}/>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">New Device Sim Card No</div>
                            </div>
                            <input type="number" className="form-control" placeholder="Sim Card Number"  value={this.state.phone}  onChange={(e)=>this.setState({phone:e.target.value})}/>
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="button" onClick={(e)=>this.updateDevicePhone(e)}>Update</button>
                            </div>
                        </div>
                    </div>
                </ModalComponent>
                <ModalComponent
                    title="Change Plate Number"
                    show={this.state.showModal4}
                    handleClose={this.handleClose4}
                    loader={this.state.modalLoder}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    <div className="col-md-12">
                        <LoaderComponent loading={this.state.loading}/>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">New Plate Number</div>
                            </div>
                            <input type="text" className="form-control" placeholder="Plate Number"  value={this.state.plateNumber}  onChange={(e)=>this.setState({plateNumber:e.target.value})}/>
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="button" onClick={(e)=>this.updatePlateNumber(e)}>Update</button>
                            </div>
                        </div>
                    </div>

                </ModalComponent>
                <ModalComponent
                    title="Extend Months"
                    show={this.state.showModal5}
                    handleClose={this.handleClose5}
                    loader={this.state.modalLoder}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    <div className="col-md-12">
                        <LoaderComponent loading={this.state.loading}/>
                        <div className="form-row">
                            <div className="input-group mb-2 col-md-6">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Number of Months</div>
                                </div>
                                <input type="number" className="form-control" placeholder="Months"  value={this.state.months}  onChange={(e)=>this.setState({months:e.target.value})}/>
                            </div>
                            <div className="input-group mb-2 col-md-6">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Amount Paid</div>
                                </div>
                                <input type="number" className="form-control" placeholder="Paid Amount"  value={this.state.paidAmount}  onChange={(e)=>this.setState({paidAmount:e.target.value})}/>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" onClick={(e)=>this.updateSubScription(e)}>Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalComponent>
                <ModalComponent
                    title="Subscriptions"
                    show={this.state.showModal7}
                    handleClose={this.handleClose7}
                    loader={this.state.modalLoder}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >



'                    <ReactPaginate
                        containerClassName="pagination"
                        breakClassName="page-item"
                        breakLabel={<a className="page-link">...</a>}
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                        pageCount={this.props.subLogs?this.props.subLogs[1]/this.state.take:0}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        onPageChange={this.paginate}
                    />

                </ModalComponent>
                <ModalComponent
                    title="Send Sms To User"
                    show={this.state.showModal8}
                    handleClose={this.handleClose8}
                    loader={this.state.modalLoder}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    <LoaderComponent loading={this.state.loading}/>
                    <div className="input-group mb-2">
                        <div className="input-group-prepend">
                            <div className="input-group-text">Search User</div>
                        </div>
                        <input type="text" className="form-control" placeholder="Name"  value={this.state.userToSearch} onChange={(e)=>this.setState({userToSearch:e.target.value})}/>
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" type="button" onClick={this.searchUsers}>Search</button>
                        </div>
                    </div>

                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">Phone Number</th>
                        </tr>
                        </thead>
                        <tbody>
                        {users()}
                        </tbody>
                    </table>

                </ModalComponent>
                <ModalComponent
                    title="Assign Report Bundle"
                    show={this.state.showModal9}
                    handleClose={this.handleClose9}
                    loader={this.state.modalLoder}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Routes</th>
                            <th scope="col">Summary</th>
                            <th scope="col">Stops</th>
                            <th scope="col">Events</th>
                            <th scope="col">Trips</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>{this.props.deviceRemainingRequestsReport?this.props.deviceRemainingRequestsReport.routes:""}</td>
                            <td>{this.props.deviceRemainingRequestsReport?this.props.deviceRemainingRequestsReport.summary:""}</td>
                            <td>{this.props.deviceRemainingRequestsReport?this.props.deviceRemainingRequestsReport.stops:""}</td>
                            <td>{this.props.deviceRemainingRequestsReport?this.props.deviceRemainingRequestsReport.events:""}</td>
                            <td>{this.props.deviceRemainingRequestsReport?this.props.deviceRemainingRequestsReport.trips:""}</td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="form-group">
                        <div className="form-row">
                            <div className="input-group mb-2 col-md-4">
                                <input type="text" className="form-control" placeholder="Price"  value={this.state.price}  onChange={(e)=>this.setState({price:e.target.value})}/>
                            </div>
                            <div className="input-group mb-3 col-md-8">
                                <select className="custom-select" disabled={this.state.selectDisabled} onChange={(e)=>this.setState({bundleId:e.target.value})}>
                                    <option selected/>
                                    {activeBunldes()}
                                </select>
                                <button className="btn btn-default" onClick={(e)=>this.setReportBundle(null,false)}>Assign Bundle</button>
                            </div>
                        </div>
                    </div>
                </ModalComponent>
                <ModalComponent
                    title="Subscription Logs"
                    show={this.state.showModal10}
                    handleClose={this.handleClose10}
                    loader={this.state.modalLoder}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Receipt No</th>
                            <th scope="col">Amount Paid</th>
                            <th scope="col">Months Paid</th>
                            <th scope="col">Mode of Payment</th>
                            <th scope="col">Payment Phone Number</th>
                            <th scope="col">Rate at Payment Date</th>
                            <th scope="col">Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {logs()}
                        </tbody>
                    </table>
                    <ReactPaginate
                        containerClassName="pagination"
                        breakClassName="page-item"
                        breakLabel={<a className="page-link">...</a>}
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                        pageCount={this.props.subDeviceLogs?this.props.subDeviceLogs[1]/this.state.logsTake:0}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        onPageChange={this.logPaginate}
                    />
                </ModalComponent>
                <ModalComponent
                    title="Payments Personnel"
                    show={this.state.showModal11}
                    handleClose={this.handleClose11}
                    loader={this.state.modalLoder}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                   <div className="row">
                       <div className="col-12">
                           <div className="form-group">
                               <label htmlFor="pymtnp">Select Payment Personnel</label>
                               <select className="form-control" id="pymtnp" onChange={(e)=>this.setState({whoPay:e.target.value})}>
                                   <option/>
                                   <option value="0">Mechanic</option>
                                   <option value="1">Marketing</option>
                               </select>
                           </div>
                       </div>
                       <div className="col-12">
                           <button type="button" className="btn btn-default btn-sm btn-block" onClick={(e)=>this.createDebt(e)}>Assign Debt</button>
                       </div>
                   </div>
                </ModalComponent>

            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        selectedDevice:state.selectedDevice,
        devicePhones:state.devicePhones,
        selectedCustomer:state.selectedCustomer,
        searchDeviceWithoutDetails:state.searchDeviceWithoutDetails,
        subLogs:state.subScriptionLogs,
        searchedUsers:state.searchedUsers,
        activeBundles:state.activeBundles,
        deviceRemainingRequestsReport:state.deviceReportRequests,
        subDeviceLogs:state.deviceSubscriptionLogs,
        authUserPermissions:state.authUserPermissions,
        debtItemData:state.getDebtItemByDetailId,
        deviceType:state.selectedDeviceType
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {
            searchDevicesWithoutDetails,
            getDeviceAdminPhoneAction,
            registerDeviceToTraccar,
            sendSmsCommandAction,
            setAccCommandAction,
            setAdminCommandAction,
            setApnCommandAction,
            setDomainCommandAction,
            setPasswordCommandAction,
            setResponseTimeCommandAction,
            setTimezoneCommandAction,
            setSmsCustomerCommandAction,
            checkCommandAction,
            rebootCommandAction,
            UnregisterDeviceToTraccar,
            removeAdminAction,
            deviceSmsResetAction,
            deviceSmsStopAction,
            deviceSmsResumeAction,
            customAdminAction,
            enableDevice,
            disableDevice,
            swapDeviceAction,
            updateDevicePhoneAction,
            updatePlateNumberAction,
            updateSubscriptionAction,
            getSubscriptionLogs,
            sendInstructionSmsAction,
            searchUsers,
            sendInstuctionSmsToUser,
            activateBundle,
            getActiveBundlesAction,
            getDeviceRemainingReportRequests,
            getAllMechanics,
            setSmsMechanicCommandAction,
            setSmsDealerCommandAction,
            getDeviceSubscriptionLogs,
            setOutOfService,
            createDebtAction,
            getDebtItemsByDetailId,
            postDeviceVersionAction,
            setCategoryAction

        }, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetailsViewComponent);
