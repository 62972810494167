import React, {ButtonHTMLAttributes} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import ModalComponent from "../Modal/ModalComponent";
import insertPhoneToDeviceAction from "../../actions/device/insertPhoneToDeviceAction";
import {UsersDataInterface} from "../../dataInterface/UsersDataInterface";
import {getMechanicsAction} from "../../actions/users/getMechanicsAction";
import {getSubscriptiontypes} from "../../actions/subscription/getsubscriptionTypeAction";
import {subScriptionTypeDataInterface} from "../../dataInterface/subScriptionTypeDataInterface";
import {mechanicsDataInterface} from "../../dataInterface/mechanicsDataInterface";
import {getAllMechanics} from "../../actions/mechanic/getAllMechanicAction";
import {getDealersNonPageableAction} from "../../actions/dealer/getDealersNonPageableAction";
import allMechanics from "../../reducers/mechanics/allMechanicsReducer";
import {dealerDataInterface} from "../../dataInterface/dealerDataInterface";
import {DeviceTypeDataInterface} from "../../dataInterface/deviceTypeDataInterface";

interface internalState {
    loading:boolean,
    modalLoder:boolean,
    Success:boolean,
    error:boolean,
    errorString:string,
    successString:string,
    showModal:boolean,
    phone:string,
    notificationPhone1:string,
    notificationPhone2:string,
    mechanicId:string,
    dealerId:string,
    plateNumber:string,
    subType:string
}

interface parentProps {
    deviceId:number,
    reload?:()=>void

}

interface externalStateProps {
    mechanics:Array<mechanicsDataInterface>,
    subScriptionTypes:Array<subScriptionTypeDataInterface>,
    alldealers:Array<dealerDataInterface>,
    deviceType:DeviceTypeDataInterface,
    deviceVersion:{version:string}


}


interface DispatchProps {
    insertPhoneToDeviceAction:(data:object,callback:(error:boolean)=>void)=>void,
    getAllMechanics:(callback:()=>void)=>void,
    getDealersNonPageableAction:(data:object,callback:(error:boolean)=>void)=>void,
    getSubscriptiontypes:(callback:(error:boolean)=>void)=>void,

}

type Props = externalStateProps & DispatchProps & parentProps;

class InserPhoneToDevice extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            loading:false,
            modalLoder:false,
            Success:false,
            error:false,
            successString:"",
            errorString:"",
            showModal:false,
            phone:"",
            notificationPhone1:"",
            notificationPhone2:"",
            mechanicId:"",
            dealerId:"",
            plateNumber:"",
            subType:""
        };

        this.handleShow=this.handleShow.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.submit=this.submit.bind(this);

    }

    componentDidMount(): void {
        this.setState({loading:true});
        this.props.getAllMechanics(()=>{
            this.setState({loading:false})
        });

        this.props.getSubscriptiontypes((error)=>{

        });


        this.props.getDealersNonPageableAction({},error =>{});
    }

    handleShow(){
        this.setState({
            showModal:true
        })
    }
    handleClose(){
        this.setState({
            showModal:false,
            Success:false,
            successString:"",
            error:false,
            errorString:""
        })
    }
    submit(e:React.MouseEvent){
        e.preventDefault();


        if(this.state.phone!=="" && this.state.notificationPhone1!=="" && this.state.mechanicId!=="" && this.state.plateNumber!==""){
            this.setState({
                Success:false,
                successString:"",
                error:false,
                errorString:"",
                modalLoder:true,
            })
            this.props.insertPhoneToDeviceAction(
                {
                    phoneNumber:this.state.phone,
                    deviceId:this.props.deviceId,
                    notificationPhone1:this.state.notificationPhone1,
                    notificationPhone2:this.state.notificationPhone2,
                    mechanicId:this.state.mechanicId,
                    dealerId:this.state.dealerId,
                    plateNumber:this.state.plateNumber,
                    subType:this.props.deviceType.id,
                    deviceVersion:this.props.deviceVersion.version
                },(error)=>{

                    if(!error){
                        this.setState({modalLoder:false,phone:"",notificationPhone1:"",notificationPhone2:"",Success:true,successString:"Operation Details Saved",mechanicId:"",plateNumber:""});
                        //this.handleClose();
                    }else {
                        this.setState({modalLoder:false,phone:"",notificationPhone1:"",notificationPhone2:"",Success:false,successString:"",errorString:"Failed to save Operation Details",error:true,mechanicId:"",plateNumber:""});
                    }

                if(this.props.reload) this.props.reload()
            });
        }else {
            this.setState({
                Success:false,
                successString:"",
                error:true,
                errorString:"Please Input Phone Number,Primary Admin Phone Number and Select Mechanic"
            })
        }
    }

    render() {

        let showDeviceType=()=>{

            if(this.props.deviceType!==null){
                return(
                    <h3>{this.props.deviceType?.name}</h3>
                )
            }

        };

        let mechanics=this.props.mechanics?.map((mechanic)=>{

            return(
                <option value={mechanic.id}>{mechanic.fname + " " + mechanic.lname}</option>
            )
        });

        let dealers=this.props.alldealers?.map((dealer)=>{

            return(
                <option value={dealer.id}>{dealer.fname + " " + dealer.lname}</option>
            )
        });

        let subScriptionTypes=this.props.subScriptionTypes?.map((types,index)=>{

            return <option value={types.id}>{types.name}</option>
        });

        return (
            <React.Fragment>
                <button  className="btn btn-default" onClick={()=>this.handleShow()}>SETUP</button>
                <ModalComponent
                    title="Device Operation Details"
                    handleShow={this.handleShow}
                    show={this.state.showModal}
                    handleClose={this.handleClose}
                    save={this.submit}
                    loader={this.state.modalLoder}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    <form>
                       <div className="row">
                           <div className="col-md-12 mb-3 text-center">
                               <h3>{showDeviceType()}</h3>
                           </div>
                           <div className="col-md-4">
                               <div className="form-group">
                                   <label htmlFor="phone">Device Sim Card Number</label>
                                   <input type="number" className="form-control" id="phone" value={this.state.phone}
                                          onChange={(e)=>this.setState({phone:e.target.value})} autoComplete="off"/>
                               </div>
                           </div>
                           <div className="col-md-8">
                               <div className="form-group">
                                   <label htmlFor="phone1">Control Number(Main)</label>
                                   <input type="number" className="form-control" id="phone1" value={this.state.notificationPhone1}
                                          onChange={(e)=>this.setState({notificationPhone1:e.target.value})} autoComplete="off"/>
                               </div>
                           </div>
                           <div className="col-md-8">
                               <div className="form-group">
                                   <label htmlFor="phone2">Control Number(Secondary)</label>
                                   <input type="number" className="form-control" id="phone2" value={this.state.notificationPhone2}
                                          onChange={(e)=>this.setState({notificationPhone2:e.target.value})} autoComplete="off"/>
                               </div>
                           </div>
                           <div className="col-md-4">
                               <div className="form-group">
                                   <label htmlFor="plate">Plate Number</label>
                                   <input type="text" className="form-control" id="plate" value={this.state.plateNumber}
                                          onChange={(e)=>this.setState({plateNumber:e.target.value})} autoComplete="off"/>
                               </div>
                           </div>
                           <div className="col-md-12">
                               <div className="form-group">
                                   <label htmlFor="mech">Select Mechanic</label>
                                   <select className="form-control" id="mec" onChange={(e)=>this.setState({mechanicId:e.target.value})}>
                                       <option></option>
                                       {mechanics}
                                   </select>
                               </div>
                           </div>
                           <div className="col-md-12">
                               <div className="form-group">
                                   <label htmlFor="mech">Select Marketing</label>
                                   <select className="form-control" id="mec" onChange={(e)=>this.setState({dealerId:e.target.value})}>
                                       <option></option>
                                       {dealers}
                                   </select>
                               </div>
                           </div>
                           {/*<div className="col-md-12">*/}
                           {/*    <div className="form-group">*/}
                           {/*        <label htmlFor="mech">Select SubScription Type</label>*/}
                           {/*        <select className="form-control" id="mec" onChange={(e)=>this.setState({subType:e.target.value})}>*/}
                           {/*            <option></option>*/}
                           {/*            {subScriptionTypes}*/}
                           {/*        </select>*/}
                           {/*    </div>*/}
                           {/*</div>*/}
                       </div>
                    </form>
                </ModalComponent>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        mechanics:state.allMechanics,
        subScriptionTypes:state.subScriptionTypes,
        alldealers:state.getDealersNonPageable,
        deviceType:state.selectedDeviceType,
        deviceVersion:state.selectedDeviceVersion


    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {insertPhoneToDeviceAction,getAllMechanics,getSubscriptiontypes,getDealersNonPageableAction}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(InserPhoneToDevice);
