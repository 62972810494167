import {roleDataTypeInterface} from "../../dataInterface/roleDataTypeInterface";
import {PermissionDataInterface} from "../../dataInterface/permissionDataInterface";


interface fetchRolesReducer {
    type:string
    payload:PermissionDataInterface
}

export default (state = null, action:fetchRolesReducer) => {
    switch (action.type) {
        case "GET_PERMISSIONS":
            return action.payload;
        default:
            return state;
    }
};