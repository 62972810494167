import { combineReducers } from "redux";
import loginReducer from "./login/loginReducer";
import loggenInUserReducer from "./users/loggenInUserReducer";
import searchDevice from "./device/searchDeviceReducer";
import searchDeviceToLinkReducer from "./device/searchDeviceToLinkReducer";
import allDevices from "./device/allDevicesReducer";
import deviceAttentionReducer from "./device/deviceAttentionReducer";
import searchedCustomers from "./customer/searchCustomerReducer";
import getCustomersReducer from "./customer/getCustomersReducer";
import selectedCustomerReducer from "./customer/selectedCustomerReducer";
import fetchusersReducer from "./users/fetchusersReducer";
import searchUserReducer from "./users/searchUserReducer";
import getRolesReducer from "./role/getRolesReducer";
import getRolesPageableReducer from "./role/getRolesPageableReducer";
import permissionsReducer from "./permissions/permissionsReducer";
import selectedDeviceReducer from "./device/selectedDeviceReducer";
import adminDetailsReducer from "./details/adminDetailsReducer";
import dealersReducer from "./dealer/dealersReducer";
import dealerDevicesReducer from "./dealer/dealerDevicesReducer";
import customerDevices from "./device/customerDevicesReducer";
import devicePhoneReducer from "./device/devicePhoneReducer";
import salesDataByMonthReducer from "./device/salesDataByMonthReducer";
import salesDataByDayReducer from "./report/getDeviceReportDailyReducer"
import searchDeviceWithoutDetailsReducer from "./device/searchDeviceWithoutDetailsReducer";
import simCardReducer from "./simcard/simCardReducer";
import selectedDealerReducer from "./dealer/selectedDealerReducer";
import subScriptionTypesPageable from "./subscription/subscriptionTypePageableReducer";
import subScriptionTypes from "./subscription/subscriptionTypeReducer";
import subScriptionLogs from "./subscription/subScriptionLogsReducer";
import subScriptiondeviceLogs from "./subscription/deviceSubscriptionLogReducer";

import selectedRole from "./role/selectedRoleReducer";
import settingsTypeReducer from "./settings/getTypeSettingsReducer";
import getUsersSettingsByTypeByUser from "./settings/getUserSettingsByTypeByUserReducer";
import authUserPermissionsReducer from "./permissions/authUserPermissionsReducer";
import allBundlesReducer from "./bundle/allBundleReducer";
import activeBundleReducer from "./bundle/activeBundleReducer";
import deviceReportRequestsReducer from "./device/deviceReportRequestsReducer";
import mechanicsPageable from "./mechanics/allMechanicsPageableReducer";
import allMechanics from "./mechanics/allMechanicsReducer";
import getMechanic from "./mechanics/mechanicReducer";
import mechanicsReportReducer from "./report/mechanicsReportReducer";
import mechanicReportByDateRangeReducer from "./report/mechanicReportByDateRangeReducer";
import getDeviceReportMonthlyReducer from "./report/getDeviceReportMonthlyReducer";
import messageReducer from "./message/messageReducer";
import getSubscriptionReportSalesReducer from "./report/getSubscriptionReportSalesReducer";
import getDeviceMoneySalesReducer from "./report/getDeviceMoneySalesReducer";
import getAllSubscriptionLogsReducer from "./subscription/getAllSubscriptionLogsReducer";
import getAllDebtsReducer from "./debt/getAllDebtsReducer";
import debtItemsNonPageableReducer from "./debt/debtItemsNonPageableReducer";
import getDealerNonPageableReducer from "./dealer/getDealerNonPageableReducer";
import getDebtItemByDetailIdReducer from "./debt/getDebtItemByDetailIdReducer";
import typeofDeviceReducer from "./device/typeofDeviceReducer";
import deviceVersion from "./device/deviceVersionReducer";

const rootReducer=combineReducers({
    user: loggenInUserReducer,
    auth:loginReducer,
    searchedDevices:searchDevice,
    searchedCustomers,
    getUsers:fetchusersReducer,
    searchedUsers:searchUserReducer,
    allRoles:getRolesReducer,
    allRolesPageable:getRolesPageableReducer,
    getPermissions:permissionsReducer,
    allDevices,
    deviceAttention:deviceAttentionReducer,
    selectedDevice:selectedDeviceReducer,
    adminDetails:adminDetailsReducer,
    allCustomers:getCustomersReducer,
    dealers:dealersReducer,
    searchDeviceToLink:searchDeviceToLinkReducer,
    allDealerDevices:dealerDevicesReducer,
    customerDevices,
    devicePhones:devicePhoneReducer,
    selectedCustomer:selectedCustomerReducer,
    salesDataByMonth:salesDataByMonthReducer,
    searchDeviceWithoutDetails:searchDeviceWithoutDetailsReducer,
    simcards:simCardReducer,
    selectedDealer:selectedDealerReducer,
    subScriptionTypesPageable,
    subScriptionTypes,
    subScriptionLogs,
    selectedRole,
    settingsType:settingsTypeReducer,
    usersSettingsByTypeByUser:getUsersSettingsByTypeByUser,
    authUserPermissions:authUserPermissionsReducer,
    allBundles:allBundlesReducer,
    activeBundles:activeBundleReducer,
    deviceReportRequests:deviceReportRequestsReducer,
    mechanicsPageable,
    allMechanics,
    getMechanic,
    mechanicsDataReport:mechanicsReportReducer,
    mechanicReportDateRange:mechanicReportByDateRangeReducer,
    deviceSalesMonthly:getDeviceReportMonthlyReducer,
    salesDataByDay:salesDataByDayReducer,
    deviceSubscriptionLogs:subScriptiondeviceLogs,
    messages:messageReducer,
    subscriptionSales:getSubscriptionReportSalesReducer,
    deviceSalesByMoney:getDeviceMoneySalesReducer,
    allSubscriptionLogs:getAllSubscriptionLogsReducer,
    allDebts:getAllDebtsReducer,
    debtItemsNonPageable:debtItemsNonPageableReducer,
    getDealersNonPageable:getDealerNonPageableReducer,
    getDebtItemByDetailId:getDebtItemByDetailIdReducer,
    selectedDeviceType:typeofDeviceReducer,
    selectedDeviceVersion:deviceVersion,
});

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer;
