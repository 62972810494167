import {HTTP} from "../../helpers/axios";
// src/thunks.ts
import {AxiosResponse} from "axios";
import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import { serverRoutes} from "../../api/serverRoutes";


export const deviceSmsInitAction = (data:object,callback:(response:AxiosResponse)=>void):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {

    try {
        const response=  await   HTTP(getState,dispatch).post(serverRoutes.deviceSmsInit,data);

        callback(response);

        dispatch({
            type: 'ALL_DEVICES',
            payload: response.data
        });
    }catch (e) {

    }


};

