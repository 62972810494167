import {
    devicesSalesInMoneyReportMonthlyInterface
} from "../../dataInterface/deviceSalesReportMonthlyInterface";
import {DebtDataInterface} from "../../dataInterface/debtDataInterface";
import {DebtItemsDatainterface} from "../../dataInterface/debtItemsDatainterface";


interface debtItems {
    type:string
    payload:DebtItemsDatainterface
}

export default (state = null, action:debtItems) => {
    switch (action.type) {
        case "DEBT_ITEMS_DETAIL_ID":
            return action.payload;
        default:
            return state;
    }
};
