import {
    devicesSalesInMoneyReportMonthlyInterface
} from "../../dataInterface/deviceSalesReportMonthlyInterface";
import {DebtDataInterface} from "../../dataInterface/debtDataInterface";


interface alldebts {
    type:string
    payload:[Array<DebtDataInterface>,number]
}

export default (state = null, action:alldebts) => {
    switch (action.type) {
        case "ALL_DEBTS":
            return action.payload;
        default:
            return state;
    }
};
