import {
    devicesSalesInMoneyReportMonthlyInterface
} from "../../dataInterface/deviceSalesReportMonthlyInterface";


interface deviceSalesMoneyReport {
    type:string
    payload:devicesSalesInMoneyReportMonthlyInterface
}

export default (state = null, action:deviceSalesMoneyReport) => {
    switch (action.type) {
        case "DEVICE_MONEY_SALES_MONTHLY":
            return action.payload;
        default:
            return state;
    }
};
