import React, {ButtonHTMLAttributes} from "react";
import {bindActionCreators, Dispatch} from "redux";
import createCustomerAction from "../../actions/customer/createCustomerAction";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import ModalComponent from "../Modal/ModalComponent";
import {getCustomersAction} from "../../actions/customer/getCustomerAction";


interface internalState {
    loading:boolean,
    modalLoder:boolean,
    showModal:boolean,
    Success:boolean,
    fname:string,
    lname:string,
    phone1:string,
    phone2:string,
    error:boolean,
    errorString:string,
    successString:string,
    take:number,
    skip:number
}

interface parentProps {

}

interface externalStateProps {
}


interface DispatchProps {
    createCustomerAction:(customer:object,callback:(error:boolean)=>void)=>void,
    getCustomersAction:(dd:object,callback:(error:boolean)=>void)=>void,

}

type Props = externalStateProps & DispatchProps & parentProps;

class CreateCustomerComponent extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);

        this.state={
            loading:false,
            fname:"",
            lname:"",
            phone1:"",
            phone2:"",
            modalLoder:false,
            Success:false,
            error:false,
            errorString:"",
            successString:"",
            showModal:false,
            take:10,
            skip:0
        };
        this.createUser=this.createUser.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.handleShow=this.handleShow.bind(this);


    }




    handleShow(e:React.MouseEvent<HTMLButtonElement>){
        e.preventDefault();
        this.setState({
            showModal:true
        })
    }

    handleClose(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal:false
        })
    }

    loadCustomers(){
        this.props.getCustomersAction({take:this.state.take,skip:this.state.skip},(error)=>{


            if(!error){
                this.setState({
                    loading:false,
                    error:false,
                    errorString:"",
                });
            }else {
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Failed To Load all Customers From Server"
                });
            }

        })
    }





    createUser(e:React.MouseEvent){
        e.preventDefault();

        if(this.state.fname!="" && this.state.lname!="" && this.state.phone1!=""){
            this.setState({modalLoder:true,error:false});
            this.props.createCustomerAction(
                {
                    fname:this.state.fname,
                    lname:this.state.lname,
                    primary_phone:this.state.phone1,
                    secondary_phone:this.state.phone2
                },
                (error)=>{
                    if(!error){
                        this.setState(
                            {
                                modalLoder:false,
                                fname:"",
                                lname:"",
                                phone2:"",
                                phone1:"",
                                Success:true,
                                successString:"Customer created and added in Traccar",
                                error:false,
                                errorString:""
                            },()=>{});

                    }else {
                        this.setState(
                            {
                                modalLoder:false,
                                Success:false,
                                successString:"",
                                error:true,
                                errorString:"Either Customer Not created in DB or in Traccar or both,please check"
                            },()=>{
                                this.loadCustomers();
                            });
                    }
                });

        }else {
            this.setState({error:true,errorString:"Please Input Required Fields"});
        }


    }


    render() {


        return (
            <React.Fragment>
                <button type="button" className="btn btn-default btn-sm btn-block" onClick={this.handleShow}>New Customer</button>
                <ModalComponent
                    title="Create New Customer"
                    handleShow={this.handleShow}
                    show={this.state.showModal}
                    handleClose={this.handleClose}
                    save={this.createUser}
                    loader={this.state.modalLoder}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    <form>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="fname">First Name</label>
                                    <input type="text" className="form-control" id="fname" autoComplete="off"
                                           value={this.state.fname} onChange={(e)=>this.setState({fname:e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label htmlFor="lname">Last Name</label>
                                    <input type="text" className="form-control" id="lname" autoComplete="off"
                                           value={this.state.lname} onChange={(e)=>this.setState({lname:e.target.value})}/>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="form-group">
                                    <label htmlFor="phone1">Primary Phone</label>
                                    <input type="text" className="form-control" id="phone1" autoComplete="off"
                                           value={this.state.phone1} onChange={(e)=>this.setState({phone1:e.target.value})}/>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="form-group">
                                    <label htmlFor="phone2">Secondary Phone</label>
                                    <input type="text" className="form-control" id="phone2" autoComplete="off"
                                           value={this.state.phone2} onChange={(e)=>this.setState({phone2:e.target.value})}/>
                                </div>
                            </div>
                        </div>
                    </form>
                </ModalComponent>

            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {}
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        { createCustomerAction,getCustomersAction}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCustomerComponent);
