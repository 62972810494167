import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {getAllSubscriptionLogs} from "../../actions/subscription/getAllSubscriptionLogsAction";
import {SubscriptionDataInterface} from "../../dataInterface/subscriptionDataInterface";
import ReactPaginate from "react-paginate";
import moment from "moment";


interface internalState {
    loading:boolean,
    Success:boolean,
    error:boolean,
    errorString:string,
    successString:string,
    take:number,
    skip:number,
    name:string,
    startDate:string,
    endDate:string,
    receiptNo:string

}

interface parentProps {
}

interface externalStateProps {
    allSubscriptionLogs:[Array<SubscriptionDataInterface>,number]
}


interface DispatchProps {
    getAllSubscriptionLogs:(data:object,callback:(error:boolean)=>void)=>void
}

type Props = externalStateProps & DispatchProps & parentProps;

class SubscriptionDashboardComponent extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading:false,
            Success:false,
            error:false,
            errorString:"",
            successString:"",
            take:15,
            skip:0,
            name:"",
            startDate:"",
            endDate:"",
            receiptNo:""
        };

        this.paginate=this.paginate.bind(this);
        this.nextPage=this.nextPage.bind(this);
        this.previousPage=this.previousPage.bind(this);
        this.getAllSubscriptionLogs=this.getAllSubscriptionLogs.bind(this);
        this.onchangeSearch=this.onchangeSearch.bind(this);
        this.clear=this.clear.bind(this);
    }

    componentDidMount(): void {
        this.getAllSubscriptionLogs();
    }


    getAllSubscriptionLogs(){
        this.setState({
            loading:true
        });
        this.props.getAllSubscriptionLogs({
            skip:this.state.skip,
            take:this.state.take,
            name:this.state.name,
            startDate:this.state.startDate,
            endDate:this.state.endDate,
            receiptNo:this.state.receiptNo
        },(error)=>{
            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Server Error Detected",
                    successString:"",
                    Success:false,
                });
            }else {
                this.setState({
                    loading:false,
                });
            }
        });
    }

    paginate(data:{selected:number}){
        console.log(data);
        this.setState({
            skip: this.state.take * data.selected
        },()=>{
            this.getAllSubscriptionLogs();
        })
    }
    nextPage(){
        this.setState({
            skip:this.state.skip+10,
        },()=>{
            this.getAllSubscriptionLogs();

        });
    }

    previousPage(){
        this.setState({
            skip:this.state.skip-10,
        },()=>{
            this.getAllSubscriptionLogs();
        });
    }

    onchangeSearch(e:React.ChangeEvent<HTMLInputElement>){
        this.setState({name:e.target.value},);

    }

    clear(e:React.MouseEvent|null=null){

        this.setState({
            skip:0,
            take:15,
            name:"",
            startDate:"",
            endDate:"",
            receiptNo:""
        },()=>{
            this.getAllSubscriptionLogs();
        })
    }

    render() {

        const logs=()=>{

            if(this.props.allSubscriptionLogs!==null){
                return this.props.allSubscriptionLogs[0].map((value,index)=>{

                    return (
                        <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{value.details?.customer?.fname + " " + value.details?.customer?.lname}</td>
                            <td>{value.details?.plateNumber}</td>
                            <td>{value.name}</td>
                            <td>{value.details?.deviceType?.name}</td>
                            <td>{value.modeOfPayment}</td>
                            <td>{value.receiptNo}</td>
                            <td>{value.paymentPhoneNo}</td>
                            <td>{moment(value.createdAt).format('YYYY-MM-DD h:mm:ss a')}</td>
                            <td>{value.amountPaid}</td>
                        </tr>
                    );
                });

            }
        };



        return (
            <React.Fragment>
                <div className="legend">
                    <div className="col-md-12">
                        <div className="col-auto">
                            <div className="input-group mb-4">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Receipt</div>
                                </div>
                                <input type="text" className="form-control" placeholder="Receipt No"  value={this.state.receiptNo}  onChange={(e)=>this.setState({receiptNo:e.target.value})}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mb-3">
                        <form>
                            <div className="row">
                                <div className="col">
                                    <label>Start Date</label>
                                    <input type="datetime-local" className="form-control" placeholder="Start Date" value={this.state.startDate}  onChange={(e)=>this.setState({startDate:e.target.value})}/>
                                </div>
                                <div className="col">
                                    <label>End Date</label>
                                    <input type="datetime-local" className="form-control" placeholder="End Date" value={this.state.endDate}  onChange={(e)=>this.setState({endDate:e.target.value})}/>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-12 mb-4">
                        <div className="col-auto">
                            <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Customer</div>
                                </div>
                                <input type="text" className="form-control" placeholder="Name"  value={this.state.name}  onChange={this.onchangeSearch}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mb-5">
                      <div className="row">
                          <div className="col-md-10">
                              <button className="btn btn-primary btn-block" type="button" onClick={this.getAllSubscriptionLogs}>Filter</button>
                          </div>
                          <div className="col-md-2">
                              <button className="btn btn-success btn-block" type="button" onClick={this.clear}>Clear</button>
                          </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Customer</th>
                                <th scope="col">Plate Number</th>
                                <th scope="col">Bundle</th>
                                <th scope="col">Type</th>
                                <th scope="col">Network</th>
                                <th scope="col">Receipt</th>
                                <th scope="col">Payment Phone</th>
                                <th scope="col">Date</th>
                                <th scope="col">Paid Amount</th>

                            </tr>
                            </thead>
                            <tbody>
                            {logs()}
                            </tbody>
                        </table>

                    </div>
                </div>
                <ReactPaginate
                    containerClassName="pagination"
                    breakClassName="page-item"
                    breakLabel={<a className="page-link">...</a>}
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    pageCount={this.props.allSubscriptionLogs?this.props.allSubscriptionLogs[1]/this.state.take:0}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    onPageChange={this.paginate}
                />

            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        allSubscriptionLogs:state.allSubscriptionLogs
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {getAllSubscriptionLogs}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionDashboardComponent);
