



let serverRoutes={

    getAllUsers: "/api/users/all",
    getMechanics:"/api/users/mechanics",
    searchUsers: "/api/users/search",
    createUser:"/api/users/create",
    assignRoleToUser:"/api/users/role",
    getAllRoles: "/api/groups/all",
    login: "/api/auth/login",
    deleteMyAccount: "/api/customer/account/delete",
    searchDevice:"/api/device/search",
    postDeviceVersion:"/api/device/version",
    searchDeviceWithoutDetails:"/api/devices/q/search",
    searchMyDevices:"/api/device/search/mine",
    deviceSmsInit:"/api/device/sms/init",
    createCustomer:"/api/customer/create",
    editCustomer:"/api/customer",
    getCustomers:"/api/customer",
    reRegister:"/api/customer/re-register",
    register:"/api/customer/traccar/register/all",
    uregisterInTraccar:"/api/device/traccar/delete",
    getAllRolesPageable:"/api/groups/all/pageable",
    getRoles:"/api/groups/all",
    getRolePermission:"/api/permission/group",
    createRole:"/api/groups/create",
    getPermissions:"/api/permission",
    assignRolePermissions:"/api/groups/permissions",
    insertPhoneToDevice:"/api/device/phone",
    updateDevicePhone:"/api/device/update/phone",
    updatePlateNumber:"/api/device/update/platenumber",
    assignDeviceToCustomer:"/api/customer/device",
    assignDeviceToUser:"/api/users/device",
    searchCustomer:"/api/customer/search",
    newDevice:"/api/device/register",
    registerToTraccar:"/api/device/traccar",
    getAllDevices:"/api/device",
    getAllAdminNotSetDevices:"/api/device/isadmin",
    getAllDevicesAttention:"/api/device/attention",
    details:"/api/details",
    sendSmsCommand:"/api/device/sms/command",
    passwordCommand:"/api/command/password",
    accCommand:"/api/command/acc",
    reboot:"/api/command/reboot",
    smsinstructions:"/api/command/customer/sms",
    adminCommand:"/api/command/admin",
    addCustomAdmin:"/api/command/custom/admin",
    apnCommand:"/api/command/apn",
    domainCommand:"/api/command/domain",
    responseTimeCommand:"/api/command/responsetime",
    timeZone:"/api/command/timezone",
    smsToCustomer:"/api/command/customer",
    checkCommand:"/api/command/check",
    getAllDealers:"/api/dealer/all",
    getAllDealersNonPageable:"/api/dealer/all/non-pageable",
    searchDealers:"/api/dealer/search",
    getDealerDevices:"/api/dealer/devices",
    createDealer:"/api/dealer",
    getDealerById:"/api/dealer/single",
    dealerDevice:"/api/dealer/device",
    dealerDeviceShift:"/api/dealer/device/shift",
    assiDeviceToDealer:"/api/dealer/assign/device",
    devicePhones:"/api/device/admin/phones",
    removeCustomerNumberSmsCommand:"/api/command/admin/remove",
    resetCommand:"/api/command/reset",
    stopCommand:"/api/command/stop",
    resumeCommand:"/api/command/resume",
    instructionSmsToUser:"/api/command/user/sms",
    smsToMechanic:"/api/command/mechanic/sms",
    messages:"/api/message/",
    createMessages:"/api/message/create",
    testMessages:"/api/message/test",
    approveMessages:"/api/message/approve",
    smsToDealer:"/api/command/dealer/sms",
    disableDevice:"/api/device/disable",
    enableDevice:"/api/device/enable",
    salesData:"/apidevices/sales",
    swapDevice:"/api/device/swap",
    outOfService:"/api/device/offservice",
    uploadNewSims:"/api/sim/upload",
    updateSims:"/api/sim/update",
    getSims:"/api/sim/allSims",
    downloadForPay:"/api/sim/download",
    searchSims:"/api/sim/search",
    subtype:"/api/devicetype/",
    subLogs:"/api/subscription/device",
    allLogs:"/api/logs/subscriptions/all",
    getsubtypePageable:"/api/devicetype/all/pageable",
    getsubtype:"/api/devicetype/all/",
    upDategetsubtype:"/api/devicetype/update",
    updateSubscription:"/api/device/update/subscription",
    typeSettings:"/api/usersettingstype",
    storeUserSettings:"/api/usersettings/create",
    getUserSettingsByTypeByUser:"/api/usersettings/user-settings",
    createBundle:"/api/bundle/create",
    getActiveBundle:"/api/bundle/active",
    getAllBundle:"/api/bundle",
    setBundleActiveInactive:"/api/bundle/activate",
    getDeviceReportsRequests:"/api/device/reports/requests",
    activateBundleToDevice:"/api/bundle/assign",
    assignDeviceToMechanic:"/api/mechanic/assign/device",
    createMechanic:"/api/mechanic/register",
    editMechanic:"/api/mechanic/edit",
    mechanicsPageable:"/api/mechanic/all/pageable",
    allMechanics:"/api/mechanic/all",
    getMechanic:"/api/mechanic/",
    reconciliation:"/api/device/reconciliation",
    getMechanicsReport:"/api/report/mechanics/devices",
    getMechanicsReportByDateRange:"/api/report/mechanics/devices/dates",
    getDeviceSalesMonthly:"api/report/devices/sales/monthly",
    getDeviceSalesDaily:"api/report/devices/sales/daily",
    getDeviceSubscriptionLogs:"/api/logs/subscription/device",
    getRecentPaidSimCardByDateRange:"/api/logs/subscriptions/by-dat",
    createDebt:"/api/debt/add",
    getDebtItemsByDebtId:"/api/debt/get/debt-items/debt-id",
    getDebtItemsByDetailId:"/api/debt/get/debt-items/detail-id",
    getAllDebts:"/api/debt/get/all",
    lockDebt:"/api/debt/lock",
    getSubscriptionSalesMonthly:"/api/report/subscription/sales/monthly",
    getDeviceSalesMoneyReport:"/api/report/devices/amount/sales/monthly",
    setCategory:"/api/device/set-category"


};



export{serverRoutes};
