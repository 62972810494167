import {HTTP} from "../../helpers/axios";
// src/thunks.ts
import {AxiosResponse} from "axios";
import { Action } from 'redux'
import {RootState} from '../../reducers'
import {ThunkAction} from "redux-thunk";


export const authUserPermissionsAction = (data:Array<String>):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {

    try {


        dispatch({
            type: 'AUTH_USER_PERMISSIONS',
            payload: data
        });


    }catch (e) {
        console.log(e);
    }


};

