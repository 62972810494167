import React from "react";
import Loader from 'react-loaders'
import "./loader.scss"

interface internalState {
}

interface parentProps {
    loading:boolean
}


type Props = parentProps;

class LoaderComponent extends React.Component<Props, internalState> {


    render() {
        return (
            <Loader type="line-scale"  active={this.props.loading}/>
        );
    }
}


export default LoaderComponent;