import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import ReactPaginate from "react-paginate";
import {UsersDataInterface} from "../../dataInterface/UsersDataInterface";
import ModalComponent from "../Modal/ModalComponent";
import fetchUsersAction from "../../actions/users/fetchUsersAction";
import {createUserAction} from "../../actions/users/createUserAction";
import {assignRoleToUserAction} from "../../actions/users/assignRoleToUserAction";
import {fetchRolesPageableAction} from "../../actions/role/fetchRolesPageableAction";
import {getAllRolesAction} from "../../actions/role/getAllRolesAction";
import {roleDataTypeInterface} from "../../dataInterface/roleDataTypeInterface";
import {getTypeSettingsAction} from "../../actions/settings/getTypeSettingsAction";
import {UserSettingsTypeDataInterface} from "../../dataInterface/userSettingsTypeDataInterface";
import {storeUserSettingsAction} from "../../actions/settings/storeUserSettingsAction";
import {getUserSettingsByTypeByUser} from "../../actions/settings/getUserSettingsByTypeByUserAction"
import {clearUserSettingsByTypeByUser} from "../../actions/settings/clearUserTypeSettingsAction";
import {UsersSettingsDataInterface} from "../../dataInterface/userSettingsDataInterface";

interface internalState {
    loading:boolean,
    error:boolean,
    errorString:string,
    Success:boolean,
    successString:string,
    showModal:boolean,
    showModal2:boolean,
    showModal3:boolean,
    modalLoder:boolean,
    fname:string,
    lname:string,
    isMechanic:boolean,
    username:string,
    password:string,
    take:number,
    skip:number,
    phone:string,
    selectedUser:UsersDataInterface|null,
    selectedRoles:Array<number>,
    type:string,
    settingId:number,
    settingsUsername:string,
    settingsPassword:string,
    sourceIdn:string,
    destinationIdn:string,
    pushBulletkey:string,
}

interface parentProps {
}

interface externalStateProps {
    users:[Array<UsersDataInterface>,number],
    roles:Array<roleDataTypeInterface>,
    settingsType:Array<UserSettingsTypeDataInterface>,
    usersSettingsByTypeByUser:UsersSettingsDataInterface

}


interface DispatchProps {
    fetchUsersAction:(data:object,callBack:(error:boolean)=>void)=>void,
    createUserAction:(user:object,callBack:(error:boolean)=>void)=>void,
    assignRoleToUserAction:(data:object,callBack:(error:boolean)=>void)=>void,
    getAllRolesAction:(data:object,callback:(error:boolean)=>void)=>void,
    getTypeSettingsAction:(callback:(error:boolean)=>void)=>void,
    storeUserSettingsAction:(data:object,callback:(error:boolean)=>void)=>void,
    getUserSettingsByTypeByUser:(data:object,callback:(error:boolean,data:UsersSettingsDataInterface)=>void)=>void,
    clearUserSettingsByTypeByUser:()=>void
}

type Props = externalStateProps & DispatchProps & parentProps;

class CreateUsersDashboard extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            modalLoder: false,
            error: false,
            errorString: "",
            Success: false,
            successString: "",
            showModal: false,
            showModal2: false,
            showModal3: false,
            fname: "",
            lname: "",
            isMechanic: false,
            username: "",
            password: "",
            take: 6,
            skip: 0,
            phone: "",
            selectedUser: null,
            selectedRoles: [],
            type:"",
            settingId:this.props.usersSettingsByTypeByUser?.id,
            settingsPassword:"",
            settingsUsername:"",
            sourceIdn:"",
            destinationIdn:"",
            pushBulletkey:""

        };

        this.handleShow=this.handleShow.bind(this);
        this.handleShow2=this.handleShow2.bind(this);
        this.handleShow3=this.handleShow3.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.handleClose2=this.handleClose2.bind(this);
        this.handleClose3=this.handleClose3.bind(this);
        this.createUser=this.createUser.bind(this);
        this.paginate=this.paginate.bind(this);
        this.nextPage=this.nextPage.bind(this);
        this.previousPage=this.previousPage.bind(this);
        this.isUserInRole=this.isUserInRole.bind(this);
        this.addRemoveRole=this.addRemoveRole.bind(this);
        this.assignRole=this.assignRole.bind(this);
        this.getTypesSettings=this.getTypesSettings.bind(this);
        this.storeUserSettings=this.storeUserSettings.bind(this);
        this.getUserSettingsByTypes=this.getUserSettingsByTypes.bind(this);
    }

    componentDidMount(): void {
        this.getAllUsers();
        this.getAllRoles();
        this.getTypesSettings();
    }

    // static getDerivedStateFromProps(props:Props, state:internalState){
    //     if(props.usersSettingsByTypeByUser && props.usersSettingsByTypeByUser.id!==state.settingId){
    //         return {
    //             settingId:props.usersSettingsByTypeByUser.id,
    //             settingsPassword:props.usersSettingsByTypeByUser.passWord,
    //             settingsUsername:props.usersSettingsByTypeByUser.userName,
    //             sourceIdn:props.usersSettingsByTypeByUser.sourceIden,
    //             destinationIdn:props.usersSettingsByTypeByUser.deviceIden,
    //             pushBulletkey:props.usersSettingsByTypeByUser.pushBulletKey
    //         };
    //     }
    //
    //     return null;
    // }

    getUserSettingsByTypes(e:React.ChangeEvent<HTMLSelectElement>){
        if(this.state.selectedUser){
            this.setState({
                type:e.target.value,
                modalLoder:true,
            },()=>{
                console.log(88888888888888888);
                console.log(this.state.type)
                this.props.getUserSettingsByTypeByUser({userId:this.state.selectedUser?.id,type:this.state.type},(error,data)=>{

                    if(error){
                        this.setState({
                            modalLoder:false,
                            error:true,
                            errorString:"Failed to Get Settings",
                            successString:"",
                            Success:false,
                        });

                    }else {
                        if(data && Object.keys(data).length !== 0 && data.constructor === Object){
                            this.setState({
                                modalLoder:false,
                                type: e.target.value,
                                settingsPassword: data.passWord,
                                settingsUsername: data.userName,
                                sourceIdn: data.sourceIden,
                                destinationIdn: data.deviceIden,
                                pushBulletkey: data.pushBulletKey,
                            });
                        }else {
                            this.setState({
                                modalLoder:false,
                                settingsUsername:"",
                                settingsPassword:"",
                                sourceIdn:"",
                                destinationIdn:"",
                                pushBulletkey:""
                            })
                        }

                    }
                })

            })
        }
    }

    storeUserSettings(){

        if(this.state.type!==""){
            this.props.storeUserSettingsAction({
                settingsPassword:this.state.settingsPassword,
                settingsUsername:this.state.settingsUsername,
                sourceIdn:this.state.sourceIdn,
                destinationIdn:this.state.destinationIdn,
                pushBulletkey:this.state.pushBulletkey,
                userId:this.state.selectedUser?.id,
                type:this.state.type
            },(error)=>{

                if(error){
                    this.setState({
                        loading:false,
                        error:true,
                        errorString:"Failed to store User Settings",
                        successString:"",
                        Success:false,
                    });
                }else {
                    this.setState({
                        loading:false,
                        error:false,
                        errorString:"",
                        successString:"User Settings Stored",
                        Success:true,
                    });
                }
            });

        }else {
            this.setState({
                loading:false,
                error:true,
                errorString:"Please Select Settings Type",
                successString:"",
                Success:false,
            });
        }
    }

    getTypesSettings(){

        this.props.getTypeSettingsAction((error => {

            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Server Error Detected in Loading Type Settings",
                    successString:"",
                    Success:false,
                });
            }
        }))
    }

    getAllRoles(){
        this.setState({loading:true});
        this.props.getAllRolesAction({take:this.state.take,skip:this.state.skip,pageable:true},(error)=>{
            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Server Error Detected in Loading Roles",
                    successString:"",
                    Success:false,
                });
            }

        });
    }

    assignRole(){

        this.props.assignRoleToUserAction({userId:this.state.selectedUser?.id,roleIds:this.state.selectedRoles},(error => {

            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Server Error Detected",
                    successString:"",
                    Success:false,
                });
            }else {
                this.setState({
                    loading:false,
                    error:false,
                    errorString:"",
                    successString:"Roles Updated",
                    Success:true,
                });
            }
        }))
    }


    handleShow(e:React.MouseEvent<HTMLButtonElement>){
        e.preventDefault();
        this.setState({
            showModal:true,
            errorString:"",
            error:false,
            successString:"",
            Success:false
        })
    }

    handleShow2(e:React.MouseEvent<HTMLButtonElement>,user:UsersDataInterface|null=null){
        e.preventDefault();
        this.setState({
            showModal2:true,
            errorString:"",
            error:false,
            successString:"",
            Success:false
        });
        if(user){
            this.setState({
                selectedUser:user
            },()=>{

                let userRoles:Array<number>=[];
                this.props.roles.map((role,index)=>{

                    //check if this selected user is in requested roles
                    let dIndex=this.isUserInRole(role.users);

                    if(dIndex!==-1){
                        userRoles.push(role.id);
                    }

                    this.setState({
                        selectedRoles:userRoles
                    })
                })
            })
        }
    }

    handleShow3(e:React.MouseEvent<HTMLButtonElement>,user:UsersDataInterface|null=null){
        e.preventDefault();
        this.setState({
            showModal3:true,
            errorString:"",
            error:false,
            successString:"",
            Success:false
        });

        if(user){
            this.setState({
                selectedUser:user
            });
        }
        this.props.clearUserSettingsByTypeByUser();

    }

    isUserInRole(userRoles:Array<UsersDataInterface>|undefined){

        if(userRoles!==undefined && this.state.selectedUser!==null){
            return userRoles.findIndex((data,index)=>{
                // @ts-ignore
                return data.id===this.state.selectedUser.id
            })
        }else {
            return -1;
        }
    }

    isRoleSelected(roleId:number){
        return this.state.selectedRoles.findIndex((id,index)=>{

            return roleId===id;
        });
    }
    addRemoveRole(e:React.ChangeEvent<HTMLInputElement>,role:roleDataTypeInterface){

        let index=this.isRoleSelected(role.id);
        let newSelectedRole=this.state.selectedRoles;

        if(index==-1 && this.state.selectedUser){
            newSelectedRole.push(role.id);
            this.setState({
                selectedRoles:newSelectedRole
            });
        }else {
            newSelectedRole.splice(index,1);
            this.setState({
                selectedRoles:newSelectedRole
            });
        }

    }
    handleClose(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal:false,
            error:false,
            errorString:"",
            Success:false,
            successString:"",
        })
    }

    handleClose2(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal2:false,
            error:false,
            errorString:"",
            Success:false,
            successString:"",
        })
    }
    handleClose3(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal3:false,
            error:false,
            errorString:"",
            Success:false,
            successString:"",
            type:"",
            settingsPassword:"",
            settingsUsername:"",
            sourceIdn:"",
            destinationIdn:"",
            pushBulletkey:""
        })
    }

    getAllUsers(){
        this.setState({modalLoder:true});
        this.props.fetchUsersAction({take:this.state.take,skip:this.state.skip},(error)=>{

            if(error){
                this.setState({
                    modalLoder:false,
                    error:true,
                    errorString:"Failed to load Users",
                    successString:"",
                    Success:false,
                });
            }else {
                this.setState({
                    modalLoder:false,
                    error:false,
                    errorString:"",
                    successString:"",
                    Success:false
                })
            }
        })
    }


    paginate(data:{selected:number}){
        this.setState({
            skip: this.state.take * data.selected
        },()=>{
            this.getAllUsers();
        })
    }
    nextPage(){
        this.setState({
            skip:this.state.skip+10,
        },()=>{
            this.getAllUsers();

        });
    }

    previousPage(){
        this.setState({
            skip:this.state.skip-10,
        },()=>{
            this.getAllUsers();
        });
    }

    createUser(e:React.MouseEvent){
        e.preventDefault();

        if(this.state.fname!="" && this.state.lname!="" && this.state.username!="" && this.state.password!=="" && this.state.phone!==""){
            this.setState({modalLoder:true,error:false});
            this.props.createUserAction(
                {
                    fname:this.state.fname,
                    lname:this.state.lname,
                    username:this.state.username,
                    password:this.state.password,
                    isMechanic:this.state.isMechanic,
                    phone:this.state.phone,
                },
                (error)=>{

                    if(error){
                        this.setState({
                            modalLoder:false,
                            Success:false,
                            successString:"",
                            error:true,
                            errorString:"Server Error Detected",
                        });
                    }else {
                        this.setState({
                            modalLoder:false,
                            fname:"",
                            lname:"",
                            username:"",
                            password:"",
                            Success:true,
                            successString:"User Created Successfully",
                            isMechanic:false
                        });
                        this.getAllUsers();
                    }

                });

        }else {
            this.setState({error:true,errorString:"Please Input Required Fields",Success:false,successString:""});
        }


    }


    render() {

        let settingsType=()=>{
            if(this.props.settingsType){

                return this.props.settingsType.map((type,index)=>{

                    return(
                        <option value={type.id}>{type.typeName}</option>
                    )
                })
            }else {
                return <React.Fragment/>
            }
        };

        let roles=()=>{

            if(this.props.roles){
                return this.props.roles.map((role,index)=>{

                    let indexUser=-1;
                    if(role.users)
                     indexUser=role.users.findIndex((user,index)=>{
                        return user.id===this.state.selectedUser?.id;
                    });

                    return(
                        <tr>
                            <th scope="row">{index+1}</th>
                            <td><input className="form-check-input" type="checkbox" value="" id="check" checked={this.isRoleSelected(role.id)!==-1} onChange={(e)=>this.addRemoveRole(e,role)}/></td>
                            <td>{role.name.toUpperCase()}</td>
                            <td>{role.codeName.toUpperCase()}</td>
                        </tr>
                    )
                })

            }
        };

        let users=()=>{
            if(this.props.users){
                return this.props.users[0].map((user,index)=>{

                    return (
                        <React.Fragment>
                            <tr>
                                <td>{index+1}</td>
                                <td>{user.fname}</td>
                                <td>{user.lname}</td>
                                <td>
                                    <button className="btn btn-default" onClick={(e)=>this.handleShow2(e,user)}>Roles</button>
                                </td>
                                <td>
                                    <button className="btn btn-default" onClick={(e)=>this.handleShow3(e,user)}>Settings</button>
                                </td>
                            </tr>
                        </React.Fragment>
                    )
                })
            }else {
                return <React.Fragment/>
            }
        };

        return (
            <React.Fragment>
                <div className="legend">
                    <h6 className="legend-title">
                        Users
                    </h6>
                    <div className="row div-table-row">
                        <div className="col-md-2 div-table div-table-title" >
                            Create Users
                        </div>
                        <div className="col-md-3 div-table div-table-value" >
                            <button className="btn btn-default" onClick={(e)=>this.handleShow(e)}>Create Users</button>
                        </div>
                        <div className="col-md-2 div-table div-table-title">
                            Upload
                        </div>
                        <div className="col-md-5 div-table div-table-value">
                        </div>
                    </div>
                </div>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Roles</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users()}
                    </tbody>
                </table>
                <ModalComponent
                    title="Create New User"
                    handleShow={this.handleShow}
                    show={this.state.showModal}
                    handleClose={this.handleClose}
                    save={this.createUser}
                    loader={this.state.modalLoder}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    <form>
                        <div className="form-row">
                            <div className="form-group col-md-5">
                                <label htmlFor="fname">First name</label>
                                <input type="text" className="form-control" id="fname" aria-describedby="fname"  value={this.state.fname} onChange={(e)=>this.setState({fname:e.target.value})}/>
                            </div>
                            <div className="form-group col-md-7">
                                <label htmlFor="lname">Last Name</label>
                                <input type="text" className="form-control" id="lname" value={this.state.lname} onChange={(e)=>this.setState({lname:e.target.value})}/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-8">
                                <label htmlFor="username">Username</label>
                                <input type="text" className="form-control" id="username" value={this.state.username} onChange={(e)=>this.setState({username:e.target.value})}/>
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="pass">Password</label>
                                <input type="password" className="form-control" id="pass" value={this.state.password} onChange={(e)=>this.setState({password:e.target.value})}/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="username">Phone Number</label>
                                <input type="text" className="form-control" id="username" value={this.state.phone} onChange={(e)=>this.setState({phone:e.target.value})}/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="mech">Is Mechanic</label>
                                <select className="form-control" id="mech" onChange={(e)=>this.setState({isMechanic:Boolean(e.target.value)})}>
                                    <option value="false">False</option>
                                    <option value="true">True</option>
                                </select>
                            </div>
                        </div>
                    </form>
                </ModalComponent>
                <ModalComponent
                    title="Assign Role To User"
                    handleShow={this.handleShow2}
                    show={this.state.showModal2}
                    handleClose={this.handleClose2}
                    save={this.assignRole}
                    loader={this.state.modalLoder}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Role Name</th>
                            <th scope="col">Code Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        {roles()}
                        </tbody>
                    </table>
                </ModalComponent>
                <ModalComponent
                    title="User Settings"
                    handleShow={this.handleShow3}
                    show={this.state.showModal3}
                    handleClose={this.handleClose3}
                    save={this.storeUserSettings}
                    loader={this.state.modalLoder}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    <div className="row">
                        <div className="col-md-12">
                            <select className="custom-select" onChange={(e)=>this.getUserSettingsByTypes(e)}>
                                <option value="">Open this select menu</option>
                                {settingsType()}
                            </select>
                        </div>
                        <div className="col-md-12 mt-3">
                            <div className="form-row">
                                <div className="col-md-7 mb-3">
                                    <input type="text" className="form-control" value={this.state.settingsUsername} onChange={(e)=>this.setState({settingsUsername:e.target.value})} placeholder="Username"/>
                                </div>
                                <div className="col-md-5 mb-3">
                                    <input type="text" className="form-control"  placeholder="Password" value={this.state.settingsPassword} onChange={(e)=>this.setState({settingsPassword:e.target.value})}/>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-5 mb-3">
                                    <input type="text" className="form-control" placeholder="Source Iden" value={this.state.sourceIdn} onChange={(e)=>this.setState({sourceIdn:e.target.value})}/>
                                </div>
                                <div className="col-md-7 mb-3">
                                    <input type="text" className="form-control" placeholder="Destination Iden" value={this.state.destinationIdn} onChange={(e)=>this.setState({destinationIdn:e.target.value})}/>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-5 mb-3">
                                    <input type="text" className="form-control" placeholder="Pushbullet Key" value={this.state.pushBulletkey} onChange={(e)=>this.setState({pushBulletkey:e.target.value})}/>
                                </div>
                            </div>
                        </div>
                    </div>

                </ModalComponent>

                <ReactPaginate
                    containerClassName="pagination"
                    breakClassName="page-item"
                    breakLabel={<a className="page-link">...</a>}
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    pageCount={this.props.users?this.props.users[1]/this.state.take:0}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    onPageChange={this.paginate}
                />
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        users:state.getUsers,
        roles:state.allRoles,
        settingsType:state.settingsType,
        usersSettingsByTypeByUser:state.usersSettingsByTypeByUser
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {
            storeUserSettingsAction,
            getAllRolesAction,
            createUserAction,
            fetchUsersAction,
            assignRoleToUserAction,
            getTypeSettingsAction,
            getUserSettingsByTypeByUser,
            clearUserSettingsByTypeByUser
        }, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUsersDashboard);
