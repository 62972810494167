import {roleDataTypeInterface} from "../../dataInterface/roleDataTypeInterface";


interface fetchRolesReducer {
    type:string
    payload:roleDataTypeInterface
}

export default (state = null, action:fetchRolesReducer) => {
    switch (action.type) {
        case "GET_ROLES":
            return action.payload;
        default:
            return state;
    }
};