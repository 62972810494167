import axios,{AxiosResponse} from "axios";

import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import {serverRoutes} from "../../api/serverRoutes";
import {loginDatatype} from "../../dataInterface/loginTypes";
import {userResponseData} from "../../dataInterface/UsersDataInterface";
import {server} from "../../api/host";


const loginAction = (authData:{username:string,password:string},callback:()=>void):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {


    interface authData extends  AxiosResponse,loginDatatype{}

    try {
        const response:AxiosResponse = await axios.post(server.host + serverRoutes.login,authData);

        let userData=response.data.userData as Array<userResponseData>;

        dispatch({
            type: 'AUTHENTICATION',
            payload: {
                isAuthenticated:true,
                token:response.data.token
            }
        });

        dispatch({
            type: 'USER_DATA',
            payload: response.data.userData
        });
        callback();

    }catch (e) {
        dispatch({
            type: 'AUTHENTICATION',
            payload: {
                isAuthenticated:false,
                token:undefined
            }
        });
        callback();
    }


};
export default loginAction;


