import {HTTP} from "../../helpers/axios";
// src/thunks.ts
import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import {CustomerDataInterface} from "../../dataInterface/CustomerDataInterface";


export const selectedCustomerAction = (customer:CustomerDataInterface|null):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {

    dispatch({
        type: 'SELECTED_CUSTOMER',
        payload: customer
    });

};
