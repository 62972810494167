import {
    devicesSalesInMoneyReportMonthlyInterface
} from "../../dataInterface/deviceSalesReportMonthlyInterface";
import {DebtDataInterface} from "../../dataInterface/debtDataInterface";
import {DebtItemsDatainterface} from "../../dataInterface/debtItemsDatainterface";


interface debtItems {
    type:string
    payload:[Array<DebtItemsDatainterface>,number]
}

export default (state = null, action:debtItems) => {
    switch (action.type) {
        case "DEBT_ITEMS_NON_PAGEABLE":
            return action.payload;
        default:
            return state;
    }
};
