import {bundleDataInterface} from "../../dataInterface/bundleDataInterface";


interface activeBundleReducer {
    type:string
    payload:bundleDataInterface
}

export default (state = null, action:activeBundleReducer) => {
    switch (action.type) {
        case "ACTIVE_BUNDLES":
            return action.payload;
        default:
            return state;
    }
};
