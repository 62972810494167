import {deviceData} from "../../dataInterface/deviceDetailInterface";
import {DeviceTypeDataInterface} from "../../dataInterface/deviceTypeDataInterface";


interface selectedDeviceType {
    type:string
    payload:DeviceTypeDataInterface
}

export default (state = null, action:selectedDeviceType) => {
    switch (action.type) {
        case "SELECTED_DEVICE_TYPE":
            return action.payload;
        default:
            return state;
    }
};
