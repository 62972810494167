import {CustomerDataInterface} from "../../dataInterface/CustomerDataInterface";


interface fetchUserReducer {
    type:string
    payload:Array<CustomerDataInterface>
}

export default (state = null, action:fetchUserReducer) => {
    switch (action.type) {
        case "GET_CUSTOMERS":
            return action.payload;
        default:
            return state;
    }
};