import {HTTP} from "../../helpers/axios";
// src/thunks.ts
import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import { serverRoutes} from "../../api/serverRoutes";


export const getDeviceAction = (data:object,callback:()=>void):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {

    const response =  await   HTTP(getState,dispatch).post(serverRoutes.getAllDevices,data);


    dispatch({
        type: 'ALL_DEVICES',
        payload: response.data
    });

    callback();

};
