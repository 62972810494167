import React, {ButtonHTMLAttributes} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import ModalComponent from "../Modal/ModalComponent";
import {PermissionDataInterface} from "../../dataInterface/permissionDataInterface";
import {createBundle} from "../../actions/bundle/createBundleAction";
import {activateDeactivate} from "../../actions/bundle/activateBundleAction";
import {getBundles} from "../../actions/bundle/getAllBundlesAction";
import {Link, withRouter,RouteComponentProps} from "react-router-dom";

import ReactPaginate from "react-paginate";
import {bundleDataInterface} from "../../dataInterface/bundleDataInterface";
import Notifiction from "../Notification/Notifiction";

interface internalState {
    loading:boolean,
    modalLoder:boolean,
    Success:boolean,
    error:boolean,
    errorString:string,
    successString:string,
    showModal:boolean,
    bundleName:string,
    numberOfRoutes:string,
    numberOfSummary:string,
    numberOfStops:string,
    numberOfEvents:string,
    numberOfTrips:string,
    isBasic:number,
    take:number,
    skip:number,
    price:string
}

interface parentProps {
}

interface externalStateProps {
    bundles:[Array<bundleDataInterface>,number],
    permissions:Array<PermissionDataInterface>,
}


interface DispatchProps {
    createBundle:(data:object,callback:(error:boolean)=>void)=>void,
    getBundles:(data:object,callback:(error:boolean)=>void)=>void,
    activateDeactivate:(data:object,callback:(error:boolean)=>void)=>void,
}

type Props = externalStateProps & internalState & DispatchProps & parentProps & RouteComponentProps;

class BundleDashboard extends React.Component<Props, internalState> {


    constructor(props: Props) {
        super(props);

        this.state = {
            loading:false,
            modalLoder:false,
            Success:false,
            error:false,
            errorString:"",
            successString:"",
            showModal:false,
            bundleName:"",
            numberOfRoutes:"",
            numberOfSummary:"",
            numberOfStops:"",
            numberOfEvents:"",
            numberOfTrips:"",
            isBasic:0,
            take:6,
            skip:0,
            price:""
        };

        this.handleShow=this.handleShow.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.createBundle=this.createBundle.bind(this);
        this.paginate=this.paginate.bind(this);
        this.nextPage=this.nextPage.bind(this);
        this.previousPage=this.previousPage.bind(this);
        this.setActiveInActive=this.setActiveInActive.bind(this);
    }

    componentDidMount(): void {
        this.getBundles();
    }


    getBundles(){
        this.setState({loading:true});
        this.props.getBundles({take:this.state.take,skip:this.state.skip,pageable:true},(error)=>{
            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Server Error Detected",
                    successString:"",
                    Success:false,
                });
            }

        });
    }

    setActiveInActive(bundleId:number,activeStatus:boolean){

        this.props.activateDeactivate({
            bundleId,
            take:this.state.take,
            skip:this.state.skip,
            activeStatus:!activeStatus
        },(error)=>{
            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Server Error Detected",
                    successString:"",
                    Success:false,
                });
            }else {
                this.setState({
                    loading:false,
                    error:false,
                    errorString:"",
                    successString:activeStatus? "SuccessFully Deactivated":"Successfully Activated",
                    Success:true,
                    bundleName:"",
                    numberOfRoutes:"",
                    numberOfSummary:"",
                    numberOfStops:"",
                    numberOfEvents:"",
                    numberOfTrips:"",
                },()=>{
                    this.getBundles();
                })
            }

        })
    }

    createBundle(){

        this.setState({loading:true});

        if(
            this.state.bundleName!==""
            && this.state.numberOfRoutes!==""
            && this.state.numberOfSummary!==""
            && this.state.numberOfStops!==""
            && this.state.numberOfEvents!==""
            && this.state.numberOfTrips!==""
            && this.state.price!==""
        ){
            this.props.createBundle({
                bundleName:this.state.bundleName.toUpperCase().trim(),
                numberOfRoutes:this.state.numberOfRoutes.toLowerCase().trim(),
                numberOfSummary:this.state.numberOfSummary.trim(),
                numberOfStops:this.state.numberOfStops.trim(),
                numberOfEvents:this.state.numberOfEvents.trim(),
                numberOfTrips:this.state.numberOfTrips.trim(),
                isBasic:this.state.isBasic,
                take:this.state.take,skip:this.state.skip,
                price:this.state.price.trim()
            },(error)=>{
                if(error){
                    this.setState({
                        loading:false,
                        error:true,
                        errorString:"Server Error Detected",
                        successString:"",
                        Success:false,
                    });
                }else {
                    this.setState({
                        loading:false,
                        error:false,
                        errorString:"",
                        successString:"Bundle Created",
                        Success:true,
                        bundleName:"",
                        numberOfRoutes:"",
                        numberOfSummary:"",
                        numberOfStops:"",
                        numberOfEvents:"",
                        numberOfTrips:"",
                        price:""
                    },()=>{
                        this.getBundles();
                    })
                }
            })

        }else {
            this.setState({
                loading:false,
                error:true,
                errorString:"Pleas Fill Required Fields",
                successString:"",
                Success:false,
            });
        }
    }

    handleShow(e:React.MouseEvent<HTMLButtonElement>){
        e.preventDefault();
        this.setState({
            showModal:true
        })
    }

    handleClose(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal:false
        })
    }



    paginate(data:{selected:number}){
        this.setState({
            skip: this.state.take * data.selected
        },()=>{
            this.getBundles();
        })
    }
    nextPage(){
        this.setState({
            skip:this.state.skip+10,
        },()=>{
            this.getBundles();

        });
    }

    previousPage(){
        this.setState({
            skip:this.state.skip-10,
        },()=>{
            this.getBundles();
        });
    }

    render() {

        let bundles=()=>{

            if(this.props.bundles){
                return this.props.bundles[0].map((bundle,index)=>{

                    return(
                        <tr>
                            <th scope="row">{index+1}</th>
                            <td>{bundle.bundleName.toUpperCase()}</td>
                            <td>{bundle.numberOfRoutes}</td>
                            <td>{bundle.numberOfSummary}</td>
                            <td>{bundle.numberOfStops}</td>
                            <td>{bundle.numberOfEvents}</td>
                            <td>{bundle.numberOfTrips}</td>
                            <td><a href="#" onClick={()=>this.setActiveInActive(bundle.id,bundle.active)}>{bundle.active? 'Active':'Inactive'}</a></td>
                        </tr>
                    )
                })

            }
        };

        return (
            <React.Fragment>
                <Notifiction error={this.state.error} success={this.state.Success} errorString={this.state.errorString} successString={this.state.successString}/>
                <div className="legend">
                    <h6 className="legend-title">
                        Roles
                    </h6>
                    <div className="row div-table-row">
                        <div className="col-md-4 div-table div-table-title" >
                            Create Roles
                        </div>
                        <div className="col-md-3 div-table div-table-value" >
                            <button className="btn btn-default" onClick={(e)=>this.handleShow(e)}>Create Bundle</button>
                        </div>
                        <div className="col-md-2 div-table div-table-title">
                            Upload
                        </div>
                        <div className="col-md-2 div-table div-table-value">
                        </div>
                    </div>
                </div>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Bundle Name</th>
                        <th scope="col">Number of Routes</th>
                        <th scope="col">Number of Summary</th>
                        <th scope="col">Number of Stops</th>
                        <th scope="col">Number of Events</th>
                        <th scope="col">Number of Trips</th>
                        <th scope="col">Active</th>
                    </tr>
                    </thead>
                    <tbody>
                    {bundles()}
                    </tbody>
                </table>
                <ModalComponent
                    title="Create Bundles"
                    handleShow={this.handleShow}
                    show={this.state.showModal}
                    handleClose={this.handleClose}
                    loader={this.state.modalLoder}
                    save={this.createBundle}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    <div className="row">
                        <div className="col-12">
                            <form>
                                <div className="form-group">
                                    <div className="form-row">
                                        <div className="input-group mb-2 col-md-4">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Bundle Name</div>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Name"  value={this.state.bundleName}  onChange={(e)=>this.setState({bundleName:e.target.value})}/>
                                        </div>
                                        <div className="input-group mb-2 col-md-8">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Number of Routes</div>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Routes"  value={this.state.numberOfRoutes}  onChange={(e)=>this.setState({numberOfRoutes:e.target.value})}/>
                                        </div>
                                        <div className="input-group mb-2 col-md-8">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Number of Summary</div>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Summary"  value={this.state.numberOfSummary}  onChange={(e)=>this.setState({numberOfSummary:e.target.value})}/>
                                        </div>
                                        <div className="input-group mb-2 col-md-4">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Number of Stops</div>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Stops"  value={this.state.numberOfStops}  onChange={(e)=>this.setState({numberOfStops:e.target.value})}/>
                                        </div>
                                        <div className="input-group mb-2 col-md-6">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Number of Events</div>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Events"  value={this.state.numberOfEvents}  onChange={(e)=>this.setState({numberOfEvents:e.target.value})}/>
                                        </div>

                                        <div className="input-group mb-2 col-md-6">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Number of Trips</div>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Trips"  value={this.state.numberOfTrips}  onChange={(e)=>this.setState({numberOfTrips:e.target.value})}/>
                                        </div>
                                        <div className="input-group mb-2 col-md-12">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Price</div>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Price"  value={this.state.price}  onChange={(e)=>this.setState({price:e.target.value})}/>
                                        </div>
                                        <div className="input-group mb-2 col-md-12">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Bundle Basic</div>
                                            </div>
                                            <select className="custom-select" onChange={(e)=>this.setState({isBasic:parseInt(e.target.value)})}>
                                                <option value="0">Not Basic</option>
                                                <option value="1">Basic</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ModalComponent>
                <ReactPaginate
                    containerClassName="pagination"
                    breakClassName="page-item"
                    breakLabel={<a className="page-link">...</a>}
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    pageCount={this.props.bundles?this.props.bundles[1]/this.state.take:0}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    onPageChange={this.paginate}
                />

            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        bundles:state.allBundles,
        permissions:state.getPermissions
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {
            getBundles,
            createBundle,
            activateDeactivate
        }, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(BundleDashboard);
