import {dealerDataInterface} from "../../dataInterface/dealerDataInterface";


interface getDealersReducer {
    type:string
    payload:Array<dealerDataInterface>
}

export default (state = null, action:getDealersReducer) => {
    switch (action.type) {
        case "GET_DEALERS_NON_PAGEABLE":
            return action.payload;
        default:
            return state;
    }
};
