import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import ModalComponent from "../Modal/ModalComponent";
import LoaderComponent from "../Loader/LoaderComponent";
import {assignDeviceToDealer} from "../../actions/dealer/assignDeviceToDealerAction";
import {dealerDataInterface} from "../../dataInterface/dealerDataInterface";


interface internalState {
    loading:boolean,
    error:boolean,
    errorString:string,
    Success:boolean,
    successString:string,
    showModal:boolean,
    modalLoder:boolean,
    device:string
}

interface parentProps {
}

interface externalStateProps {
    selectedDealer:dealerDataInterface
}


interface DispatchProps {
    assignDeviceToDealer:(data:object,callback:(error:boolean)=>void)=>void,

}

type Props = externalStateProps & DispatchProps & parentProps;

class DealerDetailComponent extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading:true,
            error:false,
            Success:false,
            errorString:"",
            successString:"",
            modalLoder:false,
            showModal:false,
            device:""
        };
        this.handleShow=this.handleShow.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.submit=this.submit.bind(this);
    }

    componentDidMount(): void {
    }

    handleShow(e:React.MouseEvent<HTMLButtonElement>){
        e.preventDefault();
        this.setState({
            showModal:true,
            error:false,
            errorString:"",
            Success:false,
            successString:"",
        })
    }
    handleClose(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal:false,
            error:false,
            errorString:"",
            Success:false,
            successString:"",
        })
    }

    submit(){
        if(this.state.device!==""){
            this.setState({loading:true});
            this.props.assignDeviceToDealer({device:this.state.device,dealer:this.props.selectedDealer.id},(error)=>{

                if(error){
                    this.setState({
                        loading:false,
                        error:true,
                        errorString:"Server Error Detected",
                        successString:"",
                        Success:false
                    });
                }else {
                    this.setState({
                        loading:false,
                        error:false,
                        errorString:"",
                        successString:"Device Assign To Dealer",
                        Success:true
                    });
                }
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="legend">
                    <h6 className="legend-title">
                        Dealer Details
                    </h6>
                    <div className="row div-table-row">
                        <div className="col-md-2 div-table div-table-title">
                            Devices
                        </div>
                        <div className="col-md-3 div-table div-table-value">
                            <Link to="/admin/dealer/device">
                                <button className="btn btn-default">View Devices</button>
                            </Link>
                        </div>
                        <div className="col-md-2 div-table div-table-title">
                            Assign Device
                        </div>
                        <div className="col-md-5 div-table div-table-value">
                            <div className="form-group">
                                <button className="btn btn-default mt-2" onClick={this.handleShow}>Assign</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalComponent
                    title="Assign Device"
                    show={this.state.showModal}
                    handleClose={this.handleClose}
                    loader={this.state.modalLoder}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    <div className="col-auto">
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">Device Unique Id</div>
                            </div>
                            <input type="text" className="form-control" placeholder="Name" value={this.state.device} onChange={(e)=>this.setState({device:e.target.value})}/>
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="button" onClick={this.submit}>Submit</button>
                            </div>
                        </div>
                    </div>
                </ModalComponent>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        selectedDealer:state.selectedDealer
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {assignDeviceToDealer}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(DealerDetailComponent);
