import {adminDetails} from "../../dataInterface/detailsDataInterface";


interface details {
    type:string
    payload:adminDetails
}

export default (state = null, action:details) => {
    switch (action.type) {
        case "DETAILS":
            return action.payload;
        default:
            return state;
    }
};
