import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import "./deleteData.scss";
import deleteAccountAction from "../../actions/login/deleteAccountAction";

import Joi from "@hapi/joi";
import {withRouter,RouteComponentProps} from "react-router-dom";
import {loginDatatype} from "../../dataInterface/loginTypes";
import LoaderComponent from "../Loader/LoaderComponent";
import {authUserPermissionsAction} from "../../actions/permissions/authUserPermissionsAction";


interface internalState {
    username:string,
    password:string,
    loading:boolean,
    error:boolean,
    success:boolean,
}

interface parentProps {
}

interface mapStateToprops {
    auth: { isAuthenticated:boolean, token:string|undefined }|null,
    user:loginDatatype,
    authUserPermissions:Array<string>
}


interface DispatchProps {
    deleteAccountAction:(authData:{username:string,password:string},callback:(error:boolean)=>void)=>void,

}

type Props = mapStateToprops & DispatchProps & parentProps & RouteComponentProps;


class DeleteMyAccount extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state={
            username:"",
            password:"",
            loading:false,
            error:false,
            success:false

        };
        this.submit=this.submit.bind(this);
    }

    componentDidMount(): void {
    }

    submit(e:React.MouseEvent<HTMLButtonElement>){

        e.preventDefault();
        const schema=Joi.object({
            username:Joi.string().alphanum().required(),
            password:Joi.string()
        });

        let validate=schema.validate({username:this.state.username,password:this.state.password});

        if(!validate.error){
            this.setState({loading:true,error:false})
            this.props.deleteAccountAction({username:this.state.username,password:this.state.password},(error)=>{
                this.setState({loading:false});
                if (error){
                    this.setState({error:true,success:false});
                }else {
                    this.setState({error:false,success:true,username:"",password:""});
                }
            })
        }else {
            this.setState({loading:false,error:true,success:false})
        }
    }


    render() {
        let failedError=()=>{
            if(this.state.error){
                return(
                    <span className="text-danger">Request Failed</span>
                );
            }
        };

        let success=()=>{
            if(this.state.success){
                return(
                    <span className="text-success">Request Submitted</span>
                );
            }
        };

        return (
            <React.Fragment>
                <div className="hold-transition login-page" style={{backgroundImage:'url("../../dist/img/company.png")',backgroundRepeat:'no-repeat'}}>
                    <div className="login-box" >
                        <div className="card card-outline card-primary" >
                            <div className="card-header text-center">
                                <a href="#">SERDCOM</a>
                            </div>
                            <div className="card-body">
                                <p className="login-box-msg">Fill Your Username and Password,for Account Deletion</p>
                                <div className="text-center">
                                    <LoaderComponent loading={this.state.loading}/>
                                    {failedError()}
                                    {success()}
                                </div>
                                <div>
                                    <div className="input-group mb-3">
                                        <input  onChange={(e: React.ChangeEvent<HTMLInputElement>)=>this.setState({username:e.target.value})}
                                                type="text"
                                                value={this.state.username}
                                                className="form-control"
                                                placeholder="User Name"
                                                name="username"
                                        />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-envelope"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input onChange={(e: React.ChangeEvent<HTMLInputElement>)=>this.setState({password:e.target.value})}
                                               type="password"
                                               value={this.state.password}
                                               className="form-control"
                                               placeholder="Password"
                                        />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-lock"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <button type="submit" className="btn btn-primary btn-block" onClick={this.submit}>Request</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): mapStateToprops {
    return {
        auth:state.auth,
        user:state.user,
        authUserPermissions:state.authUserPermissions
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {
            deleteAccountAction,
        }, dispatch)

}

export default withRouter<RouteComponentProps,React.ComponentType<any>>(connect(mapStateToProps, mapDispatchToProps)(DeleteMyAccount as any));
