import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import {deviceData} from "../../dataInterface/deviceDetailInterface";
import {Link, withRouter,RouteComponentProps} from "react-router-dom";
import {selectedDeviceAction} from "../../actions/device/selectedDeviceAction";
import "./scss/customerDevices.scss";
import {deviceInterface} from "../../dataInterface/deviceDataInterface";
import {CustomerDataInterface} from "../../dataInterface/CustomerDataInterface";
import {selectedCustomerAction} from "../../actions/customer/selectedCustomerAction";
import {reRegisterCustomer} from "../../actions/customer/reregisterCustomerToTraccar";

interface internalState {
    loading:boolean,
    Success:boolean,
    error:boolean,
}

interface parentProps {

}

interface externalStateProps {
    customerDevices:Array<deviceData>,
    selectedCustomer:CustomerDataInterface,

}


interface DispatchProps {
    selectedDeviceAction:(device:deviceInterface)=>void,
    selectedCustomerAction:(customer:CustomerDataInterface|null)=>void,
    reRegisterCustomer:(data:object,callback:(error:boolean)=>void)=>void,

}

type Props = externalStateProps & DispatchProps & parentProps & RouteComponentProps;

class CustomerDevices extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading:false,
            Success:false,
            error:false,
        };
        this.selectedDevice=this.selectedDevice.bind(this);
        this.register=this.register.bind(this);
    }

    componentDidMount(): void {
    }

    register(){
        this.props.reRegisterCustomer({
            customerId: this.props.selectedCustomer.id
        },(error)=>{


            if(!error){
                this.setState({
                    loading:false,
                    error:false,
                });
            }else {
                this.setState({
                    loading:false,
                    error:true,
                });
            }

        })
    }


    selectedDevice(device:deviceData){

        //restructure selected device object to what is expected
        let restructuredObjectDevice={
            createdAt:device.device.createdAt,
            id:device.device.id,
            details:{
                createdAt:device.createdAt,
                customer:device.customer,
                dealer:device.dealer,
                devicePhone:device.devicePhone,
                deviceTraccarId:device.deviceTraccarId,
                disabled:device.disabled,
                user:device.user,
                id:device.id,
                paid:device.paid,
                registerDate: device.requestedDate,
                requestedDate:device.requestedDate,
                updatedAt:device.updatedAt,
                expiry:device.expiry,
                phones:device.phones,
                mechanic:device.mechanic,
                needAttention:device.needAttention,
                plateNumber:device.plateNumber,
                notInService:device.notInService,
                isWhoPaySet:device.isWhoPaySet,
                configureDate:device.configureDate
            },
            registerDate:device.device.registerDate,
            requestedDate:device.device.requestedDate,
            unique_id:device.device.unique_id,
            updatedAt:device.device.updatedAt
        };
        // @ts-ignore
        this.props.selectedDeviceAction(restructuredObjectDevice);
        this.props.history.push("/admin/details/view/" + device.id);
    }



    render() {

        let devices=()=>this.props.customerDevices?.map((device,index)=>{
            return (
                <div className="row ml-2 mr-2 legend">
                    <h6 className="legend-title">
                        Device({index + 1})
                    </h6>
                    <button className="btn btn-success btn-sm" onClick={()=>this.selectedDevice(device)}>View</button><br/>
                    <div className="col-md-2 table-label">
                        Unique I
                    </div>
                    <div className="col-md-2 table-value">
                        {device.device.unique_id}
                    </div>
                    <div className="col-md-4 table-label">
                        Device Phone
                    </div>
                    <div className="col-md-2 table-value">
                        {device.devicePhone!==null? device.devicePhone:"Not Set"}
                    </div>
                    <div className="col-md-3 table-label mt-2">
                        Date Registered
                    </div>
                    <div className="col-md-2 table-value mt-2">
                        {device.updatedAt}
                    </div>
                    <div className="col-md-2 table-label mt-2">
                        Plate Number
                    </div>
                    <div className="col-md-3 table-value mt-2">
                        {device.plateNumber}
                    </div>
                    <div className="col-md-3 table-label mt-2">
                        Paid
                    </div>
                    <div className="col-md-2 table-value mt-2">
                        {device.paid? "Paid":"Not Paid"}
                    </div>

                    <div className="col-md-3 table-label mt-2">
                        User
                    </div>
                    <div className="col-md-2 table-value mt-2">
                        {device.user? device.user.fname + " " + device.user.lname:"No User"}
                    </div>
                </div>

            )
        });

        return (
            <React.Fragment>
                <button className="" onClick={()=>this.register()}>Reregister Customer</button>
                {devices()}
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        customerDevices:state.customerDevices,
        selectedCustomer:state.selectedCustomer,
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {selectedDeviceAction,selectedCustomerAction,reRegisterCustomer}, dispatch)

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerDevices)as any);
