import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import LoaderComponent from "../Loader/LoaderComponent";
import {deviceData} from "../../dataInterface/deviceDetailInterface";
import {withRouter, RouteComponentProps, Link} from "react-router-dom";
import {getDealersDevicesAction} from "../../actions/dealer/getDealerDevicesAction";
import Notifiction from "../Notification/Notifiction";
import {dealerDataInterface} from "../../dataInterface/dealerDataInterface";
import ModalComponent from "../Modal/ModalComponent";
import DealerComponent from "./DealerComponent";
import {shiftDeviceToAnotherDealerAction} from "../../actions/dealer/shiftDeviceToAnotherDealerAction";
import moment from "moment";


interface internalState {
    loading:boolean,
    devices:Array<deviceData>,
    error:boolean,
    errorString:string,
    Success:boolean,
    successString:string,
    showModal:boolean,
    modalLoder:boolean,
    selectedDealer:dealerDataInterface,
    selectedDevice:deviceData

}

interface parentProps {
}

interface externalStateProps {
    allDealerDevices:[Array<deviceData>,number],
    selectedDealer:dealerDataInterface
}


interface DispatchProps {
    getDealersDevicesAction:(data:object,callback:(error:boolean)=>void)=>void,
    shiftDeviceToAnotherDealerAction:(data:object,callback:(error:boolean)=>void)=>void,
}

type Props = externalStateProps & DispatchProps & parentProps & RouteComponentProps<{dealerId:string}>;

class AllDealerDevicesComponent extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading:false,
            devices:[],
            error:false,
            errorString:"",
            Success:false,
            successString:"",
            showModal:false,
            modalLoder:false,
            selectedDealer:{} as dealerDataInterface,
            selectedDevice:{} as deviceData

        };

        this.handleShow=this.handleShow.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.ShiftDealerFromDevice=this.ShiftDealerFromDevice.bind(this);
        this.selectedDealer=this.selectedDealer.bind(this);
        this.selectedDevice=this.selectedDevice.bind(this);

    }

    componentDidMount(): void {
        this.getDealerDevices();
    }

    getDealerDevices(){
        const { match: { params } } = this.props;

        this.props.getDealersDevicesAction({dealerId:this.props.selectedDealer.id},(error)=>{

            if(error){
                this.setState({
                    error:true,
                    successString:"",
                    Success:false,
                    errorString:"Failed To load Devices"
                })
            }else {
                this.setState({
                    error:false,
                    errorString:""
                })
            }
        })
    }



    handleShow(e:React.MouseEvent<HTMLButtonElement>){
        e.preventDefault();
        this.setState({
            showModal:true
        },()=>{

        });

    }

    handleClose(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal:false,
            error:false,
            errorString:"",
            Success:false,
            successString:""
        })
    }

    selectedDealer(selectedDealer:dealerDataInterface){

        this.setState({
            selectedDealer
        })
    }

    selectedDevice(device:deviceData){
        this.setState({
            selectedDevice:device,
            showModal:true
        })
    }


    ShiftDealerFromDevice(e:React.MouseEvent){
        e.preventDefault();

        this.setState({
            loading:true
        });

        if(this.props.match.params.dealerId===this.state.selectedDealer.id?.toString()){

            this.setState({
                error:true,
                errorString:'Please Select Different Dealer'
            });

        }else {

            this.setState({
                error:false,
                errorString:''
            });


            if(this.state.selectedDealer.id){
                this.props.shiftDeviceToAnotherDealerAction({
                    deviceId:this.state.selectedDevice.id,
                    dealerId:this.state.selectedDealer.id
                },(error)=>{

                    if(error){
                        this.setState({
                            loading:false,
                            showModal:false,
                            error:true,
                            errorString:'Failed To Shift Device'
                        })
                    }else {
                        this.setState({
                            loading:false,
                            showModal:false,
                            error:false,
                            errorString:'',
                            Success:true,
                            successString:"Device Shifted"
                        },()=>{
                            this.getDealerDevices();
                        })
                    }

                })
            }else {
                this.setState({
                    error:true,
                    errorString:'Please Select Dealer'
                });
            }


        }

    }

    render() {

        let devices=()=>{
            if(this.props.allDealerDevices!==null){
                return this.props.allDealerDevices[0].map((device,index)=>{
                    return (
                        <tr key={device.id}>
                            <th scope="row">{index +1}</th>
                            <td>{device.device.unique_id}</td>
                            <td>{device.devicePhone!==null? device.devicePhone:"Not Set"}</td>
                            <td>{moment(device.createdAt).format("YYYY:MM:DD HH:ss")}</td>
                            <td>{device.user? device.user.fname + " " + device.user.lname:"No User"}</td>
                            <td style={{cursor:"pointer"}} onClick={()=>this.selectedDevice(device)}><i className="material-icons icon" style={{color:'red'}}>edit</i>Shift Dealer</td>
                        </tr>
                    );

                })

            }
        };


        return (
            <React.Fragment>

               <LoaderComponent loading={this.state.loading}/>
               <Notifiction error={this.state.error} errorString={this.state.errorString} success={this.state.Success} successString={this.state.successString}/>
               <table className="table table-bordered">
                   <thead>
                   <tr>
                       <th scope="col">#</th>
                       <th scope="col">Unique ID</th>
                       <th scope="col">Sim Card Number</th>
                       <th scope="col">Date Registered in System</th>
                       <th scope="col">User</th>
                       <th>Action</th>
                   </tr>
                   </thead>
                   <tbody>
                   {devices()}
                   </tbody>
               </table>
               <ModalComponent
                   title="Shift Device"
                   handleShow={this.handleShow}
                   show={this.state.showModal}
                   handleClose={this.handleClose}
                   save={this.ShiftDealerFromDevice}
                   loader={this.state.modalLoder}
                   success={this.state.Success}
                   error={this.state.error}
                   errorString={this.state.errorString}
                   successString={this.state.successString}
               >
                   {/*<DealerComponent head="showSelect" callback={this.selectedDealer}/>*/}
               </ModalComponent>
            </React.Fragment>

        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        allDealerDevices:state.allDealerDevices,
        selectedDealer:state.selectedDealer
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {getDealersDevicesAction,shiftDeviceToAnotherDealerAction}, dispatch)

}

export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(AllDealerDevicesComponent) as any);
