import {deviceData} from "../../dataInterface/deviceDetailInterface";


interface selectedDeviceReducer {
    type:string
    payload:deviceData
}

export default (state = null, action:selectedDeviceReducer) => {
    switch (action.type) {
        case "SELECTED_DEVICE":
            return action.payload;
        default:
            return state;
    }
};
