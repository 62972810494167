import React, {ButtonHTMLAttributes} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import DevicesComponent from "./DevicesComponent";
import {deviceData} from "../../dataInterface/deviceDetailInterface";
import {getDeviceAction} from "../../actions/device/getDevicesAction";
import ModalComponent from "../Modal/ModalComponent";
import {newDeviceAction} from "../../actions/device/newDeviceAction";
import {getDeviceAdminNotSetAction} from "../../actions/device/getDevicesAdminNotSetAction";
import {deviceInterface} from "../../dataInterface/deviceDataInterface";
import moment from "moment";
import {AxiosResponse} from "axios";
import {deviceSmsInitAction} from "../../actions/device/deviceSmsInitAction";
import {adminDetails} from "../../dataInterface/detailsDataInterface";
import Notifiction from "../Notification/Notifiction";
import LoaderComponent from "../Loader/LoaderComponent";
import ReactPaginate from "react-paginate";


interface internalState {
    loading:boolean,
    error:boolean,
    errorString:string,
    Success:boolean,
    successString:string,
    showModal:boolean,
    modalLoder:boolean,
    uniqueId:string,
    take:number,
    skip:number,
}

interface parentProps {
}

interface externalStateProps {
    devices:[Array<deviceInterface>,number],
    adminDetails:adminDetails,
    authUserPermissions:Array<string>


}


interface DispatchProps {
    getDeviceAction:(data:object,callback:()=>void)=>void,
    getDeviceAdminNotSetAction:(data:object,callback:(error:boolean)=>void)=>void,
    newDeviceAction:(data:object,callBack:(response:boolean)=>void)=>void,
    deviceSmsInitAction:(data:object,callBack:(response:AxiosResponse)=>void)=>void,

}

type Props = externalStateProps & DispatchProps & parentProps;

class CreateDeviceComponent extends React.Component<Props, internalState> {
    private textInput: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);
        this.state = {
            loading:false,
            showModal:false,
            error:false,
            Success:false,
            errorString:"",
            successString:"",
            modalLoder:false,
            uniqueId:"",
            take:6,
            skip:0,
        };

        this.textInput = React.createRef();
        this.handleShow=this.handleShow.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.createDevice=this.createDevice.bind(this);
        this.paginate=this.paginate.bind(this);
        this.nextPage=this.nextPage.bind(this);
        this.previousPage=this.previousPage.bind(this);
    }

    componentDidMount(): void {
        this.loadDevices();

    }

    loadDevices(){
        if(this.props.authUserPermissions?.includes("POST_is_admin")){
            this.props.getDeviceAdminNotSetAction({take:this.state.take,skip:this.state.skip},(error)=>{

                if(error){
                    this.setState({
                        error:true,
                        errorString:"Server Error Detected",
                        successString:"",
                        Success:false,
                        loading:false
                    });
                }
            })

        }
    }

    handleShow(e:React.MouseEvent<HTMLButtonElement>){
        e.preventDefault();
        this.setState({
            showModal:true,
            errorString:"",
            error:false,
            successString:"",
            Success:false
        },()=>{
            if(this.textInput.current)
                this.textInput.current.focus();
        });

    }

    handleClose(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal:false,
            error:false,
            errorString:"",
            Success:false,
            successString:"",
            take:6,
            skip:0,

        })
    }

    paginate(data:{selected:number}){
        this.setState({
            skip: this.state.take * data.selected
        },()=>{
            this.loadDevices();
        })
    }
    nextPage(){
        this.setState({
            skip:this.state.skip+10,
        },()=>{
            this.loadDevices();

        });
    }

    previousPage(){
        this.setState({
            skip:this.state.skip-10,
        },()=>{
            this.loadDevices();
        });
    }

    setCommand(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>,commandType:string,deviceId:number){
        try {
            if(commandType==="admin"){

                if(this.props.adminDetails){
                    let command=`admin123456 ${this.props.adminDetails.adminPhone}`;

                    this.setState({
                        loading:true
                    });
                    this.props.deviceSmsInitAction({command,rawCommand:true,phone:this.props.adminDetails.numberToSend,deviceId},(response:AxiosResponse)=>{
                        if(response.status===200){
                            this.setState({
                                loading:false,
                                error:false,
                                Success:true,
                                successString:"Admin Command Sent",
                                errorString:""
                            },()=>{
                            });
                        }else {
                            this.setState({
                                loading:false,
                                error:true,
                                Success:false,
                                successString:"",
                                errorString:"Failed To Set Admin"
                            },()=>{
                            });
                        }

                    })
                }else {
                    this.setState({
                        loading:false,
                        error:true,
                        Success:false,
                        successString:"",
                        errorString:"Please Set Admin Phone Number in Settings First"
                    },()=>{
                    });

                }

            }else if(commandType==="shock") {
                let command=`Shock123456`
                this.setState({
                    loading:true
                })
                this.props.deviceSmsInitAction({command,rawCommand:true,phone:this.props.adminDetails.numberToSend,deviceId},(response:AxiosResponse)=>{

                    if(response.status===200){
                        this.setState({
                            loading:false,
                            error:false,
                            Success:true,
                            successString:"Shock Command Sent",
                            errorString:""
                        },()=>{
                        });
                    }else {
                        this.setState({
                            loading:false,
                            error:true,
                            Success:false,
                            successString:"Admin Command Failed",
                            errorString:"Failed To send Shock Command"
                        },()=>{
                        });
                    }

                })
            }

        }catch (e) {
            console.log(e);
        }



    }

    createDevice(e:React.FormEvent<HTMLFormElement>){
        e.preventDefault();
        if(this.state.uniqueId!==""){
            this.setState({modalLoder:true,error:false});
            this.props.newDeviceAction({deviceUniqueId:this.state.uniqueId,take:this.state.take,skip:this.state.skip},(response)=>{
                if(response){
                    this.setState({
                        modalLoder:false,
                        uniqueId:"",
                        error:false,
                        errorString:"",
                        successString:"Device inserted",
                        Success:true
                    },()=>{
                        if(this.textInput.current)
                            this.textInput.current.focus();
                    })
                }else {
                    this.setState({error:true,errorString:"Duplicate Unique ID",modalLoder:false,uniqueId:"",Success:false,successString:""});
                }

            })
        }else {
            this.setState({error:true,errorString:"Please Input Required Fields",modalLoder:false,uniqueId:""});
        }
    }

    render() {

        let devices=()=>{
            if(this.props.devices){
               return this.props.devices[0].map((device,index)=> {
                    if (this.props.authUserPermissions?.includes("POST_is_admin")) {
                        return (
                            <tr>
                                <th scope="row">{index + 1}</th>
                                <td>{device.unique_id}</td>
                                <td>{moment(device.createdAt).format("DD-MM-YYYY HH:mm")}</td>
                                <td>
                                    <button className="btn btn-default"
                                            onClick={(e) => this.setCommand(e, "admin", device.id)}>Set Admin
                                    </button>
                                </td>
                            </tr>
                        );
                    }
                });

            }else {
                return <React.Fragment/>
            }
        };



        return (
            <React.Fragment>
                <LoaderComponent loading={this.state.loading}/>
                <Notifiction errorString={this.state.errorString} error={this.state.error} successString={this.state.successString} success={this.state.Success}/>
                {
                    (()=>{
                        if(this.props.authUserPermissions?.includes("POST_register_Device")){
                            return <button className="btn btn-dark mb-3" onClick={this.handleShow}>Insert New Device</button>
                        }else {
                            return <React.Fragment/>
                        }
                    })()
                }
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Unique ID</th>
                        <th scope="col">Date Registered</th>
                    </tr>
                    </thead>
                    <tbody>
                    {devices()}
                    </tbody>
                </table>
                <ModalComponent
                    title="Register New Device"
                    handleShow={this.handleShow}
                    show={this.state.showModal}
                    handleClose={this.handleClose}
                    loader={this.state.modalLoder}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    <form onSubmit={this.createDevice}>
                        <div className="col-auto">
                            <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">DEVICE UNIQUE ID</div>
                                </div>

                                    <input type="text" className="form-control" placeholder="Enter Device ID" ref={this.textInput}  value={this.state.uniqueId}  onChange={(e)=>this.setState({uniqueId:e.target.value})}/>
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" type="submit">Submit</button>
                                    </div>
                            </div>
                        </div>
                    </form>
                </ModalComponent>
                <ReactPaginate
                    containerClassName="pagination"
                    breakClassName="page-item"
                    breakLabel={<a className="page-link">...</a>}
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    pageCount={this.props.devices?this.props.devices[1]/this.state.take:0}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    onPageChange={this.paginate}
                />

            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        devices:state.allDevices,
        adminDetails:state.adminDetails,
        authUserPermissions:state.authUserPermissions
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {getDeviceAction,newDeviceAction,getDeviceAdminNotSetAction,deviceSmsInitAction}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(CreateDeviceComponent);
