import {dealerDataInterface} from "../../dataInterface/dealerDataInterface";


interface getDealersDevicesReducer {
    type:string
    payload:Array<dealerDataInterface>
}

export default (state = null, action:getDealersDevicesReducer) => {
    switch (action.type) {
        case "DEALER_DEVICES":
            return action.payload;
        default:
            return state;
    }
};