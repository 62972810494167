import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import ModalComponent from "../Modal/ModalComponent";
import LoaderComponent from "../Loader/LoaderComponent";
import {createSubscriptionTypeAction} from "../../actions/subscription/createSubscriptionTypeAction";
import {getSubscriptiontypesPageable} from "../../actions/subscription/getsubscriptiontypesPageableAction";
import {updateSubscriptionTypes} from "../../actions/subscription/updateSubscriptionTypesAction";

import subScriptionTypes from "../../reducers/subscription/subscriptionTypePageableReducer";
import {subScriptionTypeDataInterface} from "../../dataInterface/subScriptionTypeDataInterface";

import moment from "moment";
import ReactPaginate from "react-paginate";

interface internalState {
    loading:boolean,
    error:boolean,
    errorString:string,
    Success:boolean,
    successString:string,
    showModal:boolean,
    showModal2:boolean,
    modalLoder:boolean,
    name:string,
    amount:string,
    take:number,
    skip:number,
    selectedSubscriptionType:{
        id:number,
        name:string,
        amount:number
    }
}

interface parentProps {
}

interface externalStateProps {
    subScriptionTypes:[Array<subScriptionTypeDataInterface>,number]
}


interface DispatchProps {
    createSubscriptionTypeAction:(data:object,callback:(error:boolean)=>void)=>void,
    getSubscriptiontypesPageable:(data:object,callback:(error:boolean)=>void)=>void,
    updateSubscriptionTypes:(data:object,callback:(error:boolean)=>void)=>void,
}

type Props = externalStateProps & DispatchProps & parentProps;

class SubscriptionType extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
            Success: false,
            errorString: "",
            successString: "",
            modalLoder: false,
            showModal: false,
            showModal2: false,
            name: "",
            amount: "",
            take: 6,
            skip: 0,
            selectedSubscriptionType:{
                id:0,
                name:"",
                amount:0
            }
        };

        this.handleShow=this.handleShow.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.handleShow2=this.handleShow2.bind(this);
        this.handleClose2=this.handleClose2.bind(this);
        this.createSubScription=this.createSubScription.bind(this);
        this.updateSubScription=this.updateSubScription.bind(this);

    }

    componentDidMount(): void {
        this.getAllSubScription();
    }

    getAllSubScription(){
        this.setState({loading:true});
        this.props.getSubscriptiontypesPageable({take:this.state.take,skip:this.state.skip},(error)=>{

            if(error){
                this.setState({
                    error:true,
                    errorString:"Server error Detected",
                    successString:"",
                    Success:false,
                    loading:false
                });
            }else {
                this.setState({
                    loading:false
                })
            }
        })
    }

    handleShow(e:React.MouseEvent<HTMLButtonElement>){
        e.preventDefault();
        this.setState({
            showModal:true
        })
    }
    handleShow2(e:React.MouseEvent<HTMLButtonElement>,type:{id:number,name:string,amount:number}){
        e.preventDefault();
        this.setState({
            showModal2:true,
            selectedSubscriptionType:type
        })
    }

    handleClose(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal:false,
            error:false,
            errorString:"",
            Success:false,
            successString:"",
            amount:""
        })
    }

    handleClose2(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal2:false,
            error:false,
            errorString:"",
            Success:false,
            successString:"",
            amount:""
        })
    }
    paginate(data:{selected:number}){
        this.setState({
            skip: this.state.take * data.selected
        },()=>{
            this.getAllSubScription();
        })
    }
    nextPage(){
        this.setState({
            skip:this.state.skip+10,
        },()=>{
            this.getAllSubScription();

        });
    }

    previousPage(){
        this.setState({
            skip:this.state.skip-10,
        },()=>{
            this.getAllSubScription();
        });
    }

    updateSubScription(){


        this.setState({
            loading:true,
        });

        if(this.state.amount!==""){
            this.props.updateSubscriptionTypes({typeId:this.state.selectedSubscriptionType.id,amount:this.state.amount},(error)=>{

                if(error){
                    this.setState({
                        error:true,
                        errorString:"Server error Detected",
                        successString:"",
                        Success:false,
                        loading:false,
                    });

                }else {
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Subscription Type Updated",
                        Success:true,
                        loading:false,
                        amount:"",
                    },()=>{
                        this.getAllSubScription();
                    });

                }

            })

        }else {
            this.setState({
                error:true,
                errorString:"Please Input Amount",
                successString:"",
                Success:false,
                loading:false,
            });
        }

    }



    createSubScription(e:React.MouseEvent<HTMLButtonElement>){

        if(this.state.amount.trim()!=="" && this.state.name.trim()!==""){

            this.setState({
                loading:true,
            });

            this.props.createSubscriptionTypeAction({name:this.state.name.trim().toUpperCase(),amount:this.state.amount.trim()},(error)=>{

                if(error){
                    this.setState({
                        error:true,
                        errorString:"Server error Detected",
                        successString:"",
                        Success:false,
                        loading:false
                    });

                }else {
                    this.setState({
                        error:false,
                        errorString:"",
                        successString:"Subscription Type Created",
                        Success:true,
                        loading:false,
                        amount:"",
                        name:""
                    },()=>{
                        this.getAllSubScription();
                    });

                }

            })
        }else {
            this.setState({
                error:true,
                errorString:"Name and Amount are Required",
                successString:"",
                Success:false,
                loading:false
            });
        }
    }

    render() {

        let types=()=>{

            if (this.props.subScriptionTypes){
                return this.props.subScriptionTypes[0].map((types,index)=>{

                    return(
                        <React.Fragment>
                            <tr>
                                <th scope="row">{index+1}</th>
                                <td>{types.name}</td>
                                <td>{types.amount}</td>
                                <td>{moment(types.createdAt).format('YYYY-MM-DD HH:ss')}</td>
                                <td><button className="btn btn-default" onClick={(e)=>this.handleShow2(e,{id:types.id,name:types.name,amount:types.amount})}>Update</button></td>
                            </tr>
                        </React.Fragment>
                    )
                });

            }else {
                return <React.Fragment/>
            }
        };


        // @ts-ignore
        return (
            <React.Fragment>
                <div className="legend">
                    <h6 className="legend-title">
                        Subscription Types
                    </h6>
                    <div className="row div-table-row">
                        <div className="col-md-2 div-table div-table-title">
                            Create Subscription Type
                        </div>
                        <div className="col-md-3 div-table div-table-value">
                            <button className="btn btn-default" onClick={this.handleShow}>Create</button>
                        </div>
                        <div className="col-md-2 div-table div-table-title">
                            Upload
                        </div>
                        <div className="col-md-5 div-table div-table-value">
                        </div>
                    </div>
                    <br/>
                    <div className="row div-table-row">
                        <div className="col-md-2 div-table div-table-title">
                            RECONCILIATION
                        </div>
                        <div className="col-md-4 div-table div-table-value">
                        </div>
                    </div>
                    <br/>
                </div>
                <ModalComponent
                    title="Create Subscription Type"
                    show={this.state.showModal}
                    handleClose={this.handleClose}
                    loader={this.state.modalLoder}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    <div className="col-md-12">
                        <LoaderComponent loading={this.state.loading}/>
                        <div className="form-row">
                            <div className="input-group mb-2 col-md-6">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Name</div>
                                </div>
                                <input type="text" className="form-control" placeholder="Subscription Name"  value={this.state.name}  onChange={(e)=>this.setState({name:e.target.value})}/>
                            </div>
                            <div className="input-group mb-2 col-md-6">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Amount</div>
                                </div>
                                <input type="text" className="form-control" placeholder="Amount"  value={this.state.amount}  onChange={(e)=>this.setState({amount:e.target.value})}/>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" onClick={(e)=>this.createSubScription(e)}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalComponent>
                <ModalComponent
                    title="Update Subscription Type"
                    show={this.state.showModal2}
                    handleClose={this.handleClose2}
                    loader={this.state.modalLoder}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    <div className="col-md-12">
                        <LoaderComponent loading={this.state.loading}/>
                        <div className="form-row">
                            <div className="input-group mb-2 col-md-6">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Name</div>
                                </div>
                                <input type="text" className="form-control" placeholder="Subscription Name"  value={this.state.selectedSubscriptionType.name} disabled/>
                            </div>
                            <div className="input-group mb-2 col-md-6">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Amount</div>
                                </div>
                                <input type="text" className="form-control" placeholder="Amount"  value={this.state.amount}  onChange={(e)=>this.setState({amount:e.target.value})}/>
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" onClick={this.updateSubScription}>Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalComponent>

                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Subscription Name</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Date Created</th>
                    </tr>
                    </thead>
                    <tbody>
                    {types()}
                    </tbody>
                </table>
                <ReactPaginate
                    containerClassName="pagination"
                    breakClassName="page-item"
                    breakLabel={<a className="page-link">...</a>}
                    pageClassName="page-item"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    pageCount={this.props.subScriptionTypes?this.props.subScriptionTypes[1]/this.state.take:0}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    onPageChange={this.paginate}
                />

            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        subScriptionTypes:state.subScriptionTypesPageable

    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {createSubscriptionTypeAction,getSubscriptiontypesPageable,updateSubscriptionTypes}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionType);
