import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {downloadAllSubscriptionMonthlyByDateRange} from "../../actions/subscription/getAllSubscriptionMonthlyByDateRangeAction";


interface internalState {
    startDate:string,
    endDate:string,
    loading:boolean,
    Success:boolean,
    error:boolean,
    errorString:string,
    successString:string,
}

interface parentProps {
}

interface externalStateProps {
}


interface DispatchProps {
    downloadAllSubscriptionMonthlyByDateRange:(data:object,callback:(error:boolean)=>void)=>void

}

type Props = externalStateProps & DispatchProps & parentProps;

class MoneyDashboardComponent extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            startDate:'',
            endDate:'',
            loading:false,
            Success:false,
            error:false,
            errorString:"",
            successString:"",

        }

        this.downloadSimsForPay=this.downloadSimsForPay.bind(this);
    }

    downloadSimsForPay(e:React.MouseEvent<HTMLButtonElement>){
        e.preventDefault();
        this.setState({loading:true});
        this.props.downloadAllSubscriptionMonthlyByDateRange({
            startDate:this.state.startDate,
            endDate:this.state.endDate
        },(error => {

            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Failed to generate Download",
                    successString:"",
                    Success:false,
                });

            }else {
                this.setState({
                    loading:false
                });
            }
        }))
    }


    componentDidMount(): void {
    }

    render() {
        return (
            <React.Fragment>
                <div className="legend mb-5">
                    <h6 className="legend-title">
                        Payments
                    </h6>
                    <div className="row div-table-row">
                        <div className="col-md-2 div-table div-table-title">
                            Devices Payments/Debts
                        </div>
                        <div className="col-md-3 div-table div-table-value">
                            <Link to="/admin/money/debt">
                                <button className="btn btn-default">View</button>
                            </Link>
                        </div>
                        <div className="col-md-2 div-table div-table-title">
                            Subscription Payments
                        </div>
                        <div className="col-md-5 div-table div-table-value">
                            <Link to="/admin/money/subscription/dashboard">
                                <button className="btn btn-default">view</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="legend">
                    <div className="col-md-12 mb-3">
                        <h3 className="mb-5">Download Sim Cards for Monthly Bundle Placement</h3>
                        <form>
                            <div className="row">
                                <div className="col-md-6 mb-5">
                                    <label>Start Date</label>
                                    <input type="datetime-local" className="form-control" placeholder="Start Date" value={this.state.startDate}  onChange={(e)=>this.setState({startDate:e.target.value})}/>
                                </div>
                                <div className="col-md-6 mb-5">
                                    <label>End Date</label>
                                    <input type="datetime-local" className="form-control" placeholder="End Date" value={this.state.endDate}  onChange={(e)=>this.setState({endDate:e.target.value})}/>
                                </div>
                                <div className="col-md-12">
                                    <button className="btn btn-success btn-block" onClick={this.downloadSimsForPay}>Download</button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>

            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {}
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {downloadAllSubscriptionMonthlyByDateRange}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(MoneyDashboardComponent);
