import {HTTP} from "../../helpers/axios";
import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import { serverRoutes} from "../../api/serverRoutes";
import {responseData} from "../../dataInterface/deviceDetailInterface";


export const setDeviceVersion = (data:object):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {

    dispatch({
        type: 'SELECTED_DEVICE_VERSION',
        payload: data
    });
};

