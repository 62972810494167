import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link, RouteComponentProps, Redirect
} from "react-router-dom";
import Dashboard from "../Components/Admin/DashboardComponent";


import React from "react";
import MainLayout from "../Components/Layout/MainLayout";
import Login2Component from "../Components/Login/Login2Component";
import DeviceDettailsComponent from "../Components/Device/DeviceDettailsComponent";
import DeviceDetailsViewComponent from "../Components/Device/DeviceDetailsViewComponent";
import CustomerComponent from "../Components/Customer/CustomerComponent";
import CustomerDevices from "../Components/Customer/CustomerDevices";
import CreateDeviceComponent from "../Components/Device/CreateDeviceComponent";
import SimCardComponent from "../Components/SimCard/SimCardComponent";
import ViewSimCards from "../Components/SimCard/ViewSimCards";
import DealerComponent from "../Components/Dealer/DealerComponent";
import DealerViewComponent from "../Components/Dealer/DealerViewComponent";
import DealerDetailComponent from "../Components/Dealer/DealerDetailComponent";
import AllDealerDevicesComponent from "../Components/Dealer/AllDealerDevicesComponent";
import SubscriptionType from "../Components/SubScription/SubscriptionType";
import CreateUsersDashboard from "../Components/users/CreateUsersDashboard";
import RolesComponent from "../Components/Role/RolesComponent";
import PermissionsComponent from "../Components/Permission/PermissionsComponent";
import PrivateRoute from "../Components/PrivateRoute";
import axios from "axios";
import BundleDashboard from "../Components/Bundle/BundleDashboard";
import MechanicDashboard from "../Components/mechanic/MechanicDashboard";
import SettingsComponent from "../Components/settings/SettingsComponent";
import ReportDashboard from "../Components/Report/ReportDashboard";
import Mechanic from "../Components/Report/Mechanic";
import DeviceSales from "../Components/Report/DeviceSales";
import MessagesComponent from "../Components/settings/MessagesComponent";
import MoneyDashboardComponent from "../Components/Money/MoneyDashboardComponent";
import SubscriptionDashboardComponent from "../Components/Money/SubscriptionDashboardComponent";
import SubscriptionStatisticsComponent from "../Components/Report/SubscriptionStatisticsComponent";
import DeptComponent from "../Components/Money/DeptComponent";
import SelectDeviceTypeComponent from "../Components/Device/SelectDeviceTypeComponent";
import IndexComponent from "../Components/webpage/indexComponent";
import DeleteMyData from "../Components/webpage/DeleteMyData";



export let pagesRoutes:Array<{route:string,component:any,name:string,codeName:string}>=[
    {route:'/admin',name:'Pages', component:Dashboard, codeName:'-admin-'},
    {route:'/admin/devices',name:'Pages', component:DeviceDettailsComponent,codeName:'-admin-devices-'},
    {route:'/admin/details/view/:deviceId',name:'Pages',component:DeviceDetailsViewComponent, codeName:'-admin-devices-@deviceid-'},
    {route:'/admin/customer/',name:'Pages',component:CustomerComponent, codeName:'-admin-customer-'},
    {route:'/admin/customer/devices/list',name:'Pages',component:CustomerDevices, codeName:'-admin-customer-devices-list-'},
    {route:'/admin/devices/new',name:'Pages',component:CreateDeviceComponent, codeName:'-admin-devices-new-'},
    {route:'/admin/simcard',name:'Pages',component:SimCardComponent, codeName:'-admin-simcard-'},
    {route:'/admin/simcard/view',name:'Pages',component:ViewSimCards, codeName:'-admin-simcard-view-'},
    {route:'/admin/dealer',name:'Pages',component:DealerComponent, codeName:'-admin-dealer'},
    {route:'/admin/dealer/view',name:'Pages',component:DealerViewComponent, codeName:'-admin-dealer'},
    {route:'/admin/dealer/detail',name:'Pages',component:DealerDetailComponent, codeName:'-admin-dealer-detail-'},
    {route:'/admin/dealer/device',name:'Pages',component:AllDealerDevicesComponent, codeName:'-admin-dealer-device-'},
    {route:'/admin/subscription/type',name:'Pages',component:SubscriptionType, codeName:'-admin-subscription-type-'},
    {route:'/admin/users/dashboard',name:'Pages',component:CreateUsersDashboard, codeName:'-admin-users-dashboard-'},
    {route:'/admin/roles/dashboard',name:'Pages',component:RolesComponent, codeName:'-admin-roles-dashboard-'},
    {route:'/admin/permissions',name:'Pages',component:PermissionsComponent, codeName:'-admin-permissions-'},
    {route:'/admin/bundle/dashboard',name:'Pages',component:BundleDashboard, codeName:'-admin-bundle-'},
    {route:'/admin/mechanic/dashboard',name:'Pages',component:MechanicDashboard, codeName:'-admin-mechanic-'},
    {route:'/admin/settings',name:'Pages',component:SettingsComponent, codeName:'-admin-settings-'},
    {route:'/admin/messages',name:'Pages',component:MessagesComponent, codeName:'-admin-messages-'},
    {route:'/admin/report',name:'Pages',component:ReportDashboard, codeName:'-admin-report-'},
    {route:'/admin/report/mechanic',name:'Pages',component:Mechanic, codeName:'-admin-report-mechanic-'},
    {route:'/admin/report/subscription',name:'Pages',component:SubscriptionStatisticsComponent, codeName:'-admin-report-subscription-statistics-'},
    {route:'/admin/report/device/sales',name:'Pages',component:DeviceSales, codeName:'-admin-report-device-sales'},
    {route:'/admin/money',name:'Pages',component:MoneyDashboardComponent, codeName:'-admin-money-'},
    {route:'/admin/money/debt',name:'Pages',component:DeptComponent, codeName:'-admin-debt-'},
    {route:'/admin/money/subscription/dashboard',name:'Pages',component:SubscriptionDashboardComponent, codeName:'-admin-money-subscription-dashboard'},
    {route:'/admin/details/set/subScription',name:'Pages',component:SelectDeviceTypeComponent, codeName:'-admin-subscriptionType-select'},
];

export let guestRoutes:Array<{route:string,component:any,name:string}>=[
     {route:'/',name:'Pages', component:IndexComponent},
    {route:'/login',name:'Pages', component:Login2Component},
    {route:'/account/delete',name:'Pages', component:DeleteMyData},
];



export default ()=>{

    let dynamicRoutes=pagesRoutes.map((routes,index)=>{
        return(
            <PrivateRoute path={routes.route}  requiredPermission={routes.codeName} exact Component={routes.component}/>
        )
    });

    let indexRoutes=guestRoutes.map((routes,index)=>{
        return(
            <Route
                exact
                path={routes.route}
                render={routes.component}
            />

        )
    });

    return(
        <Router>
            <Route path="/">
                {indexRoutes}
            </Route>
            <Route path="/admin:path?">
                <MainLayout>
                    {dynamicRoutes}
                </MainLayout>
            </Route>
        </Router>
        )
}
