import {dealerDataInterface} from "../../dataInterface/dealerDataInterface";


interface selectedDealerInterface {
    type:string
    payload:dealerDataInterface
}

export default (state = null, action:selectedDealerInterface) => {
    switch (action.type) {
        case "SELECTED_DEALER":
            return action.payload;
        default:
            return state;
    }
};
