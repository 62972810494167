import {deviceSalesReportMonthlyInterface} from "../../dataInterface/deviceSalesReportMonthlyInterface";
import {MessageDataInterface} from "../../dataInterface/messageDataInterface";


interface message {
    type:string
    payload:MessageDataInterface
}

export default (state = null, action:message) => {
    switch (action.type) {
        case "MESSAGES":
            return action.payload;
        default:
            return state;
    }
};
