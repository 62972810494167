import {mechanicsDataInterface} from "../../dataInterface/mechanicsDataInterface";


interface mechanics {
    type:string
    payload:mechanicsDataInterface
}

export default (state = null, action:mechanics) => {
    switch (action.type) {
        case "ALL_MECHANICS":
            return action.payload;
        default:
            return state;
    }
};
