import {HTTP} from "../../helpers/axios";
// src/thunks.ts
import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import { serverRoutes} from "../../api/serverRoutes";


export const newDeviceAction = (data:object,callback:(status:boolean)=>void):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {

    try {
        const response =  await   HTTP(getState,dispatch).post(serverRoutes.newDevice,data);

        callback(true);
        dispatch({
            type: 'ALL_DEVICES',
            payload: response.data
        });



    }catch (e) {
        callback(false);
    }

};
