import {UsersDataInterface} from "../../dataInterface/UsersDataInterface";


interface fetchUserReducer {
    type:string
    payload:Array<UsersDataInterface>
}

export default (state = null, action:fetchUserReducer) => {
    switch (action.type) {
        case "FETCH_USERS":
            return action.payload;
        default:
            return state;
    }
};