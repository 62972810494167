import {deviceData} from "../../dataInterface/deviceDetailInterface";


interface fetchUserReducer {
    type:string
    payload:deviceData
}

export default (state = null, action:fetchUserReducer) => {
    switch (action.type) {
        case "SEARCH_DEVICES":
            return action.payload;
        default:
            return state;
    }
};
