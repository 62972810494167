import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import {ResponsiveLine, Serie,Datum} from "@nivo/line";
import {getSalesDataByMonth} from "../../actions/device/getSalesDataByMonthAction"
import {salesDataByMonthInterface} from "../../dataInterface/salesDataByMonthInterface";


interface internalState {
    data2:Array<Serie>
}

interface parentProps {
}

interface externalStateProps {
    sales:Array<salesDataByMonthInterface>
}


interface DispatchProps {
    getSalesDataByMonth:(callback:()=>void)=>void
}

type Props = externalStateProps & DispatchProps & parentProps;

class DevicesSalesByMonthYearComponent extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state={
            data2:[{
                id:'Sales',
                color: "#61cdbb",
                data:[]
            }],
        }


    }

    componentDidMount(): void {

        this.props.getSalesDataByMonth(()=>{

        })
    }

    static getDerivedStateFromProps(props:Props,state:internalState){
        if(props.sales!==null && props.sales.toString()!==state.data2.toString()){
            if(props.sales !=null){
                let data:Array<Serie>=[{
                    borderColor: "#61cdbb",
                    id:'Sales',
                    color: "#61cdbb",
                    data:[]
                }];

                props.sales.map((sale,index)=>{

                    data[0].data.push(
                        {
                            y:sale["sum(sales)"],
                            x:`Month ${sale["month(requestedDate)"]}`,
                        }
                    );
                });

                return {data2:data}


            }

        }
        return null;
    }

    render() {
        return (
            <React.Fragment>
                <div className="card">
                    <div className="card-body" style={{height:500}}>
                        <ResponsiveLine
                            data={this.state.data2}
                            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                            xScale={{ type: 'point' }}
                            yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                            yFormat=" >-.2f"
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                orient: 'bottom',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'transportation',
                                legendOffset: 36,
                                legendPosition: 'middle'
                            }}
                            axisLeft={{
                                orient: 'left',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'count',
                                legendOffset: -40,
                                legendPosition: 'middle'
                            }}
                            pointSize={10}
                            pointColor={{ theme: 'background' }}
                            pointBorderWidth={2}
                            pointBorderColor={{ from: 'serieColor' }}
                            pointLabelYOffset={-12}
                            useMesh={true}
                            legends={[
                                {
                                    anchor: 'bottom-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 100,
                                    translateY: 0,
                                    itemsSpacing: 0,
                                    itemDirection: 'left-to-right',
                                    itemWidth: 80,
                                    itemHeight: 20,
                                    itemOpacity: 0.75,
                                    symbolSize: 12,
                                    symbolShape: 'circle',
                                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemBackground: 'rgba(0, 0, 0, .03)',
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />

                    </div>
                </div>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        sales:state.salesDataByMonth
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {getSalesDataByMonth}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(DevicesSalesByMonthYearComponent);
