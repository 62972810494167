// src/thunks.ts
import { Action } from 'redux'
import {RootState} from '../../reducers'
import {ThunkAction} from "redux-thunk";


export const clearUserSettingsByTypeByUser = ():ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {

    try {


        dispatch({
            type: 'GET_TYPE_USER_SETTINGS',
            payload: null
        });


    }catch (e) {
        console.log(e);
    }


};

