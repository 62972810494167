import { Route, Redirect ,RouteComponentProps,RouteProps} from "react-router-dom";
import React  from "react";
import {RootState} from "../reducers";
import {connect} from "react-redux";
import {loginDatatype} from "../dataInterface/loginTypes";
import {bindActionCreators, Dispatch} from "redux";


interface parentprops {
    Component: any;
    requiredPermission:string
}

interface reduxStateProps{
    user:loginDatatype,
    auth:RootState['auth'],
    authUserPermissions:Array<string>

}

interface DispatchProps {
}

//interface Props extends reduxStateProps,parentprops,RouteProps{}

type Props = reduxStateProps & DispatchProps & parentprops & RouteProps;



class PrivateRoute extends Route<Props> {

    constructor(props:Props) {
        super(props);
    }

    componentWillMount(): void {
    }

    isAuthorized():boolean{
        return !!this.props.auth?.isAuthenticated;
    }


    render() {
        return (
            <Route
                exact={this.props.exact}
                path={this.props.path}
                render={(props: RouteComponentProps) =>
                    this.isAuthorized() && this.props.authUserPermissions?.includes(this.props.requiredPermission)?
                        (<this.props.Component {...props} />) : (<Redirect to={{pathname: '/'}}/>)
                }
            />
        );
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators({},dispatch)
}

function mapStateToProps(state: RootState): reduxStateProps {
    return {
        user:state.user,
        auth:state.auth,
        authUserPermissions:state.authUserPermissions
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(PrivateRoute);
