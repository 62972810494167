import {HTTP} from "../../helpers/axios";
import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import { serverRoutes} from "../../api/serverRoutes";


export const assignRoleToUserAction = (data:object,callback:(error:boolean)=>void):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {

    try {
        const response =  await   HTTP(getState,dispatch).post(serverRoutes.assignRoleToUser,data);

        dispatch({
            type: 'GET_ROLES',
            payload: response.data
        });
        let error=false;
        callback(error);
    }catch (e) {
        let error=true;
        callback(error);
    }


};

