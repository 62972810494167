import {deviceData} from "../../dataInterface/deviceDetailInterface";
import {adminPhoneDataInterface} from "../../dataInterface/adminPhoneDataInterface";


interface devicePhone {
    type:string
    payload:adminPhoneDataInterface
}

export default (state = null, action:devicePhone) => {
    switch (action.type) {
        case "DEVICE_PHONES":
            return action.payload;
        default:
            return state;
    }
};
