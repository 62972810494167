import {mechanicsDataReportInterface} from "../../dataInterface/mechanicsDataReportInterfce";
import {mechanicDataReportByDateRangeInterface} from "../../dataInterface/mechanicDataReportByDateRangeInterface";


interface mechanicsDataReport {
    type:string
    payload:mechanicDataReportByDateRangeInterface
}

export default (state = null, action:mechanicsDataReport) => {
    switch (action.type) {
        case "MECHANICS_REPORT_DATE_RANGE":
            return action.payload;
        default:
            return state;
    }
};
