import {deviceData} from "../../dataInterface/deviceDetailInterface";
import {deviceInterface} from "../../dataInterface/deviceDataInterface";


interface device {
    type:string
    payload:deviceInterface
}

export default (state = null, action:device) => {
    switch (action.type) {
        case "ALL_DEVICES":
            return action.payload;
        default:
            return state;
    }
};
