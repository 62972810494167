import {HTTP} from "../../helpers/axios";
// src/thunks.ts
import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import { serverRoutes} from "../../api/serverRoutes";
import {PermissionDataInterface} from "../../dataInterface/permissionDataInterface";


export const getPermissionsByRoleAction = (data:object,callback:(permissions:Array<PermissionDataInterface>,error:boolean)=>void):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {

    try {
        const response =  await   HTTP(getState,dispatch).post(serverRoutes.getRolePermission,data);

        dispatch({
            type: 'ROLE_PERMISSIONS',
            payload: response.data
        });

        let error=false;
        callback(response.data,error);
    }catch (e) {
        let error=true;
        callback({} as Array<PermissionDataInterface>,error);
    }


};

