import {HTTP} from "../../helpers/axios";
// src/thunks.ts
import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import {serverRoutes} from "../../api/serverRoutes";


export const removeAdminAction = (data:object,callback:(error:boolean)=>void):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {

    try {
        const response=  await   HTTP(getState,dispatch).post(serverRoutes.removeCustomerNumberSmsCommand,data);

        console.log(response.data);
        dispatch({
            type:"DEVICE_PHONES",
            payload:response.data.details.phones
        });
        let error=false;
        callback(error);

    }catch (e) {
        console.log(e);
        let error=true;
        callback(error);
    }


};

