import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import { withRouter } from "react-router";
import LteHeader from "./LteHeader";
import {Link} from "react-router-dom";
import Sidemenu from "./Sidemenu";




interface internalState {
    resize:boolean
}

interface parentProps {
}

interface externalStateProps {
}


interface DispatchProps {
}

type Props = externalStateProps & DispatchProps & parentProps;

class MainLayout extends React.Component<Props, internalState> {

    constructor(props:Props) {
        super(props);
        this.state={
            resize:false
        }
        this.resize=this.resize.bind(this);
    }

    resize(){
        this.setState({
            resize:!this.state.resize
        })
    }

    componentDidMount(): void {

    }

    render() {
        return (
            <React.Fragment>
                <LteHeader/>
                <Sidemenu/>
                {/*Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/*Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Dashboard</h1>
                                </div>
                                {/*/.col */}
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Dashboard</li>
                                    </ol>
                                </div>
                                {/*/.col */}
                            </div>
                            {/*/.row */}
                        </div>
                        {/*/.container-fluid */}
                    </div>
                    {/*/.content-header */}

                    {/*Main content */}
                    <div className="content">
                        <div className="container-fluid">
                            {this.props.children}
                        </div>
                        {/*/.container-fluid */}
                    </div>
                    {/*/.content */}
                </div>
                {/*/.content-wrapper */}

                {/*Control Sidebar */}
                <aside className="control-sidebar control-sidebar-dark">
                    {/*Control sidebar content goes here */}
                    <div className="p-3">
                        <h5>Title</h5>
                        <p>Sidebar content</p>
                    </div>
                </aside>
                {/*/.control-sidebar */}

                {/*Main Footer */}
                <footer className="main-footer">
                    {/*To the right */}
                    <div className="float-right d-none d-sm-inline">
                        Anything you want
                    </div>
                    {/*Default to the left */}
                    <strong>Copyright &copy; 2020 <a href="https://www.serdcom.co.tz">SERDCOM</a>.</strong> All
                    rights reserved.
                </footer>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {}
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {}, dispatch)

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLayout) as any);
