interface authUserPermissions {
    type:string
    payload:Array<string>
}

export default (state = null, action:authUserPermissions) => {
    switch (action.type) {
        case "AUTH_USER_PERMISSIONS":
            return action.payload;
        default:
            return state;
    }
};
