import {ThunkAction} from "redux-thunk";
import {RootState} from "../../reducers";
import {Action} from "redux";

export const selectedRoleAction = (role:object):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {

    dispatch({
        type: 'SELECTED_ROLE',
        payload: role
    });

};
