import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import ModalComponent from "../Modal/ModalComponent";
import LoaderComponent from "../Loader/LoaderComponent";
import Notifiction from "../Notification/Notifiction";
import {createDealerAction} from "../../actions/dealer/createDealerAction";


interface internalState {
    error:boolean,
    errorString:string,
    Success:boolean,
    successString:string,
    showModal:boolean,
    modalLoder:boolean,
    loading:boolean,
    name:string,
    fName:string,
    lName:string,
    phoneNumber:string
}

interface parentProps {
}

interface externalStateProps {
}


interface DispatchProps {
    createDealerAction:(dealer:object,callback:()=>void)=>void
}

type Props = externalStateProps & DispatchProps & parentProps;

class DealerComponent extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading:false,
            showModal:false,
            error:false,
            Success:false,
            errorString:"",
            successString:"",
            modalLoder:false,
            name:"",
            fName:"",
            lName:"",
            phoneNumber:""
        };

        this.createDealer=this.createDealer.bind(this);
        this.handleShow=this.handleShow.bind(this);
        this.handleClose=this.handleClose.bind(this);
    }

    componentDidMount(): void {
    }

    handleShow(e:React.MouseEvent<HTMLButtonElement>|React.MouseEvent<HTMLTableDataCellElement, MouseEvent>,DevicePhoneSelected:boolean,selectedAdminNumber?:string){
        e.preventDefault();
        if(DevicePhoneSelected){
            this.setState({
                showModal:true
            })
        }else {
            if(selectedAdminNumber)
                this.setState({
                    showModal:true,
                })
        }
    }

    handleClose(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal:false,
            error:false,
            errorString:"",
            Success:false,
            successString:""
        })
    }

    createDealer(e:React.MouseEvent<HTMLButtonElement>){
        e.preventDefault();

        if(this.state.fName!=="" && this.state.lName!==""  && this.state.phoneNumber!=="" ){
            this.setState({error:false,Success:false});
            this.setState({loading:true});
            this.props.createDealerAction({
                fName:this.state.fName,
                lName:this.state.lName,
                phoneNumber:this.state.phoneNumber
            },()=>{
                this.setState({
                    loading:false,
                    Success:true,
                    successString:"Dealer Created",
                    fName:"",
                    lName:"",
                    phoneNumber:""
                });
            })
        }else {
            this.setState({error:true,Success:false,errorString:"Dealer Could not be created"})
        }
    }


    render() {
        return (
            <React.Fragment>
                <div className="legend">
                    <h6 className="legend-title">
                        Marketing
                    </h6>
                    <div className="row div-table-row">
                        <div className="col-md-2 div-table div-table-title">
                            Marketing
                        </div>
                        <div className="col-md-3 div-table div-table-value">
                            <Link to="/admin/dealer/view">
                                <button className="btn btn-default">View Marketing people</button>
                            </Link>
                        </div>
                        <div className="col-md-2 div-table div-table-title">
                            New Marketing personnel
                        </div>
                        <div className="col-md-5 div-table div-table-value">
                            <button className="btn btn-default" onClick={()=>this.setState({showModal:true})}>New Marketing</button>
                        </div>
                    </div>
                </div>
                <ModalComponent
                    title="New Dealer"
                    show={this.state.showModal}
                    handleClose={this.handleClose}
                    loader={this.state.modalLoder}
                    success={this.state.Success}
                    error={this.state.error}
                    errorString={this.state.errorString}
                    successString={this.state.successString}
                >
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <LoaderComponent loading={this.state.loading}/>
                                <div className="col-md-12">
                                    <form>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="name">First Name</label>
                                                <input type="text" className="form-control" id="name" value={this.state.fName} onChange={(e)=>this.setState({name:e.target.value})}/>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="name">First Name</label>
                                                <input type="text" className="form-control" id="name" value={this.state.lName} onChange={(e)=>this.setState({name:e.target.value})}/>
                                            </div>
                                            <div className="form-group col-md-5">
                                                <label htmlFor="name">Phone Number</label>
                                                <input type="text" className="form-control" id="name" value={this.state.phoneNumber} onChange={(e)=>this.setState({phoneNumber:e.target.value})}/>
                                            </div>
                                        </div>
                                    </form>
                                    <button className="btn btn-primary" onClick={this.createDealer}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalComponent>


            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {}
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {createDealerAction}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(DealerComponent);
