import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import moment from "moment";
import {uploadNewSimcard} from "../../actions/simcard/uploadNewSimCardAction";
import {updateSimcard} from "../../actions/simcard/updateSimcardsAction";
import {Link} from "react-router-dom";
import {downloadSimCardsForPayments} from "../../actions/simcard/downloadSimCardsForPaymentAction";
import LoaderComponent from "../Loader/LoaderComponent";
import Notifiction from "../Notification/Notifiction";


interface internalState {
    file:File|null,
    loading:boolean,
    error:boolean,
    errorString:string,
    Success:boolean,
    successString:string,
}

interface parentProps {
}

interface externalStateProps {
}


interface DispatchProps {
    uploadNewSimcard:(data:{dateTopUp:string,excel:File|null},onUploadProgress:(data:any)=>void,callback:(error:boolean)=>void)=>void
    updateSimcard:(data:{dateTopUp:string,excel:File|null},onUploadProgress:(data:any)=>void,callback:(error:boolean)=>void)=>void,
    downloadSimCardsForPayments:(data:object,callback:(error:boolean)=>void)=>void

}

type Props = externalStateProps & DispatchProps & parentProps;

class SimCardComponent extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            file:null,
            loading:false,
            error:false,
            errorString:"",
            Success:false,
            successString:"",
        };

        this.selectedFile=this.selectedFile.bind(this);
        this.submit=this.submit.bind(this);
        this.update=this.update.bind(this);
        this.downloadSimsForPay=this.downloadSimsForPay.bind(this);

    }

    componentDidMount(): void {
    }

    selectedFile(e:React.ChangeEvent<HTMLInputElement>){
        e.preventDefault();

        this.setState({
            file:e.target.files? e.target.files[0]:null
        },()=>{
            console.log(this.state);
        });

    }

    downloadSimsForPay(){
        this.setState({loading:true});
        this.props.downloadSimCardsForPayments({
            startDate:'',
            endDate:''
        },(error => {

            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Failed to generate Download",
                    successString:"",
                    Success:false,
                });

            }else {
                this.setState({
                    loading:false
                });
            }
        }))
    }

    submit(e:React.MouseEvent){
        e.preventDefault();
        this.setState({
            loading:true
        });

        this.props.uploadNewSimcard({dateTopUp:"2020-08-03",excel:this.state.file},(data:any)=>{
            console.log(data);
        },(error)=>{

            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Failed to Upload",
                    successString:"",
                    Success:false,
                });

            }else {
                this.setState({
                    loading:false,
                    error:false,
                    file:null,
                    errorString:"",
                    successString:"Sim Cards Uploaded",
                    Success:true,
                });

            }
        })
    }

    update(e:React.MouseEvent){
        e.preventDefault();
        this.props.updateSimcard({dateTopUp:"2020-08-03",excel:this.state.file},(data:any)=>{
            console.log(data);
        },(error)=>{

        })
    }


    render() {
        return (
            <React.Fragment>
                <LoaderComponent loading={this.state.loading}/>
                <Notifiction error={this.state.error} success={this.state.Success} successString={this.state.successString} errorString={this.state.errorString}/>
                <div className="legend">
                    <h6 className="legend-title">
                        Device Details
                    </h6>
                    <div className="row div-table-row">
                        <div className="col-md-2 div-table div-table-title">
                            Sim Cards
                        </div>
                        <div className="col-md-3 div-table div-table-value">
                            <Link to="/admin/simcard/view">
                                <button className="btn btn-default">View Sim Cards</button>
                            </Link>
                        </div>
                        <div className="col-md-2 div-table div-table-title">
                            Upload<br/>
                            <a href="/files/SIMCARD.xlsx" download>Template</a>
                        </div>
                        <div className="col-md-5 div-table div-table-value">
                            <div className="form-group">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="customFile" onChange={(e)=>this.selectedFile(e)}/>
                                    <label className="custom-file-label" htmlFor="customFile">{this.state.file? this.state.file.name:"Select File"}</label>
                                </div>
                                <button className="btn btn-default mt-2" onClick={this.submit}>Submit</button>
                            </div>

                        </div>
                    </div>
                    <br/>
                    <div className="row div-table-row">
                        <div className="col-md-2 div-table div-table-title">
                            Download Sim Cards to Top Up
                        </div>
                        <div className="col-md-4 div-table div-table-value">
                            <button className="btn btn-default" onClick={this.downloadSimsForPay}>Download Sim Cards For payments</button>
                        </div>
                    </div>
                    <br/>
                </div>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {}
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {uploadNewSimcard,updateSimcard,downloadSimCardsForPayments}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(SimCardComponent);
