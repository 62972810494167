import {roleDataTypeInterface} from "../../dataInterface/roleDataTypeInterface";


interface roles {
    type:string
    payload:roleDataTypeInterface
}

export default (state = null, action:roles) => {
    switch (action.type) {
        case "SELECTED_ROLE":
            return action.payload;
        default:
            return state;
    }
};
