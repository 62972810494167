import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import LoaderComponent from "../Loader/LoaderComponent";
import {deviceData} from "../../dataInterface/deviceDetailInterface";
import {Link} from "react-router-dom";
import {getDeviceAction} from "../../actions/device/getDevicesAction";
import SearchDeviceComponent from "./SearchDeviceComponent";
import SearchForAllComonent from "./SearchForAllComonent";
import History from 'history'
import {selectedDeviceAction} from "../../actions/device/selectedDeviceAction";
import {selectedCustomerAction} from "../../actions/customer/selectedCustomerAction";
import {CustomerDataInterface} from "../../dataInterface/CustomerDataInterface";
import {deviceInterface} from "../../dataInterface/deviceDataInterface";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import ReactPaginate from "react-paginate";
import moment from "moment";

interface internalState {
    loading:boolean,
    take:number,
    skip:number,
    deviceIndex:number,
    totalRecords:number,
    current_page:number
}

interface parentProps {
    history?:History.History,
}

interface externalStateProps {
    devices:[Array<deviceInterface>,number],
}


interface DispatchProps {
    getDeviceAction:(data:object,callback:()=>void)=>void,
    selectedDeviceAction:(data:object)=>void,
    selectedCustomerAction:(customer:CustomerDataInterface|null)=>void,
}

type Props = externalStateProps & DispatchProps & parentProps;

class DeviceDettailsComponent extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading:false,
            take:6,
            skip:0,
            deviceIndex:0,
            totalRecords:0,
            current_page:1

        };
        this.dateTimeToHuman=this.dateTimeToHuman.bind(this);
        this.selectedDevice=this.selectedDevice.bind(this);
        this.previousPage=this.previousPage.bind(this);
        this.nextPage=this.nextPage.bind(this);
        this.paginate=this.paginate.bind(this);

    }

    loadDevices(){
        this.props.getDeviceAction({take:this.state.take,skip:this.state.skip},()=>{
            this.setState({
                loading:false,
            });
        });
    }

    componentDidMount(): void {
        this.setState({
            loading:true,
        });
        this.loadDevices();

    }



    getSnapshotBeforeUpdate(props:Props,state:internalState){

        // state.totalRecords=parseInt(props.devices[1].toString());
        if(props.devices && props.devices.length>0){
            return {totalRecords:parseInt(props.devices[1].toString())}
        }

        return null;
    }

    selectedDevice(device:deviceInterface,pathname:string|null=null){

        this.props.selectedDeviceAction(device);
        if(device.details?.customer){
            this.props.selectedCustomerAction(device.details.customer);
            if(pathname!==null){
                this.props.history?.push({pathname})
            }
        }else {
            this.props.selectedCustomerAction(null);
            if(pathname!==null){
                this.props.history?.push({pathname:"/admin/details/set/subScription"})
            }
        }


    }

    nextPage(){
        this.setState({
            skip:this.state.skip+10,
            deviceIndex:this.state.deviceIndex + 10,
        },()=>{
            this.loadDevices();

        });
    }

    previousPage(){
        this.setState({
            skip:this.state.skip-10,
            deviceIndex: this.state.deviceIndex - 10,
        },()=>{
            this.loadDevices();
        });
    }

    dateTimeToHuman(stringDate:string|null) {
        // if (stringDate !== null ) {
        //
        //     let splittedDate = stringDate.split("T");
        //
        //     let vuejsDate = splittedDate[0];
        //
        //     let vuejsTime:Array<string> =[];
        //
        //     if(splittedDate[1].includes("."))
        //         vuejsTime = splittedDate[1].split(".");
        //     if(splittedDate[1].includes("+"))
        //         vuejsTime = splittedDate[1].split("+");
        //
        //     return vuejsDate + " " + vuejsTime[0];
        //
        // }else {
        //     return stringDate;
        // }
        return stringDate;

    }


    paginate(data:{selected:number}){
        this.setState({
            skip: this.state.take * data.selected
        },()=>{
            this.loadDevices();
        })
    }


    render() {
        let devices=()=>{

            if(this.props.devices){
                let deviceIndex=this.state.deviceIndex;

                return this.props.devices[0].map((device,index)=> {
                    deviceIndex++;
                    return (
                        <tr key={device.id}>
                            <th scope="row">{deviceIndex}</th>
                            <td>{device.unique_id}</td>
                            <td>{device.details?.devicePhone !== null ? device.details?.devicePhone : "Not Set"}</td>
                            <td>{moment(device.updatedAt).format('DD-MM-YYYY HH:mm')}</td>
                            <td>{moment(device.requestedDate).format('DD-MM-YYYY HH:mm')}</td>
                            <td>{device.details?.customer ? device.details?.customer?.fname + " " + device.details?.customer?.lname : "Nill"}</td>
                            <td>{device.details?.plateNumber }</td>
                            <td>{device.details?.user ? device.details?.user?.fname + " " + device.details?.user?.lname : "No User"}</td>
                            <td onClick={(e) => this.selectedDevice(device, "details/view/" + device.id)}>
                                <button className="btn btn-default">Details</button>
                            </td>
                        </tr>
                    );
                });

            }else {
                return <React.Fragment/>
            }
        };




        return (
            <React.Fragment>
               <div className="row d-flex justify-content-end">
                   <div className="col-md-12">
                       <div className="col-md-12">
                           <LoaderComponent loading={this.state.loading}/>
                           <SearchForAllComonent/>
                           <table className="table table-bordered">
                               <thead>
                               <tr>
                                   <th scope="col">#</th>
                                   <th scope="col">Unique ID</th>
                                   <th scope="col">Device Phone</th>
                                   <th scope="col">Date Registered</th>
                                   <th scope="col">requested</th>
                                   <th scope="col">Customer</th>
                                   <th scope="col">Plate.No</th>

                               </tr>
                               </thead>
                               <tbody>
                               {devices()}
                               </tbody>
                           </table>
                       </div>
                   </div>
                   <ReactPaginate
                       containerClassName="pagination"
                       breakClassName="page-item"
                       breakLabel={<a className="page-link">...</a>}
                       pageClassName="page-item"
                       previousClassName="page-item"
                       nextClassName="page-item"
                       pageLinkClassName="page-link"
                       previousLinkClassName="page-link"
                       nextLinkClassName="page-link"
                       activeClassName="active"
                       pageCount={this.props.devices?this.props.devices[1]/this.state.take:0}
                       pageRangeDisplayed={2}
                       marginPagesDisplayed={2}
                       onPageChange={this.paginate}
                   />
               </div>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        devices:state.allDevices,

    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {getDeviceAction,selectedDeviceAction,selectedCustomerAction}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDettailsComponent);
