import {
    deviceSalesReportMonthlyInterface,
    subScriptionSalesReportMonthlyInterface
} from "../../dataInterface/deviceSalesReportMonthlyInterface";


interface subScrptionSales {
    type:string
    payload:subScriptionSalesReportMonthlyInterface
}

export default (state = null, action:subScrptionSales) => {
    switch (action.type) {
        case "SUBSCRIPTION_SALES_MONTHLY":
            return action.payload;
        default:
            return state;
    }
};
