import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {
    deviceSalesReportDailyInterface,
    deviceSalesReportMonthlyInterface,
    devicesSalesInMoneyReportMonthlyInterface
} from "../../dataInterface/deviceSalesReportMonthlyInterface";
import {getDeviceSalesByMonth} from "../../actions/report/getDeviceSalesByMonthAction";
import {getDeviceSalesByDay} from "../../actions/report/getSalesByDayAction";
import {getDevicesSalesMoneyReportSalesMonthly} from "../../actions/report/getDevicesSalesInMoneyReportAction";

import LoaderComponent from "../Loader/LoaderComponent";
import Notifiction from "../Notification/Notifiction";

interface internalState {
    stastData:Array<{name:string,data:Array<number>}>
    salesMoneyData:Array<{name:string,data:Array<number>}>,
    salesDailyStats:Array<{name:string,data:Array<number>}>
    loading:boolean,
    dailyLoading:boolean,
    Success:boolean,
    error:boolean,
    errorString:string,
    successString:string,
    year:string,
    month:string
}

interface parentProps {

}

interface externalStateProps {
    deviceSalesMonthly:deviceSalesReportMonthlyInterface,
    deviceSalesByMoney:devicesSalesInMoneyReportMonthlyInterface,
    salesDataByDay:deviceSalesReportDailyInterface
}


interface DispatchProps {
    getDeviceSalesByMonth:(data:object,callback:(error:boolean)=>void)=>void,
    getDeviceSalesByDay:(data:object,callback:(error:boolean)=>void)=>void,
    getDevicesSalesMoneyReportSalesMonthly:(data:object,callback:(error:boolean)=>void)=>void,
}

type Props = externalStateProps & DispatchProps & parentProps;

class DeviceSales extends React.Component<Props, internalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            stastData:[],
            salesMoneyData:[],
            salesDailyStats:[],
            loading:false,
            dailyLoading:false,
            Success:false,
            error:false,
            errorString:"",
            successString:"",
            year:"",
            month:""
        };

        this.getSalesMonthly=this.getSalesMonthly.bind(this);
        this.datesInMonth=this.datesInMonth.bind(this);
        this.getSalesByDay=this.getSalesByDay.bind(this);

    }

    static getDerivedStateFromProps(props:Props,state:internalState){

        return {
            stastData:props.deviceSalesMonthly,
            salesMoneyData:props.deviceSalesByMoney,
            salesDailyStats:props.salesDataByDay
        }

    }

    getSalesByDay(e:React.ChangeEvent<HTMLSelectElement>){

        const year=new Date().getFullYear();

        this.setState({
            month:e.target.value
        },()=>{
            this.props.getDeviceSalesByDay({ year,month:this.state.month},(error)=>{

                if(error){
                    this.setState({
                        loading:false,
                        error:true,
                        errorString:"Server Error Detected",
                        successString:"",
                        Success:false,
                    });
                }else {
                    this.setState({
                    })
                }
            });
        })


    }


    getSalesMonthly(e:React.ChangeEvent<HTMLSelectElement>){

        this.setState({
            year:e.target.value

        },()=>{

            this.props.getDeviceSalesByMonth({ year:this.state.year},(error)=>{

                if(error){
                    this.setState({
                        loading:false,
                        error:true,
                        errorString:"Server Error Detected",
                        successString:"",
                        Success:false,
                    });
                }else {
                    this.setState({
                    })
                }
            });


            this.props.getDevicesSalesMoneyReportSalesMonthly({year:this.state.year},(error)=>{

                if(error){
                    this.setState({
                        loading:false,
                        error:true,
                        errorString:"Server Error Detected",
                        successString:"",
                        Success:false,
                    });
                }else {
                    this.setState({
                    })
                }
            });



        });



    }

    componentDidMount(): void {

    }

    datesInMonth(): Array<string> {
        const days:Array<string>=[];
        const year=new Date().getFullYear();
        const numberOfDaysInMonth=new Date(year, Number(this.state.month), 0).getDate();

        for (let j = 1; j <= numberOfDaysInMonth; j++) {
            days.push(j.toString());
        }

        console.log(days);
        return days;
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <LoaderComponent loading={this.state.loading}/>
                        <Notifiction error={this.state.error} success={this.state.Success} errorString={this.state.errorString} successString={this.state.successString}/>
                    </div>
                    <div className="col-md-12">
                        <select className="custom-select custom-select-lg mb-3" onChange={this.getSalesMonthly}>
                            <option selected>Select Year</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                        </select>
                    </div>
                    <div className="col-md-12 mb-5">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                chart: {
                                    type: 'line'
                                },
                                title: {
                                    text: 'Sales Statistics(Tsh)'
                                },
                                subtitle: {
                                    text: 'Sales'
                                },
                                xAxis: {
                                    categories: [
                                        'Jan',
                                        'Feb',
                                        'Mar',
                                        'Apr',
                                        'May',
                                        'Jun',
                                        'Jul',
                                        'Aug',
                                        'Sep',
                                        'Oct',
                                        'Nov',
                                        'Dec'
                                    ],
                                },
                                yAxis: {
                                    min: 0,
                                    title: {
                                        text: 'Devices'
                                    }
                                },
                                plotOptions: {
                                    line: {
                                        dataLabels: {
                                            enabled: true
                                        },
                                        enableMouseTracking: true
                                    }
                                },
                                series: this.state.salesMoneyData
                            }
                            }
                        />

                    </div>
                    <div className="col-md-12">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                chart: {
                                    type: 'line'
                                },
                                title: {
                                    text: 'Number of Devices Sold'
                                },
                                subtitle: {
                                    text: 'Sales'
                                },
                                xAxis: {
                                    categories: [
                                        'Jan',
                                        'Feb',
                                        'Mar',
                                        'Apr',
                                        'May',
                                        'Jun',
                                        'Jul',
                                        'Aug',
                                        'Sep',
                                        'Oct',
                                        'Nov',
                                        'Dec'
                                    ],
                                },
                                yAxis: {
                                    min: 0,
                                    title: {
                                        text: 'Devices'
                                    }
                                },
                                plotOptions: {
                                    line: {
                                        dataLabels: {
                                            enabled: true
                                        },
                                        enableMouseTracking: true
                                    }
                                },
                                series: this.state.stastData
                            }
                            }
                        />

                    </div>
                    {/*daily data*/}
                    <div className="col-md-12">
                        <LoaderComponent loading={this.state.dailyLoading}/>
                    </div>
                    <div className="col-md-12">
                        <select className="custom-select custom-select-lg mb-3" onChange={this.getSalesByDay}>
                            <option selected>Select Month</option>
                            <option value="1">Jan</option>
                            <option value="2">Feb</option>
                            <option value="3">March</option>
                            <option value="4">Apr</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                    </div>
                    <div className="col-md-12">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                chart: {
                                    type: 'line'
                                },
                                title: {
                                    text: 'Daily Number of Devices Sold'
                                },
                                subtitle: {
                                    text: 'Sales'
                                },
                                xAxis: {
                                    categories: this.datesInMonth(),
                                },
                                yAxis: {
                                    min: 0,
                                    title: {
                                        text: 'Devices'
                                    }
                                },
                                plotOptions: {
                                    line: {
                                        dataLabels: {
                                            enabled: true
                                        },
                                        enableMouseTracking: true
                                    }
                                },
                                series: this.state.salesDailyStats
                            }
                            }
                        />

                    </div>
                </div>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        deviceSalesMonthly:state.deviceSalesMonthly,
        deviceSalesByMoney:state.deviceSalesByMoney,
        salesDataByDay:state.salesDataByDay
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {getDeviceSalesByMonth,getDevicesSalesMoneyReportSalesMonthly,getDeviceSalesByDay}, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSales);
