import {
    deviceSalesReportDailyInterface
} from "../../dataInterface/deviceSalesReportMonthlyInterface";


interface deviceSalesReport {
    type:string
    payload:deviceSalesReportDailyInterface
}

export default (state = null, action:deviceSalesReport) => {
    switch (action.type) {
        case "DEVICE_SALES_DAILY":
            return action.payload;
        default:
            return state;
    }
};
