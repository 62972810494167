import {HTTP} from "../../helpers/axios";
// src/thunks.ts
import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import { serverRoutes} from "../../api/serverRoutes";
import moment from "moment";


export const downloadAllSubscriptionMonthlyByDateRange = (data:object,callback:(error:boolean)=>void):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {


    try {
        const response =  await   HTTP(getState,dispatch).post(serverRoutes.getRecentPaidSimCardByDateRange,data,{responseType:"blob"});

        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
        const aEle = document.createElement('a');     // Create a label
        const href = window.URL.createObjectURL(blob);       // Create downloaded link
        aEle.href = href;
        let date=new Date();
        let dateString=date.getDay() + '-' + date.getMonth() + 1 + '-' + date.getFullYear() + "(" + date.getHours() + ":" + date.getMinutes() + ")";
        aEle.download = `simsForPayment-${dateString}.xlsx`;  // File name after download
        document.body.appendChild(aEle);
        aEle.click();     // Click to download
        document.body.removeChild(aEle); // Download complete remove element
        window.URL.revokeObjectURL(href); // Release
        let error=false;
        callback(error);
    }catch (e) {
        let error=true;
        callback(error);
    }


};
