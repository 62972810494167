import React from "react";
import {bindActionCreators, Dispatch} from "redux";
import {RootState} from "../../reducers";
import {connect} from "react-redux";
import CustomerComponent from "./CustomerComponent";
import {withRouter,RouteComponentProps} from "react-router-dom";
import searchedCustomers from "../../reducers/customer/searchCustomerReducer";
import {CustomerDataInterface} from "../../dataInterface/CustomerDataInterface";
import searchCustomerAction from "../../actions/customer/searchCustomerAction";

import ModalComponent from "../Modal/ModalComponent";
import CreateCustomerComponent from "./CreateCustomerComponent";
import {deviceInterface} from "../../dataInterface/deviceDataInterface";
import assignDeviceToCustomerAction from "../../actions/customer/assignDeviceToCustomerAction";
import LoaderComponent from "../Loader/LoaderComponent";
import Notifiction from "../Notification/Notifiction";

interface internalState {
    loading:boolean,
    deviceId:null|string,
    selectedCustomerId:null|number,
    name:string,
    error:boolean,
    errorString:string,
    Success:boolean,
    successString:string,
    showModal:boolean
}

interface parentProps {

}

interface externalStateProps {
    searchedCustomers:[Array<CustomerDataInterface>,number],
    selectedDevice:deviceInterface,

}


interface DispatchProps {
    searchCustomerAction:(searchData:object,callback:()=>void)=>void,
    assignDeviceToCustomerAction:(data:object,callBack:(error:boolean)=>void)=>void,


}

type Props = externalStateProps & DispatchProps & parentProps & RouteComponentProps<{deviceId:string}>;

class SearchCustomerCreateAssignDevice extends React.Component<Props, internalState> {

    constructor(props:Props) {
        super(props);

        this.state={
            loading:false,
            deviceId:null,
            selectedCustomerId:null,
            name:"",
            showModal:false,
            error:false,
            Success:false,
            errorString:"",
            successString:""
        }

        this.submit=this.submit.bind(this);
        this.handleShow=this.handleShow.bind(this);
        this.handleClose=this.handleClose.bind(this);
        this.assign=this.assign.bind(this);


    }


    componentDidMount(): void {
        const { match: { params } } = this.props;

        this.setState({
            deviceId:params.deviceId
        })

    }


    submit(e:React.MouseEvent){
        e.preventDefault();
        this.setState({loading:true});
        this.props.searchCustomerAction({name:this.state.name},()=>{
            this.setState({loading:false,name:""});
        });
    }

    handleShow(e:React.MouseEvent<HTMLButtonElement>){
        e.preventDefault();
        this.setState({
            showModal:true
        })
    }

    handleClose(e:React.MouseEvent<HTMLButtonElement>|null){
        e?.preventDefault();
        this.setState({
            showModal:false
        })
    }

    assign(e:React.MouseEvent,customerId:number){
        e.preventDefault();
        this.setState({
            loading:true
        });

        let deviceId=this.props.selectedDevice.id;
        this.props.assignDeviceToCustomerAction({deviceId,customerId},(error)=>{

            if(error){
                this.setState({
                    loading:false,
                    error:true,
                    errorString:"Failed to Link Device with customer",
                    successString:"",
                    Success:false,
                });

            }else {
                this.setState({
                    loading:false,
                    error:false,
                    errorString:"",
                    successString:"Device Linked with Customer",
                    Success:true,
                })

            }
        })
    }



    render() {


        let isCustomerEmmpty=()=>{

            if(this.props.searchedCustomers){
                if(this.props.searchedCustomers[0].length===0){
                    return (
                        <div className="col-auto text-center mb-3">
                            <CreateCustomerComponent/>
                        </div>
                    )

                }

            }else {
                return (
                    <div className="col-auto text-center mb-3">
                        <CreateCustomerComponent/>
                    </div>
                )
            }

        };


        let searchedCustomers=()=>{

            let that=this;
            function isDeviceAlreadyAssigned(customer: CustomerDataInterface) {
                if (customer.devices !== null) {
                    return customer?.devices?.findIndex((device) => {
                        return device.id === that.props.selectedDevice.details.id
                    })
                } else {
                    return -1;
                }

            }

            if(this.props.searchedCustomers){
                return this.props.searchedCustomers[0]?.map((customer,index)=>{

                    return(
                        <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{customer.fname}</td>
                            <td>{customer.lname}</td>
                            <td>
                                {
                                    isDeviceAlreadyAssigned(customer)=== -1?
                                        (<button  className="btn btn-info" onClick={(e)=>this.assign(e,customer.id)}>Assign Device to Customer</button>)
                                        :
                                        ``
                                }
                            </td>
                        </tr>

                    )
                })

            }
        };

        return (
            <React.Fragment>
                <div className="row mb-4">
                    <div className="col-md-12">
                        <LoaderComponent loading={this.state.loading}/>
                        <Notifiction success={this.state.Success} successString={this.state.successString} error={this.state.error} errorString={this.state.errorString}/>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">Search Customer</div>
                            </div>
                            <input type="text" className="form-control" placeholder="Name"  value={this.state.name}  onChange={(e)=>this.setState({name:e.target.value})}/>
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="button" onClick={this.submit}>Search</button>
                            </div>
                        </div>
                    </div>
                </div>
                {isCustomerEmmpty()}
                <div className="row">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        {searchedCustomers()}
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state: RootState): externalStateProps {
    return {
        searchedCustomers:state.searchedCustomers,
        selectedDevice:state.selectedDevice,

    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return bindActionCreators(
        {
            searchCustomerAction,assignDeviceToCustomerAction
        }, dispatch)

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchCustomerCreateAssignDevice)as any);
