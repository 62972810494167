
interface payloadInterface{
    isAuthenticated:boolean,
    token:string
}

interface loginReducer {
    type:string
    payload:payloadInterface
}

export default (state:payloadInterface|null = null, action:loginReducer) => {
    switch (action.type) {
        case "AUTHENTICATION":
            return action.payload;
        default:
            return state;
    }
};